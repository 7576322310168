import { StyleSheet, View, ViewStyle } from "react-native";

import { EmptyListPlaceholder } from "./Empty-List-Placeholder";

import { Comment, IComment } from "../Comment";
import { Loader } from "../Loader";

import { SideGutter } from "../../Utilities";

interface ICommentsListControls {
	Fetching: boolean;
	Loading: boolean;
	More: boolean;
};

interface ICommentsListProps {
	Comments: IComment[];
	OnPress: (Comment: IComment) => void;
	OnLongPress: (Comment: IComment) => void;
	EmptyListLabel: string;
	Fetching: boolean;
	Loading: boolean;
	More: boolean;
	Pagination: JSX.Element;
	ContainerStyles?: ViewStyle;
};

const CommentsList = (Props: ICommentsListProps): JSX.Element => {

	const { Comments, EmptyListLabel, Fetching, Loading, More, Pagination, ContainerStyles, OnPress, OnLongPress } = Props;

	if (Loading) {
		return <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } } />;
	}

	if (Comments.length === 0) {
		return <EmptyListPlaceholder Label={ EmptyListLabel } ContainerStyle={ { marginBottom: 25, width: SideGutter(20, 20, 768) } } />;
	}

	return (
		<View collapsable style={ ContainerStyles }>
			{ Comments.map<JSX.Element>((Entry: IComment) => <Comment
				key={ Entry.id }
				OnPress={ (Comment: IComment) => OnPress(Comment) }
				OnLongPress={ (Comment: IComment) => OnLongPress(Comment) }
				Comment={ Entry }
				ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } }
			/>) }

			{ Fetching ? <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } } /> : More ? Pagination : <View collapsable style={ Styles.Placeholder } /> }
		</View>
	)
};

const Styles = StyleSheet.create({
	Placeholder: {
		marginBottom: 15
	}
});

export { CommentsList, ICommentsListControls };
