import { StyleSheet, Text, View, ViewStyle } from "react-native";

interface IHeadingProps {
	ContainerStyles?: ViewStyle;
	Left?: JSX.Element;
	Right?: JSX.Element;
	Title?: string;
};

const Heading = (Props: IHeadingProps): JSX.Element => {

	const { ContainerStyles, Left, Right, Title } = Props;

	return (
		<View collapsable style={ [Styles.Flex_Container, ContainerStyles] }>
			{ Left ? Left : <View collapsable style={ Styles.Placeholder } /> }

			{ Title ? <Text ellipsizeMode="tail" numberOfLines={ 1 } style={ Styles.Title }>{ Title }</Text> : <></> }

			{ Right ? Right : <View collapsable style={ Styles.Placeholder } /> }
		</View>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	Placeholder: {
		height: 25,
		margin: 5,
		width: 25
	},
	Title: {
		color: "#000",
		flex: 1,
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginHorizontal: 15,
		textAlign: "center"
	}
});

export { Heading };
