import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text } from 'react-native';
import ActionSheet, { ActionSheetRef, SheetProps, useScrollHandlers, SheetManager } from 'react-native-actions-sheet';

import { Button, Dropdown, IOption, Input, StarRating } from "../";
import { SideLabel, TwoSideLabels } from "../Typography";

import { SideGutter } from "../../Utilities";
import { BOULDER_GRADES, ROUTE_GRADES } from '../../Constants';
import { HTTPClientContext, IHTTPClientContext } from '../../Providers/HTTP-Client-Provider';
import { AxiosResponse } from 'axios';
import { BOULDER_GRADES_REVERSE, ROUTE_GRADES_REVERSE } from '../../Constants/Route-Grades';

interface ILogAscent {};

const LogAscentSheet = (Props: SheetProps): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);

	const { sheetId, payload } = Props;

	const LogAscentSheet_Ref: MutableRefObject<ActionSheetRef | null> = useRef<ActionSheetRef | null>(null);

	const ScrollHandlers = useScrollHandlers<ScrollView>("Log-Ascent-Sheet", LogAscentSheet_Ref);

	const [Choice, SetChoice] = useState<string>("");
	const [RouteGrade, SetRouteGrade] = useState<IOption>({ ID: "", Label: "", Value: "" });
	const [Comment, SetComment] = useState<string>("");
	const [Rating, SetRating] = useState<number>(5);
	const [Grades, SetGrades] = useState({});
	const [Grade, SetGrade] = useState({ ID: "", Label: "", Value: "" });

	useEffect(() => {
		( async() => {
			let AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get('users/current-user');

			SetGrades(payload.Route.type === 'route' ? ROUTE_GRADES[ROUTE_GRADES_REVERSE[AxiosResponse_Result.data.routeGradeSystem]] : BOULDER_GRADES[BOULDER_GRADES_REVERSE[AxiosResponse_Result.data.boulderGradeSystem]]);
			SetGrade(payload.Route.type === 'route' ? ROUTE_GRADES[ROUTE_GRADES_REVERSE[AxiosResponse_Result.data.routeGradeSystem]].filter((e) => e.Value.includes(payload.Route.grade))[0] : BOULDER_GRADES[BOULDER_GRADES_REVERSE[AxiosResponse_Result.data.boulderGradeSystem]].filter((e) => e.Value.includes(payload.Route.grade))[0]);
		})();
	}, []);

	const LogAscent = async () => {
		await _HTTPClientContext.HTTPClient.post("log-ascent", {
			logText: Comment,
			rating: Rating,
			grade: RouteGrade.Value || Grade.Value,
			routeId: payload.Route.id
		});

		SheetManager.hide(sheetId);
	};

	return (
		<ActionSheet containerStyle={ Styles.ActionSheetContent_Container } defaultOverlayOpacity={ 0.5 } gestureEnabled id={ sheetId } indicatorStyle={ Styles.Indicator } initialSnapIndex={ 2 } ref={ LogAscentSheet_Ref } snapPoints={ [33, 66, 100] }>
			<ScrollView collapsable contentContainerStyle={ Styles.ScrollViewContent_Container } contentInsetAdjustmentBehavior="automatic" { ...ScrollHandlers } showsVerticalScrollIndicator>
				<Text style={ Styles.Title }>{ payload.I18N.t(["LogAscentSheet", "LogAscent"]) }</Text>

				{ Choice ? <SideLabel Label={ payload.I18N.t(["LogAscentSheet", "TapToVote"]) } ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } } /> : <></> }
				{ Choice ? <StarRating
					OnPress={ (Rating: number) => SetRating(Rating) }
					Rating={ Rating }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { marginHorizontal: 5 } }
					Size={ 30 }
				/> : <></> }

				{ Choice === "Quick-Log" ? <TwoSideLabels
					LeftLabel={ payload.I18N.t(["LogAscentSheet", "RouteGrade"]) }
					RightLabel={ Grade.Label }
					LeftLabelStyles={ { fontSize: 16, fontFamily: "Montserrat-Medium" } }
					RightLabelStyles={ { fontSize: 16, fontFamily: "Montserrat-Medium" } }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/> : <></> }

				{ Choice === "Detailed-Log" ? <Dropdown
					OnOptionSelect={ (SelectedOption: IOption) => SetRouteGrade(SelectedOption) }
					Options={ Grades }
					OptionsShown={ 5 }
					Placeholder={ payload.I18N.t(["LogAscentSheet", "RouteGrade"]) }
					SelectedOption={ !RouteGrade.ID ? Grades[0] : RouteGrade }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/> : <></> }

				{ Choice === "Detailed-Log" ? <Input
					Label={ payload.I18N.t(["LogAscentSheet", "Comment"]) }
					OnChange={ (Text: string) => SetComment(Text) }
					Placeholder={ payload.I18N.t(["LogAscentSheet", "WriteYourCommentHere"]) }
					Value={ Comment }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Lines={ 5 }
					Multiline
				/> : <></> }

				{ Choice ? <TwoSideLabels
					LeftLabel={ payload.I18N.t(["LogAscentSheet", "Date"]) }
					RightLabel={ new Date().toLocaleDateString() }
					LeftLabelStyles={ { fontSize: 16, fontFamily: "Montserrat-Medium" } }
					RightLabelStyles={ { fontSize: 16, fontFamily: "Montserrat-Medium" } }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/> : <></> }

				{ Choice ? <Button
					Label={ payload.I18N.t(["LogAscentSheet", "SubmitLog"]) }
					OnPress={ () => LogAscent() }
					Theme="#ED2020"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/> : <></> }

				{ Choice === "Detailed-Log" ? <Button
					Label={ payload.I18N.t(["LogAscentSheet", "ClearLog"]) }
					OnPress={ () => {
						SetComment("");
						SetRating(0);
						SetRouteGrade({ ID: "", Label: "", Value: "" });
					} }
					Theme="#000"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/> : <></> }

				{ (!Choice || Choice === "Detailed-Log") ? <Button
					Label={ payload.I18N.t(["LogAscentSheet", "MakeAQuickLog"]) }
					OnPress={ () => SetChoice("Quick-Log") }
					Theme="#ED2020"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/> : <></> }

				{ (!Choice || Choice !== "Detailed-Log") ? <Button
					Label={ payload.I18N.t(["LogAscentSheet", "MakeADetailedLog"]) }
					OnPress={ () => SetChoice("Detailed-Log") }
					Theme="#ED2020"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/> : <></> }
			</ScrollView>
		</ActionSheet>
	);
};

const Styles = StyleSheet.create({
	ActionSheetContent_Container: {
		borderTopLeftRadius: 25,
		borderTopRightRadius: 25,
		backgroundColor: "#F5F5F5",
		paddingTop: 10,
		flex: 0.75
	},
	Indicator: {
		backgroundColor: "#000",
		width: 50
	},
	ScrollViewContent_Container: {
		backgroundColor: "#F5F5F5",
		alignItems: "center",
		paddingTop: 10
	},
	Title: {
		color: "#000",
		fontFamily: "Montserrat-Bold",
		fontSize: 18,
		marginBottom: 25
	}
});

export { ILogAscent, LogAscentSheet };
