import AsyncStorage from "@react-native-async-storage/async-storage";
import { KeyValuePair } from "@react-native-async-storage/async-storage/lib/typescript/types";
import { Context, ReactNode, createContext, useEffect, useState, useContext } from "react";

import { IUserEntity } from "../Interfaces/Entities";

import { HTTPClientContext, IHTTPClientContext } from "../Providers/HTTP-Client-Provider";

interface IAuthenticationContext {
	JWT: string;
	Login: (JWT: string, User: IUserEntity) => void;
	Logout: () => void;
	UpdateUser: (User: IUserEntity) => void;
	User: IUserEntity | null;
};

interface IAuthenticationProviderProps {
	children: ReactNode;
};

const AuthenticationContext: Context<IAuthenticationContext> = createContext<IAuthenticationContext>({
	JWT: "",
	Login: () => {},
	Logout: () => {},
	UpdateUser: () => {},
	User: null
});

const AuthenticationProvider = (Props: IAuthenticationProviderProps): JSX.Element => {

	const { children } = Props;

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);

	const [JWT, SetJWT] = useState<string>("");
	const [User, SetUser] = useState<IUserEntity | null>(null);

	useEffect(() => {
		(async () => {
			const Credentials: readonly KeyValuePair[] = await AsyncStorage.multiGet(["@JWT", "@User"]);

			if (Credentials[0][1] && Credentials[1][1]) {
				SetJWT(Credentials[0][1]);
				SetUser(JSON.parse(Credentials[1][1]));

			} else {
				SetJWT("");
				SetUser(null);
			}
		})();
	}, []);

	const Login = (JWT: string, User: IUserEntity): void => {
		AsyncStorage.multiSet([["@JWT", JWT], ["@User", JSON.stringify(User)]]);

		SetJWT(JWT);
		SetUser(User);
	};

	const Logout = (): void => {
		AsyncStorage.multiRemove(["@JWT", "@User"]);

		SetJWT("");
		SetUser(null);
	};

	const UpdateUser = (User: IUserEntity): void => {
		SetUser(User);
	};

	return (
		<AuthenticationContext.Provider value={ { JWT, Login, Logout, UpdateUser, User } }>
			{ children }
		</AuthenticationContext.Provider>
	);
};

export { AuthenticationContext };
export { AuthenticationProvider };
export { IAuthenticationContext };
