import { StyleSheet, View, ViewStyle } from "react-native";

import { EmptyListPlaceholder } from "./Empty-List-Placeholder";

import { Loader } from "../Loader";
import { ITop, Top } from "../Top";

import { SideGutter } from "../../Utilities";

interface ITopsListControls {
	Fetching: boolean;
	Loading: boolean;
	More: boolean;
};

interface ITopsListProps {
	EmptyListLabel: string;
	Fetching: boolean;
	Loading: boolean;
	More: boolean;
	Pagination: JSX.Element;
	Tops: ITop[];
	ContainerStyles?: ViewStyle;
};

const TopsList = (Props: ITopsListProps): JSX.Element => {

	const { EmptyListLabel, Fetching, Loading, More, Pagination, Tops, ContainerStyles } = Props;

	if (Loading) {
		return <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } } />;
	}

	if (Tops.length === 0) {
		return <EmptyListPlaceholder Label={ EmptyListLabel } ContainerStyle={ { marginBottom: 25, width: SideGutter(20, 20, 768) } } />;
	}

	return (
		<View collapsable style={ ContainerStyles }>
			{ Tops.map<JSX.Element>((Entry: ITop, i) => <Top
				key={ i }
				Top={ Entry }
				ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } }
			/>) }

			{ Fetching ? <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } } /> : More ? Pagination : <View collapsable style={ Styles.Placeholder } /> }
		</View>
	)
};

const Styles = StyleSheet.create({
	Placeholder: {
		marginBottom: 15
	}
});

export { ITopsListControls, TopsList };
