import { IOption } from "../Components";

const FILTER_BY_BG_BG: IOption[] = [
	{ ID: "0", Label: "Всички", Value: "Всички" },
	{ ID: "1", Label: "Ниво Болдър", Value: "Ниво Болдър" },
	{ ID: "2", Label: "Ниво На Маршрута", Value: "Ниво На Маршрута" }
];

const FILTER_BY_EN_US: IOption[] = [
	{ ID: "0", Label: "All", Value: "All" },
	{ ID: "1", Label: "Boulder Grade", Value: "Boulder Grade" },
	{ ID: "2", Label: "Route Grade", Value: "Route Grade" }
];

const SORT_CRITERIA_BG_BG: IOption[] = [
	{ ID: "0", Label: "Най-Труден", Value: "hardest" },
	{ ID: "1", Label: "Най-Изкачвани", Value: "mostAscent" },
	{ ID: "2", Label: "Най-Популярни", Value: "mostPopular" },
	{ ID: "3", Label: "Име", Value: "name" },
	{ ID: "4", Label: "Най-Нови", Value: "newest" }
];

const SORT_CRITERIA_EN_US: IOption[] = [
	{ ID: "0", Label: "Hardest", Value: "hardest" },
	{ ID: "1", Label: "Most Ascent", Value: "mostAscent" },
	{ ID: "2", Label: "Most Popular", Value: "mostPopular" },
	{ ID: "3", Label: "Name", Value: "name" },
	{ ID: "4", Label: "Newest", Value: "newest" }
];

export { FILTER_BY_BG_BG };
export { FILTER_BY_EN_US };
export { SORT_CRITERIA_BG_BG };
export { SORT_CRITERIA_EN_US };
