import { Context, ReactNode, createContext, useEffect, useState } from "react";
import { EmitterSubscription, Keyboard } from "react-native";

interface IKeyboardContext {
	Offset: number;
};

interface IKeyboardProviderProps {
	children: ReactNode;
};

const KeyboardContext: Context<IKeyboardContext> = createContext<IKeyboardContext>({
	Offset: 0
});

const KeyboardProvider = (Props: IKeyboardProviderProps): JSX.Element => {

	const { children } = Props;

	const [Offset, SetOffset] = useState(() => 60);

	useEffect(() => {
		const KeyboardDidHideSubscription: EmitterSubscription = Keyboard.addListener("keyboardDidHide", () => {
			SetOffset(() => 60);
		});

		const KeyboardDidShowSubscription: EmitterSubscription = Keyboard.addListener("keyboardDidShow", () => {
			SetOffset(0);
		});

		return () => {
			KeyboardDidHideSubscription.remove();
			KeyboardDidShowSubscription.remove();
		};
	}, []);

	return (
		<KeyboardContext.Provider value={ { Offset } }>
			{ children }
		</KeyboardContext.Provider>
	);
};

export { KeyboardContext };
export { KeyboardProvider };
export { IKeyboardContext };
