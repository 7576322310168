import { FontSource } from "expo-font";

interface IFont {
	[Font: string]: string | Record<string, FontSource>;
};

const Fonts: IFont = {
	"Montserrat-Bold": require("../../assets/Montserrat-Bold.ttf"),
	"Montserrat-Medium": require("../../assets/Montserrat-Medium.ttf")
};

export { Fonts };
