import { NativeStackNavigationOptions, createNativeStackNavigator } from "@react-navigation/native-stack";

import { ClimbedRoutes, EditProfile, MyProjects, YourProfile } from "../../Screens/User-Flow/Profile";

const ProfileFlow = (): JSX.Element => {

	const NativeStack = createNativeStackNavigator();

	const NativeStackScreenOptions: NativeStackNavigationOptions = {
		animation: "slide_from_right",
		contentStyle: {
			backgroundColor: "#F5F5F5"
		},
		headerShown: false,
		statusBarStyle: "dark",
		statusBarHidden: true,
		statusBarTranslucent: true
	};

	return (
		<NativeStack.Navigator initialRouteName="Your Profile" screenOptions={ NativeStackScreenOptions }>
			<NativeStack.Screen name="Climbed Routes" component={ ClimbedRoutes } />
			<NativeStack.Screen name="Edit Profile" component={ EditProfile } />
			<NativeStack.Screen name="My Projects" component={ MyProjects } />
			<NativeStack.Screen name="Your Profile" component={ YourProfile } />
		</NativeStack.Navigator>
	);
};

export { ProfileFlow };
