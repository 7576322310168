import { ReactNode, MutableRefObject, useEffect, useRef, useState } from "react";
import { Animated, Modal, StyleSheet, Text, View } from "react-native";

import { SideGutter } from "../Utilities";

interface IAnimatedModalProps {
	Message: string;
	Title: string;
	Visible: boolean;
	children: ReactNode;
};

const AnimatedModal = (Props: IAnimatedModalProps): JSX.Element => {

	const { Visible, Message, Title, children } = Props;

	const Value: MutableRefObject<Animated.Value> = useRef<Animated.Value>(new Animated.Value(0));

	const [Visibility, SetVisibility] = useState<boolean>(() => Visible);

	useEffect(() => ToggleModal(), [Visible]);

	const ToggleModal = (): void => {
		if (!Visible) {
			Animated.timing(Value.current, {
				duration: 100,
				toValue: 0,
				useNativeDriver: true
			})
			.start(() => SetVisibility(false));

		} else {
			SetVisibility(true);

			Animated.timing(Value.current, {
				duration: 100,
				toValue: 1,
				useNativeDriver: true
			})
			.start();
		}
	};

	return (
		<Modal collapsable transparent visible={ Visibility }>
			<View collapsable style={ [Styles.BackdropFlexContainer] }>
				<Animated.View collapsable style={ [Styles.ModalContainer, { transform: [{ scale: Value.current }] }] }>
					<Text style={ Styles.Title }>{ Title }</Text>

					{ Message ? <Text style={ Styles.Message }>{ Message }</Text> : <></> }

					{ children }
				</Animated.View>
			</View>
		</Modal>
	);
};

const Styles = StyleSheet.create({
	BackdropFlexContainer: {
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
	},
	ModalContainer: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		elevation: 3,
		padding: 25,
		position: "absolute",
		width: SideGutter(20, 20, 768)
	},
	Title: {
		fontFamily: "Montserrat-Bold",
		fontSize: 18,
		marginBottom: 25,
		textAlign: "center"
	},
	Message: {
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginBottom: 25,
		textAlign: "center"
	}
});

export { AnimatedModal };
