import { ActivityIndicator, StyleSheet, Text, TextStyle, TouchableHighlight, View, ViewStyle } from "react-native";

interface IButtonProps {
	Label: string;
	OnPress: () => void;
	Theme: string;
	ContainerStyles?: ViewStyle;
	Disabled?: boolean;
	Icon?: JSX.Element;
	LabelStyles?: TextStyle;
	LoaderColor?: string;
};

const Button = (Props: IButtonProps): JSX.Element => {

	const { Label, OnPress, Theme, ContainerStyles, Disabled, Icon, LabelStyles, LoaderColor } = Props;

	return (
		<TouchableHighlight disabled={ Disabled ? true: false } onPress={ () => OnPress() } style={ [Styles.TouchableHighlight, ContainerStyles] }>
			<View collapsable style={ [Styles.FlexRelative_Container, { backgroundColor: Theme }] }>
				{ Icon ? Icon : <></> }

				{ Disabled ? <ActivityIndicator collapsable color={ LoaderColor ? LoaderColor : "#FFF" } size={ 24 } style={ Styles.Loader } /> : <Text style={ LabelStyles }>{ Label }</Text> }
			</View>
		</TouchableHighlight>
	);
};

const Styles = StyleSheet.create({
	TouchableHighlight: {
		borderRadius: 25
	},
	FlexRelative_Container: {
		borderRadius: 25,
		elevation: 3,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		shadowColor: "#000",
		shadowOffset: {
			height: 1,
			width: 0
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22
	},
	Loader: {
		paddingVertical: 8.5
	}
});

export { Button };
