import { NavigationProp, StackActions, useNavigation } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useContext, useState } from "react";
import { KeyboardAvoidingView, SafeAreaView, ScrollView, StyleSheet, Text } from "react-native";

import { AnimatedModal, Button, Input, PasswordInput } from "../../Components";
import { SideLabel, TwoSideLabels } from "../../Components/Typography";

import { EMAIL_REGEXP } from "../../Constants";

import { IAuthEmailLoginDto } from "../../Interfaces/Dto";
import { IUserEntity } from "../../Interfaces/Entities";

import { AuthenticationContext, IAuthenticationContext } from "../../Providers/Authentication-Provider";
import { HTTPClientContext, IHTTPClientContext } from "../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../Providers/I18N-Provider";

import { SideGutter, TopGutter } from "../../Utilities";

const Login = (): JSX.Element => {

	const _AuthenticationContext: IAuthenticationContext = useContext<IAuthenticationContext>(AuthenticationContext);
	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N }: II18NContext = useContext<II18NContext>(I18NContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();

	const [Email, SetEmail] = useState<string>("");
	const [Password, SetPassword] = useState<string>("");
	const [PasswordVisibility, SetPasswordVisibility] = useState<boolean>(false);

	const [Loading, SetLoading] = useState<boolean>(false);
	const [Modal, SetModal] = useState({ Title: "", Message: "", Visible: false });

	const Login = async (): Promise<void> => {

		if (EMAIL_REGEXP.test(Email) && Password) {

			try {
				SetLoading(true);

				const AxiosResponse_Result: AxiosResponse<{ user: IUserEntity, token: string }, IAuthEmailLoginDto> = await _HTTPClientContext.HTTPClient.post<IUserEntity, AxiosResponse<{ user: IUserEntity, token: string }, IAuthEmailLoginDto>, IAuthEmailLoginDto>("/auth/email/login", {
					email: Email,
					password: Password
				});

				const JWT: string = AxiosResponse_Result.data.token;
				const User: IUserEntity = AxiosResponse_Result.data.user;

				_AuthenticationContext.Login(JWT, User);

			} catch (Error) {
				SetPassword("");

				SetLoading(false);
				SetModal({ Title: I18N.t(["LoginScreen", "InvalidCredentials"]), Message: I18N.t(["LoginScreen", "IncorrectEmailOrPassword"]), Visible: true });
			}

		} else {
			SetModal({ Title: I18N.t(["LoginScreen", "FormFilledOutIncorrectly"]), Message: I18N.t(["LoginScreen", "FillInAllTheFieldsToContinue"]), Visible: true });
		}
	};

	return (
		<SafeAreaView collapsable>
			<KeyboardAvoidingView collapsable>
				<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" keyboardShouldPersistTaps="handled" showsVerticalScrollIndicator>
					<Text style={ Styles.Slogan }>{ I18N.t(["LoginScreen", "WelcomeBack"]) }</Text>

					<Input
						Label={ I18N.t(["LoginScreen", "Email"]) }
						OnChange={ (Text: string) => SetEmail(Text) }
						Placeholder={ I18N.t(["LoginScreen", "EnterEmail"]) }
						Value={ Email }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						IsEmail
					/>

					<PasswordInput
						Label={ I18N.t(["LoginScreen", "Password"]) }
						OnChange={ (Password: string) => SetPassword(Password) }
						Placeholder={ I18N.t(["LoginScreen", "EnterPassword"]) }
						Value={ Password }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						PasswordVisibility={ PasswordVisibility }
						TogglePasswordVisibility={ () => SetPasswordVisibility(!PasswordVisibility) }
						WithPasswordVisibilityIcon
					/>

					<SideLabel
						Label={ I18N.t(["LoginScreen", "ForgotPassword"]) }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						LabelOnRight
						OnPress={ () => Navigation.dispatch(StackActions.push("Forgot Password")) }
					/>

					<Button
						Label={ I18N.t(["LoginScreen", "Login"]) }
						OnPress={ () => Login() }
						Theme="#ED2020"
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						Disabled={ Loading }
					/>

					{/* <AlternativeHint
						Hint={ I18N.t(["LoginScreen", "OrContinueWith"]) }
						ContainerStyles={ { flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: 25, width: SideGutter(20, 20, 768) } }
					/>

					<Button
						Label={ I18N.t(["LoginScreen", "ContinueWithGoogle"]) }
						OnPress={ () => LaunchGoogleSignInPrompt() }
						Theme="#FFF"
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						Icon={ <MaterialCommunityIcons color="#000" name="google" size={ 25 } style={ Styles.Icon } /> }
						LabelStyles={ { color: "#000", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
					/> */}

					<TwoSideLabels
						LeftLabel={ I18N.t(["LoginScreen", "DontHaveAnAccount"]) }
						RightLabel={ I18N.t(["LoginScreen", "SignUp"]) }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						LeftLabelStyles={ { color: "#000", fontFamily: "Montserrat-Medium", fontSize: 16 } }
						OnRightLabelPress={ () => Navigation.dispatch(StackActions.push("Sign Up")) }
						RightLabelStyles={ { color: "#000", fontFamily: "Montserrat-Bold", fontSize: 16 } }
					/>
				</ScrollView>

				<AnimatedModal Title={ Modal.Title } Message={ Modal.Message } Visible={ Modal.Visible }>
					<Button
						Label={ I18N.t(["LoginScreen", "Dismiss"]) }
						OnPress={ () => SetModal({ Title: "", Message: "", Visible: false }) }
						Theme="#000"
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/>
				</AnimatedModal>
			</KeyboardAvoidingView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	Slogan: {
		color: "#000",
		fontFamily: "Montserrat-Bold",
		fontSize: 20,
		marginBottom: 25
	},
	Icon: {
		height: 25,
		position: "absolute",
		left: 10,
		width: 25
	}
});

export { Login };
