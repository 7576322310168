import { Image } from "expo-image";
import { MutableRefObject, useRef, useState } from "react";
import { Animated, FlatList, ListRenderItemInfo, NativeScrollEvent, NativeSyntheticEvent, StyleSheet, View, ViewabilityConfig, ViewStyle, ViewToken } from "react-native";

import { IsMobileDevice, SideGutter } from "../Utilities";
import { eEWallsAPI } from "../Constants";

interface ICarouselProps {
	Images: string[];
	ContainerStyles?: ViewStyle;
};

interface IOnViewableItemsChangedEvent {
	changed: ViewToken[];
	viewableItems: ViewToken[];
};

const Carousel = (Props: ICarouselProps): JSX.Element => {

	const { Images, ContainerStyles } = Props;

	const OnViewableItemsChanged_Ref: MutableRefObject<(Event: IOnViewableItemsChangedEvent) => void> = useRef<(Event: IOnViewableItemsChangedEvent) => void>((Event: IOnViewableItemsChangedEvent) => SetViewableItemIndex(Event.viewableItems[0].index));
	const ScrollX_Ref: MutableRefObject<Animated.Value> = useRef<Animated.Value>(new Animated.Value(0));
	const ViewabilityConfig_Ref: MutableRefObject<ViewabilityConfig> = useRef<ViewabilityConfig>({ itemVisiblePercentThreshold: 50 });

	const [ViewableItemIndex, SetViewableItemIndex] = useState<number | null>(0);

	const OnScroll = (Event: NativeSyntheticEvent<NativeScrollEvent>): void => {

		Animated.event<NativeScrollEvent>([
			{
				nativeEvent: {
					contentOffset: {
						x: ScrollX_Ref.current
					}
				}
			}
		], { useNativeDriver: false })(Event);
	};

	return (
		<View collapsable style={ [Styles.Flex_Container, ContainerStyles] }>
			<FlatList
				collapsable
				data={ Images }
				horizontal={ IsMobileDevice() ? true : false }
				onScroll={ OnScroll }
				onViewableItemsChanged={ OnViewableItemsChanged_Ref.current }
				pagingEnabled
				renderItem={ (ItemInfo: ListRenderItemInfo<string>) => <Image
					collapsable
					contentFit="contain"
					placeholder="L2HC1R~q00IU?bM{ofj[000000%M"
					placeholderContentFit="cover"
					priority="high"
					source={ eEWallsAPI.VERSIONED_URL + ItemInfo.item }
					style={ Styles.Image }
				/> }
				showsHorizontalScrollIndicator={ false }
				snapToAlignment="center"
				viewabilityConfig={ ViewabilityConfig_Ref.current }
			/>

			{ IsMobileDevice() ? <View collapsable style={ Styles.Pagination }>
				{ Images.map<JSX.Element>((Image: string, Index: number) => <View
					key={ Index }
					collapsable
					style={ [Styles.Dot, ViewableItemIndex === Index ? { backgroundColor: "#ED2020" } : {}] }
				/>) }
			</View> : <></> }
		</View>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center"
	},
	Image: {
		borderRadius: 8,
		height: SideGutter(20, 20, 768),
		marginBottom: 25,
		marginHorizontal: 20,
		width: SideGutter(20, 20, 768)
	},
	Pagination: {
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "center",
		width: SideGutter(20, 20, 768)
	},
	Dot: {
		backgroundColor: "#000",
		borderRadius: 100,
		height: 10,
		margin: 5,
		width: 10
	}
});

export { Carousel };
