import { IOption } from "../Components";

const BOULDER_GRADES_HUECO: IOption[] = [
	{ ID: "1", Label: "VB", Value: "[6]" },
	{ ID: "2", Label: "V0-", Value: "[7, 8]" },
	{ ID: "3", Label: "V0", Value: "[9, 10]" },
	{ ID: "4", Label: "V0+", Value: "[11, 12]" },
	{ ID: "5", Label: "V1", Value: "[13]" },
	{ ID: "6", Label: "V2", Value: "[14]" },
	{ ID: "7", Label: "V3", Value: "[15]" },
	{ ID: "8", Label: "V3+", Value: "[16]" },
	{ ID: "9", Label: "V4", Value: "[17]" },
	{ ID: "10", Label: "V4+", Value: "[18]" },
	{ ID: "11", Label: "V5", Value: "[19]" },
	{ ID: "12", Label: "V5+", Value: "[19, 20]" },
	{ ID: "13", Label: "V6", Value: "[20]" },
	{ ID: "14", Label: "V6+", Value: "[20, 21]" },
	{ ID: "15", Label: "V7", Value: "[21, 22]" },
	{ ID: "16", Label: "V7+", Value: "[22, 23]" },
	{ ID: "17", Label: "V8", Value: "[23]" },
	{ ID: "18", Label: "V9", Value: "[24]" },
	{ ID: "19", Label: "V10", Value: "[25]" },
	{ ID: "20", Label: "V11", Value: "[26]" },
	{ ID: "21", Label: "V12", Value: "[27]" },
	{ ID: "22", Label: "V13", Value: "[28]" },
	{ ID: "23", Label: "V14", Value: "[29]" },
	{ ID: "24", Label: "V15", Value: "[30]" },
	{ ID: "25", Label: "V16", Value: "[31]" },
	{ ID: "26", Label: "V17", Value: "[32]" }
];

const BOULDER_GRADES_EBLEAU: IOption[] = [
	{ ID: "1", Label: "3", Value: "[6]" },
	{ ID: "2", Label: "4-", Value: "[7]" },
	{ ID: "3", Label: "4", Value: "[8]" },
	{ ID: "4", Label: "4+", Value: "[9]" },
	{ ID: "5", Label: "5-", Value: "[10]" },
	{ ID: "6", Label: "5", Value: "[11]" },
	{ ID: "7", Label: "5+", Value: "[12, 13]" },
	{ ID: "8", Label: "6A", Value: "[14]" },
	{ ID: "9", Label: "6A+", Value: "[15]" },
	{ ID: "10", Label: "6B", Value: "[16]" },
	{ ID: "11", Label: "6B+", Value: "[17]" },
	{ ID: "12", Label: "6C", Value: "[18]" },
	{ ID: "13", Label: "6C+", Value: "[19]" },
	{ ID: "14", Label: "7A", Value: "[20]" },
	{ ID: "15", Label: "7A+", Value: "[21]" },
	{ ID: "16", Label: "7B", Value: "[22]" },
	{ ID: "17", Label: "7B+", Value: "[23]" },
	{ ID: "18", Label: "7C", Value: "[24]" },
	{ ID: "19", Label: "7C+", Value: "[25]" },
	{ ID: "20", Label: "8A", Value: "[26]" },
	{ ID: "21", Label: "8A+", Value: "[27]" },
	{ ID: "22", Label: "8B", Value: "[28]" },
	{ ID: "23", Label: "8B+", Value: "[29]" },
	{ ID: "24", Label: "8C", Value: "[30]" },
	{ ID: "25", Label: "8C+", Value: "[31]" },
	{ ID: "26", Label: "9A", Value: "[32]" }
];

const BOULDER_GRADES = {
	"0": BOULDER_GRADES_HUECO,
	"1": BOULDER_GRADES_EBLEAU
}

export { BOULDER_GRADES };
