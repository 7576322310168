import { useState } from "react";
import { StyleSheet, Text, View, ViewStyle } from "react-native";

import { EmptyListPlaceholder } from "./Empty-List-Placeholder";

import { GymCard } from "../Gym-Card";
import { Heading } from "../Heading";
import { AccordionIcon } from "../Heading/Icons";
import { Loader } from "../Loader";

import { IGymEntity } from "../../Interfaces/Entities";

import { SideGutter } from "../../Utilities";

interface IFavoriteGymsListControls {
	Fetching: boolean;
	Limit: number;
	Loading: boolean;
	More: boolean;
	Page: number;
};

interface IFavoriteGymsListProps {
	EmptyListLabel: string;
	FavoriteGyms: IGymEntity[];
	Fetching: boolean;
	Label: string;
	Loading: boolean;
	More: boolean;
	OnFavoriteGymBookmarkPress: (FavoriteGym: IGymEntity) => void;
	OnFavoriteGymCardPress: (FavoriteGym: IGymEntity) => void;
	Pagination: JSX.Element;
	ContainerStyles?: ViewStyle;
};

const FavoriteGymsList = (Props: IFavoriteGymsListProps): JSX.Element => {

	const { EmptyListLabel, FavoriteGyms, Fetching, Label, Loading, More, OnFavoriteGymBookmarkPress, OnFavoriteGymCardPress, Pagination, ContainerStyles } = Props;

	const [Expanded, SetExpanded] = useState<boolean>(true);

	if (Loading) {
		return <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } } />;
	}

	if (FavoriteGyms.length === 0) {
		return <EmptyListPlaceholder Label={ EmptyListLabel } ContainerStyle={ { marginBottom: 25, width: SideGutter(20, 20, 768) } } />;
	}

	return (
		<View collapsable style={ ContainerStyles }>
			<Heading
				ContainerStyles={ { justifyContent: "space-between", marginBottom: Expanded ? 10 : 25, width: SideGutter(20, 20, 768) } }
				Left={ <Text style={ Styles.Label }>{ `${ Label }: ${ FavoriteGyms.length }` }</Text> }
				Right={ <AccordionIcon Expanded={ Expanded } OnPress={ () => SetExpanded(!Expanded) } /> }
			/>

			{ Expanded ? FavoriteGyms.map<JSX.Element>((FavoriteGym: IGymEntity, Index: number) => <GymCard
				key={ FavoriteGym.id }
				Gym={ FavoriteGym }
				OnGymBookmarkPress={ (FavoriteGym: IGymEntity) => OnFavoriteGymBookmarkPress(FavoriteGym) }
				OnGymCardPress={ (FavoriteGym: IGymEntity) => OnFavoriteGymCardPress(FavoriteGym) }
				ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } }
			/>) : <></> }

			{ Expanded ? Fetching ? <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } } /> : More ? Pagination : <View collapsable style={ Styles.Placeholder } /> : <></> }
		</View>
	)
};

const Styles = StyleSheet.create({
	Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16
	},
	Placeholder: {
		marginBottom: 15
	}
});

export { FavoriteGymsList, IFavoriteGymsListControls };
