import { IOption } from "../Components";

const BOULDER_GRADES_REVERSE = {
	"hueco": "0",
	"ebleau": "1"
};

const ROUTES_GRADES_FRENCH: IOption[] = [
	{ ID: "1", Label: "1", Value: "[1]" },
	{ ID: "2", Label: "2", Value: "[2]" },
	{ ID: "3", Label: "3A", Value: "[3]" },
	{ ID: "4", Label: "3B", Value: "[4]" },
	{ ID: "5", Label: "3C", Value: "[5]" },
	{ ID: "6", Label: "4A", Value: "[6]" },
	{ ID: "7", Label: "4B", Value: "[7]" },
	{ ID: "8", Label: "4C", Value: "[8]" },
	{ ID: "9", Label: "5A", Value: "[9]" },
	{ ID: "10", Label: "5A+", Value: "[10]" },
	{ ID: "11", Label: "5B", Value: "[11]" },
	{ ID: "12", Label: "5B+", Value: "[12,13]" },
	{ ID: "13", Label: "5C", Value: "[13]" },
	{ ID: "14", Label: "5C+", Value: "[14]" },
	{ ID: "15", Label: "6A", Value: "[15]" },
	{ ID: "16", Label: "6A+", Value: "[15,16]" },
	{ ID: "17", Label: "6B", Value: "[17]" },
	{ ID: "18", Label: "6B+", Value: "[18]" },
	{ ID: "19", Label: "6C", Value: "[18,19]" },
	{ ID: "20", Label: "6C+", Value: "[19]" },
	{ ID: "21", Label: "7A", Value: "[20]" },
	{ ID: "22", Label: "7A+", Value: "[21]" },
	{ ID: "23", Label: "7B", Value: "[21,22]" },
	{ ID: "24", Label: "7B+", Value: "[22]" },
	{ ID: "25", Label: "7C", Value: "[23]" },
	{ ID: "26", Label: "7C+", Value: "[24]" },
	{ ID: "27", Label: "8A", Value: "[24,25]" },
	{ ID: "28", Label: "8A+", Value: "[25]" },
	{ ID: "29", Label: "8B", Value: "[26]" },
	{ ID: "30", Label: "8B+", Value: "[27]" },
	{ ID: "31", Label: "8C", Value: "[28]" },
	{ ID: "32", Label: "8C+", Value: "[28,29]" },
	{ ID: "33", Label: "9A", Value: "[29]" },
	{ ID: "34", Label: "9A+", Value: "[30]" },
	{ ID: "35", Label: "9B", Value: "[31]" },
	{ ID: "36", Label: "9B+", Value: "[32]" },
	{ ID: "37", Label: "9C", Value: "[33]" }
];

const ROUTES_GRADES_USA: IOption[] = [
	{ ID: "1", Label: "4 Class", Value: "[1]" },
	{ ID: "2", Label: "5", Value: "[1, 2]" },
	{ ID: "3", Label: "5.1", Value: "[2]" },
	{ ID: "4", Label: "5.2", Value: "[3]" },
	{ ID: "5", Label: "5.3", Value: "[4]" },
	{ ID: "6", Label: "5.4", Value: "[5]" },
	{ ID: "7", Label: "5.5", Value: "[6]" },
	{ ID: "8", Label: "5.6", Value: "[7, 8]" },
	{ ID: "9", Label: "5.7", Value: "[9, 10]" },
	{ ID: "10", Label: "5.8", Value: "[11, 12]" },
	{ ID: "11", Label: "5.9", Value: "[13, 14]" },
	{ ID: "12", Label: "5.10", Value: "[14, 15]" },
	{ ID: "13", Label: "5.10+", Value: "[14, 15]" },
	{ ID: "14", Label: "5.10A", Value: "[15]" },
	{ ID: "15", Label: "5.10B", Value: "[16]" },
	{ ID: "16", Label: "5.10C", Value: "[17]" },
	{ ID: "17", Label: "5.10D", Value: "[18]" },
	{ ID: "18", Label: "5.11A", Value: "[19]" },
	{ ID: "19", Label: "5.11B", Value: "[20]" },
	{ ID: "20", Label: "5.11C", Value: "[21]" },
	{ ID: "21", Label: "5.11D", Value: "[22]" },
	{ ID: "22", Label: "5.12A", Value: "[23]" },
	{ ID: "23", Label: "5.12B", Value: "[24]" },
	{ ID: "24", Label: "5.12C", Value: "[25]" },
	{ ID: "25", Label: "5.12D", Value: "[26]" },
	{ ID: "26", Label: "5.13A", Value: "[27]" },
	{ ID: "27", Label: "5.13B", Value: "[28]" },
	{ ID: "28", Label: "5.13C", Value: "[29]" },
	{ ID: "29", Label: "5.13D", Value: "[30]" },
	{ ID: "30", Label: "5.14A", Value: "[31]" },
	{ ID: "31", Label: "5.14B", Value: "[32]" },
	{ ID: "32", Label: "5.14C", Value: "[33]" },
	{ ID: "33", Label: "5.14D", Value: "[34]" },
	{ ID: "34", Label: "5.15A", Value: "[35]" },
	{ ID: "35", Label: "5.15B", Value: "[36]" },
	{ ID: "36", Label: "5.15C", Value: "[37]" },
	{ ID: "37", Label: "5.15D", Value: "[38]" }
];

const ROUTES_GRADES_UIAA: IOption[] = [
	{ ID: "1", Label: "1-", Value: "[1]" },
	{ ID: "2", Label: "1", Value: "[1]" },
	{ ID: "3", Label: "1+", Value: "[1]" },
	{ ID: "4", Label: "2-", Value: "[1, 2]" },
	{ ID: "5", Label: "2", Value: "[2]" },
	{ ID: "6", Label: "2+", Value: "[2, 3]" },
	{ ID: "7", Label: "3-", Value: "[3]" },
	{ ID: "8", Label: "3", Value: "[4]" },
	{ ID: "9", Label: "3+", Value: "[5]" },
	{ ID: "10", Label: "4-", Value: "[6]" },
	{ ID: "11", Label: "4", Value: "[7]" },
	{ ID: "12", Label: "4+", Value: "[8]" },
	{ ID: "13", Label: "5-", Value: "[9]" },
	{ ID: "14", Label: "5", Value: "[10]" },
	{ ID: "15", Label: "5+", Value: "[11]" },
	{ ID: "16", Label: "6-", Value: "[12]" },
	{ ID: "17", Label: "6", Value: "[13]" },
	{ ID: "18", Label: "6+", Value: "[14]" },
	{ ID: "19", Label: "7-", Value: "[15]" },
	{ ID: "20", Label: "7", Value: "[16]" },
	{ ID: "21", Label: "7+", Value: "[17]" },
	{ ID: "22", Label: "8-", Value: "[18]" },
	{ ID: "23", Label: "8", Value: "[19]" },
	{ ID: "24", Label: "8+", Value: "[20]" },
	{ ID: "25", Label: "9-", Value: "[21]" },
	{ ID: "26", Label: "9", Value: "[22]" },
	{ ID: "27", Label: "9+", Value: "[23]" },
	{ ID: "28", Label: "9.1+", Value: "[23, 24]" },
	{ ID: "29", Label: "10-", Value: "[24]" },
	{ ID: "30", Label: "10", Value: "[25]" },
	{ ID: "31", Label: "10+", Value: "[26]" },
	{ ID: "32", Label: "11-", Value: "[27]" },
	{ ID: "33", Label: "11", Value: "[28]" },
	{ ID: "34", Label: "11+", Value: "[29]" },
	{ ID: "35", Label: "12-", Value: "[30]" },
	{ ID: "36", Label: "12", Value: "[31]" },
	{ ID: "37", Label: "12+", Value: "[32]" }
];

const ROUTES_GRADES_EWBANKS: IOption[] = [
	{ ID: "1", Label: "1", Value: "[1]" },
	{ ID: "2", Label: "2", Value: "[2]" },
	{ ID: "3", Label: "3", Value: "[3]" },
	{ ID: "4", Label: "4", Value: "[4]" },
	{ ID: "5", Label: "5", Value: "[5]" },
	{ ID: "6", Label: "6", Value: "[6]" },
	{ ID: "7", Label: "7", Value: "[7]" },
	{ ID: "8", Label: "8", Value: "[8]" },
	{ ID: "9", Label: "9", Value: "[9]" },
	{ ID: "10", Label: "10", Value: "[10]" },
	{ ID: "11", Label: "11", Value: "[11]" },
	{ ID: "12", Label: "12", Value: "[12]" },
	{ ID: "13", Label: "13", Value: "[13]" },
	{ ID: "14", Label: "14", Value: "[14]" },
	{ ID: "15", Label: "15", Value: "[15]" },
	{ ID: "16", Label: "16", Value: "[16]" },
	{ ID: "17", Label: "17", Value: "[17]" },
	{ ID: "18", Label: "18", Value: "[18]" },
	{ ID: "19", Label: "19", Value: "[19]" },
	{ ID: "20", Label: "20", Value: "[20]" },
	{ ID: "21", Label: "21", Value: "[21]" },
	{ ID: "22", Label: "22", Value: "[22]" },
	{ ID: "23", Label: "23", Value: "[23]" },
	{ ID: "24", Label: "24", Value: "[24]" },
	{ ID: "25", Label: "25", Value: "[25]" },
	{ ID: "26", Label: "26", Value: "[26]" },
	{ ID: "27", Label: "27", Value: "[27]" },
	{ ID: "28", Label: "28", Value: "[28]" },
	{ ID: "29", Label: "29", Value: "[29]" },
	{ ID: "30", Label: "30", Value: "[30]" },
	{ ID: "31", Label: "31", Value: "[31]" },
	{ ID: "32", Label: "32", Value: "[32]" },
	{ ID: "33", Label: "33", Value: "[33]" },
	{ ID: "34", Label: "34", Value: "[34]" },
	{ ID: "35", Label: "35", Value: "[35]" },
	{ ID: "36", Label: "36", Value: "[36]" },
	{ ID: "37", Label: "37", Value: "[37]" }
];

const ROUTE_GRADES = {
	"0": ROUTES_GRADES_UIAA,
	"1": ROUTES_GRADES_FRENCH,
	"2": ROUTES_GRADES_USA,
	"3": ROUTES_GRADES_EWBANKS
};

const ROUTE_GRADES_REVERSE = {
	"UIAA": "0",
	"French": "1",
	"US": "2",
	"EWBANKS": "3"
};

export { BOULDER_GRADES_REVERSE };
export { ROUTE_GRADES };
export { ROUTE_GRADES_REVERSE };
