import Ionicons from "@expo/vector-icons/Ionicons";
import { NavigationProp, ParamListBase, RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";

import { Carousel } from "../../../Components";
import { Heading } from "../../../Components/Heading";

import { I18NContext, II18NContext } from "../../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../../Providers/Keyboard-Provider";

import { SideGutter, TopGutter } from "../../../Utilities";

const ImageGallery = (): JSX.Element => {

	const { I18N }: II18NContext = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();
	const Route: RouteProp<ParamListBase> = useRoute<RouteProp<ParamListBase>>();

	const [Images, SetImages] = useState<string[]>([]);

	const [Trivia, SetTrivia] = useState<string>("");
	const [Address, SetAddress] = useState<string>("");

	useEffect(() => {
		SetImages(Route.params.Gallery);
		SetAddress(Route.params.Address);
		SetTrivia(Route.params.Trivia);
	}, [Route.params]);

	return (
		<SafeAreaView collapsable>
			<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" showsVerticalScrollIndicator>
				<Heading
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Title={ I18N.t(["ImageGalleryScreen", "ImageGallery"]) }
				/>

				<Carousel
					Images={ Images }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(0, 0, 768) } }
				/>

				{ Trivia ? <Text style={ Styles.Trivia }>{ Trivia }</Text> : <></> }

				<Heading
					ContainerStyles={ { justifyContent: "space-between", marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Left={
						<View collapsable style={ Styles.Address_Container }>
							<Ionicons color="#000" name="location" size={ 25 } />
							<Text ellipsizeMode="tail" numberOfLines={ 2 } style={ Styles.Address }>{ Address }</Text>
						</View>
					}
				/>

				{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
			</ScrollView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	Address_Container: {
		flex: 1,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	Trivia: {
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		textAlign: "justify",
		marginBottom: 25, width: SideGutter(20, 20, 768)
	},
	Address: {
		color: "#000",
		flex: 1,
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginLeft: 5,
		textAlign: "justify"
	}
});

export { ImageGallery };
