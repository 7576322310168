import Ionicons from "@expo/vector-icons/Ionicons";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { IsMobileDevice } from "../../../Utilities";

interface IScanIconProps {
	OnPress: () => void;
};

const ScanIcon = (Props: IScanIconProps): JSX.Element => {

	const { OnPress } = Props;

	if (!IsMobileDevice()) {
		return <View collapsable style={ Styles.Placeholder } />;
	}

	return (
		<TouchableOpacity onPress={ () => OnPress() }>
			<Ionicons color="#000" name="qr-code" size={ 25 } />
		</TouchableOpacity>
	);
};

const Styles = StyleSheet.create({
	Placeholder: {
		height: 25,
		margin: 5,
		width: 25
	}
});

export { ScanIcon };
