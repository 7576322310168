import { StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";

interface ITwoSideLabelsProps {
	LeftLabel: string;
	RightLabel: string;
	ContainerStyles?: ViewStyle;
	LeftLabelStyles?: TextStyle;
	OnLeftLabelPress?: () => void;
	OnRightLabelPress?: () => void;
	RightLabelStyles?: TextStyle;
};

const TwoSideLabels = (Props: ITwoSideLabelsProps): JSX.Element => {

	const { LeftLabel, RightLabel, ContainerStyles, LeftLabelStyles, OnLeftLabelPress, OnRightLabelPress, RightLabelStyles } = Props;

	return (
		<View collapsable style={ [Styles.Flex_Container, ContainerStyles] }>
			<TouchableOpacity disabled={ OnLeftLabelPress ? false : true } onPress={ () => OnLeftLabelPress ? OnLeftLabelPress() : {} }>
				<Text style={ LeftLabelStyles }>{ LeftLabel }</Text>
			</TouchableOpacity>

			<TouchableOpacity disabled={ OnRightLabelPress ? false : true } onPress={ () => OnRightLabelPress ? OnRightLabelPress() : {} }>
				<Text style={ RightLabelStyles }>{ RightLabel }</Text>
			</TouchableOpacity>
		</View>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	}
});

export { TwoSideLabels };
