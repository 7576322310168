import { ActivityIndicator, StyleSheet, View, ViewStyle } from "react-native";

interface ILoaderProps {
	Color: string;
	Size: number;
	ContainerStyles?: ViewStyle;
};

const Loader = (Props: ILoaderProps): JSX.Element => {

	const { Color, Size, ContainerStyles } = Props;

	return (
		<View collapsable style={ [Styles.Flex_Container, ContainerStyles] }>
			<ActivityIndicator collapsable color={ Color } size={ Size } />
		</View>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center"
	}
});

export { Loader };
