import { NavigationProp, StackActions, useNavigation } from "@react-navigation/native";
import { useContext, useState } from "react";
import { KeyboardAvoidingView, SafeAreaView, ScrollView, StyleSheet, Text } from "react-native";

import { AnimatedModal, Button, Input } from "../../Components";

import { EMAIL_REGEXP } from "../../Constants/Validation";

import { I18NContext, II18NContext } from "../../Providers/I18N-Provider";

import { SideGutter, TopGutter } from "../../Utilities";

const ForgotPassword = (): JSX.Element => {

	const { I18N } = useContext<II18NContext>(I18NContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();

	const [Email, SetEmail] = useState<string>("");

	const [Modal, SetModal] = useState({ Title: "", Message: "", Visible: false });

	const Submit = (): void => {
		if (EMAIL_REGEXP.test(Email)) {

		} else {
			SetModal({ Title: I18N.t(["ForgotPasswordScreen", "FormFilledOutIncorrectly"]), Message: I18N.t(["ForgotPasswordScreen", "FillInAllTheFieldsToContinue"]), Visible: true });
		}
	};

	return (
		<SafeAreaView collapsable>
			<KeyboardAvoidingView collapsable>
				<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" keyboardShouldPersistTaps="handled" showsVerticalScrollIndicator>
					<Text style={ Styles.Slogan }>{ I18N.t(["ForgotPasswordScreen", "EnterYourEmail"]) }</Text>

					<Input
						Label={ I18N.t(["ForgotPasswordScreen", "Email"]) }
						OnChange={ (Text: string) => SetEmail(Text) }
						Placeholder={ I18N.t(["ForgotPasswordScreen", "EnterEmail"]) }
						Value={ Email }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					/>

					<Button
						Label={ I18N.t(["ForgotPasswordScreen", "Submit"]) }
						OnPress={ () => Submit() }
						Theme="#ED2020"
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
					/>

					<Button
						Label={ I18N.t(["ForgotPasswordScreen", "GoBack"]) }
						OnPress={ () => Navigation.dispatch(StackActions.pop(1)) }
						Theme="#000"
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
					/>

					<AnimatedModal Title={ Modal.Title } Message={ Modal.Message } Visible={ Modal.Visible }>
						<Button
							Label={ I18N.t(["ForgotPasswordScreen", "Dismiss"]) }
							OnPress={ () => SetModal({ Title: "", Message: "", Visible: false }) }
							Theme="#000"
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/>
					</AnimatedModal>
				</ScrollView>
			</KeyboardAvoidingView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	Slogan: {
		color: "#000",
		fontFamily: "Montserrat-Bold",
		fontSize: 20,
		marginBottom: 25
	}
});

export { ForgotPassword };
