import { Context, Dispatch, ReactNode, createContext, useMemo, useReducer } from "react";

import { IOption } from "../Components";

interface ICredentials {
	ConfirmPassword: string;
	Email: string;
	Name: string;
	Password: string;
	Reach: string;
	Surname: string;
};

interface ICredentialsAction {
	Payload: string;
	Type: "CHANGE_CONFIRM_PASSWORD" | "CHANGE_EMAIL" | "CHANGE_NAME" | "CHANGE_PASSWORD" | "CHANGE_REACH" | "CHANGE_SURNAME" | "QUIT_SIGN_UP_WIZARD";
};

interface ICredentialsContext {
	Credentials: ICredentials;
	DispatchCredentials: Dispatch<ICredentialsAction>;
	DispatchPersonalSettings: Dispatch<IPersonalSettingsAction>;
	PersonalSettings: IPersonalSettings;
};

interface ICredentialsProviderProps {
	children: ReactNode;
};

interface IPersonalSettings {
	BoulderGrade: IOption;
	BoulderGradeSystem: IOption;
	RouteGrade: IOption;
	RouteGradeSystem: IOption;
	UnitSystem: IOption;
};

interface IPersonalSettingsAction {
	Payload: IOption;
	Type: "QUIT_SIGN_UP_WIZARD" | "CHANGE_BOULDER_GRADE" | "CHANGE_BOULDER_GRADE_SYSTEM" | "CHANGE_ROUTE_GRADE" | "CHANGE_ROUTE_GRADE_SYSTEM" | "CHANGE_UNIT_SYSTEM" | "CLEAR_PERSONAL_SETTINGS";
};

const InitialCredentialsState: ICredentials = {
	ConfirmPassword: "",
	Email: "",
	Name: "",
	Password: "",
	Reach: "",
	Surname: ""
};

const InitialPersonalSettingsState: IPersonalSettings = {
	BoulderGrade: { ID: "", Label: "", Value: "" },
	BoulderGradeSystem: { ID: "", Label: "", Value: "" },
	RouteGrade: { ID: "", Label: "", Value: "" },
	RouteGradeSystem: { ID: "", Label: "", Value: "" },
	UnitSystem: { ID: "", Label: "", Value: "" }
};

const CredentialsReducer = (State: ICredentials, Action: ICredentialsAction): ICredentials => {

	switch (Action.Type) {
		case "CHANGE_CONFIRM_PASSWORD":
			return {
				...State,
				ConfirmPassword: Action.Payload
			};

		case "CHANGE_EMAIL":
			return {
				...State,
				Email: Action.Payload
			};

		case "CHANGE_NAME":
			return {
				...State,
				Name: Action.Payload
			};

		case "CHANGE_PASSWORD":
			return {
				...State,
				Password: Action.Payload
			};

		case "CHANGE_REACH":
			return {
				...State,
				Reach: Action.Payload
			};

		case "CHANGE_SURNAME":
			return {
				...State,
				Surname: Action.Payload
			};

		case "QUIT_SIGN_UP_WIZARD":
			return InitialCredentialsState;

		default:
			throw new Error("INVALID ACTION !");
	}
};

const PersonalSettingsReducer = (State: IPersonalSettings, Action: IPersonalSettingsAction): IPersonalSettings => {

	switch (Action.Type) {
		case "CHANGE_BOULDER_GRADE":
			return {
				...State,
				BoulderGrade: Action.Payload
			};

		case "CHANGE_BOULDER_GRADE_SYSTEM":
			return {
				...State,
				BoulderGradeSystem: Action.Payload
			};

		case "CHANGE_ROUTE_GRADE":
			return {
				...State,
				RouteGrade: Action.Payload
			};

		case "CHANGE_ROUTE_GRADE_SYSTEM":
			return {
				...State,
				RouteGradeSystem: Action.Payload
			};

		case "CHANGE_UNIT_SYSTEM":
			return {
				...State,
				UnitSystem: Action.Payload
			};

		case "CLEAR_PERSONAL_SETTINGS":
			return InitialPersonalSettingsState;

		case "QUIT_SIGN_UP_WIZARD":
			return InitialPersonalSettingsState;

		default:
			throw new Error("INVALID ACTION !");
	}
};

const CredentialsContext: Context<ICredentialsContext> = createContext<ICredentialsContext>({
	Credentials: InitialCredentialsState,
	DispatchCredentials: () => {},
	DispatchPersonalSettings: () => {},
	PersonalSettings: InitialPersonalSettingsState
});

const CredentialsProvider = (Props: ICredentialsProviderProps): JSX.Element => {

	const { children } = Props;

	const [Credentials, DispatchCredentials] = useReducer(CredentialsReducer, InitialCredentialsState);
	const [PersonalSettings, DispatchPersonalSettings] = useReducer(PersonalSettingsReducer, InitialPersonalSettingsState);

	const CredentialsContext_Memoized: ICredentialsContext = useMemo<ICredentialsContext>(() => ({
		Credentials,
		DispatchCredentials,
		DispatchPersonalSettings,
		PersonalSettings
	}), [Credentials, PersonalSettings]);

	return (
		<CredentialsContext.Provider value={ CredentialsContext_Memoized }>
			{ children }
		</CredentialsContext.Provider>
	);
};

export { CredentialsContext };
export { CredentialsProvider };
export { ICredentials };
export { ICredentialsContext };
