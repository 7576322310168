import { NativeStackNavigationOptions, createNativeStackNavigator } from "@react-navigation/native-stack";

import { PublicProfile } from "../Screens/Public-Profile";
import { ForgotPassword, GetStarted, Login, PersonalSettings, SignUp } from "../Screens/Authentication-Flow";

const AuthenticationFlow = (): JSX.Element => {

	const NativeStack = createNativeStackNavigator();

	const NativeStackScreenOptions: NativeStackNavigationOptions = {
		animation: "slide_from_right",
		contentStyle: {
			backgroundColor: "#F5F5F5",
			overflow: "scroll"
		},
		headerShown: false,
		statusBarStyle: "dark",
		statusBarHidden: true,
		statusBarTranslucent: true
	};

	return (
		<NativeStack.Navigator initialRouteName="Get Started" screenOptions={ NativeStackScreenOptions }>
			<NativeStack.Screen name="Forgot Password" component={ ForgotPassword } />
			<NativeStack.Screen name="Get Started" component={ GetStarted } />
			<NativeStack.Screen name="Login" component={ Login } />
			<NativeStack.Screen name="Personal Settings" component={ PersonalSettings } />
			<NativeStack.Screen name="Public Profile" component={ PublicProfile } />
			<NativeStack.Screen name="Sign Up" component={ SignUp } />
		</NativeStack.Navigator>
	);
};

export { AuthenticationFlow };
