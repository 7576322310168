import { Fragment } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import { EmptyListPlaceholder } from "./Empty-List-Placeholder";

import { RouteCard } from "../Route-Card";
import { Loader } from "../Loader";

import { IRouteEntity } from "../../Interfaces/Entities";

import { SideGutter } from "../../Utilities";

interface IRoutesListControls {
	Fetching: boolean;
	Limit: number;
	Loading: boolean;
	More: boolean;
	Page: number;
};

interface IRoutesListProps {
	EmptyListLabel: string;
	Fetching: boolean;
	Loading: boolean;
	More: boolean;
	OnRouteBookmarkPress: (Route: IRouteEntity) => void;
	OnRouteCardPress: (Route: IRouteEntity) => void;
	Pagination: JSX.Element;
	Routes: IRouteEntity[];
	ContainerStyles?: ViewStyle;
	Header?: JSX.Element;
	BookmarkHidden?: boolean;
};

interface IRoutesPayload {
	Fetching: boolean;
	Limit: number;
	Loading: boolean;
	More: boolean;
	Page: number;
	Payload: IRouteEntity[];
};

const RoutesList = (Props: IRoutesListProps): JSX.Element => {

	const { EmptyListLabel, Fetching, Loading, More, OnRouteBookmarkPress, OnRouteCardPress, Pagination, Routes, ContainerStyles, Header, BookmarkHidden } = Props;

	if (Loading) {
		return <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } } />;
	}

	if (Routes.length === 0) {
		return (
			<Fragment>
				{ Header ? Header : <></> }
				<EmptyListPlaceholder Label={ EmptyListLabel } ContainerStyle={ { marginBottom: 25, width: SideGutter(20, 20, 768) } } />
			</Fragment>
		);
	}

	return (
		<View collapsable style={ ContainerStyles }>
			{ Header ? Header : <></> }

			<View collapsable>
				{ Routes.map<JSX.Element>((Route: IRouteEntity, Index: number) => <RouteCard
					key={ Route.id }
					OnRouteBookmarkPress={ (Route: IRouteEntity) => OnRouteBookmarkPress(Route) }
					OnRouteCardPress={ (Route: IRouteEntity) => OnRouteCardPress(Route) }
					Route={ Route }
					ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } }
					BookmarkHidden={ BookmarkHidden ? true : false }
				/>) }
			</View>

			{ Fetching ? <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } } /> : More ? Pagination : <View collapsable style={ Styles.Placeholder } /> }
		</View>
	)
};

const Styles = StyleSheet.create({
	Placeholder: {
		marginBottom: 15
	}
});

export { IRoutesListControls };
export { IRoutesPayload };
export { RoutesList };
