import { NavigationProp, StackActions, useNavigation } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useContext, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, Text } from "react-native";

import { AnimatedModal, Button, Dropdown, IOption, Input } from "../../Components";
import { SideLabel } from "../../Components/Typography";

import { ALL_DIGITS_REGEXP, BOULDER_GRADES, GRADE_SYSTEM_ROUTE_BG_BG, GRADE_SYSTEM_ROUTE_EN_US, GRADE_SYSTEM_BOULDER, ROUTE_GRADES, UNIT_SYSTEM_BG_BG, UNIT_SYSTEM_EN_US } from "../../Constants";

import { IAuthRegisterLoginDto } from "../../Interfaces/Dto";
import { IUserEntity } from "../../Interfaces/Entities";

import { AuthenticationContext, IAuthenticationContext } from "../../Providers/Authentication-Provider";
import { CredentialsContext, ICredentialsContext } from "../../Providers/Credentials-Provider";
import { HTTPClientContext, IHTTPClientContext } from "../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../Providers/I18N-Provider";

import { SideGutter, TopGutter } from "../../Utilities";

const PersonalSettings = (): JSX.Element => {

	const _AuthenticationContext: IAuthenticationContext = useContext<IAuthenticationContext>(AuthenticationContext);
	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();

	const _CredentialsContext: ICredentialsContext = useContext<ICredentialsContext>(CredentialsContext);
	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N, Locale }: II18NContext = useContext<II18NContext>(I18NContext);

	const [Loading, SetLoading] = useState<boolean>(false);
	const [Modal, SetModal] = useState({ Title: "", Message: "", Visible: false });

	const Skip = async (): Promise<void> => {

		SetLoading(true);

		try {
			const AxiosResponse_Result:  AxiosResponse<IUserEntity, IAuthRegisterLoginDto> = await _HTTPClientContext.HTTPClient.post<IUserEntity, AxiosResponse<IUserEntity, IAuthRegisterLoginDto>, IAuthRegisterLoginDto>("/users/basic", {
				boulderGrade: BOULDER_GRADES[0][0].Value,
				boulderGradeSystem: GRADE_SYSTEM_BOULDER[0].Value,
				email: _CredentialsContext.Credentials.Email,
				firstName: _CredentialsContext.Credentials.Name,
				height: 1,
				lastName: _CredentialsContext.Credentials.Surname,
				password: _CredentialsContext.Credentials.Password,
				routeGrade: ROUTE_GRADES[0][0].Value,
				routeGradeSystem: GRADE_SYSTEM_ROUTE_EN_US[0].Value,
				unitSystem: UNIT_SYSTEM_EN_US[0].Value
			});

			const JWT: string = AxiosResponse_Result.data.token;
			const User: IUserEntity = AxiosResponse_Result.data;

			_AuthenticationContext.Login(JWT, User);

		} catch (Error) {
			SetLoading(false);
			SetModal({ Title: I18N.t(["PersonalSettingsScreen", "EmailAlreadyTaken"]), Message: I18N.t(["PersonalSettingsScreen", "SignUpWithDifferentEmail"]), Visible: true });
		}
	};

	const Next = async (): Promise<void> => {

		SetLoading(true);

		try {
			const AxiosResponse_Result:  AxiosResponse<IUserEntity, IAuthRegisterLoginDto> = await _HTTPClientContext.HTTPClient.post<IUserEntity, AxiosResponse<IUserEntity, IAuthRegisterLoginDto>, IAuthRegisterLoginDto>("/users/basic", {
				boulderGrade: _CredentialsContext.PersonalSettings.BoulderGrade.Value,
				boulderGradeSystem: _CredentialsContext.PersonalSettings.BoulderGradeSystem.Value,
				email: _CredentialsContext.Credentials.Email,
				firstName: _CredentialsContext.Credentials.Name,
				height: parseInt(_CredentialsContext.Credentials.Reach, 10),
				lastName: _CredentialsContext.Credentials.Surname,
				password: _CredentialsContext.Credentials.Password,
				routeGrade: _CredentialsContext.PersonalSettings.RouteGrade.Value,
				routeGradeSystem: _CredentialsContext.PersonalSettings.RouteGradeSystem.Value,
				unitSystem: _CredentialsContext.PersonalSettings.UnitSystem.Value
			});

			const JWT: string = AxiosResponse_Result.data.token;
			const User: IUserEntity = AxiosResponse_Result.data;

			_AuthenticationContext.Login(JWT, User);

		} catch (Error) {
			SetLoading(false);
			SetModal({ Title: I18N.t(["PersonalSettingsScreen", "EmailAlreadyTaken"]), Message: I18N.t(["PersonalSettingsScreen", "SignUpWithDifferentEmail"]), Visible: true });
		}
	};

	return (
		<SafeAreaView collapsable>
			<ScrollView collapsable contentContainerStyle={Styles.Flex_Container} contentInsetAdjustmentBehavior="automatic" showsVerticalScrollIndicator>
				<Text style={ Styles.GradeSystem_Label }>{ I18N.t(["PersonalSettingsScreen", "WhatsYourPreferredGradeSystem"]) }</Text>

				<Dropdown
					OnOptionSelect={ (SelectedOption: IOption) => _CredentialsContext.DispatchPersonalSettings({ Payload: SelectedOption, Type: "CHANGE_BOULDER_GRADE_SYSTEM" }) }
					Options={ GRADE_SYSTEM_BOULDER }
					OptionsShown={ 5 }
					Placeholder={ I18N.t(["PersonalSettingsScreen", "BoulderGradeSystem"]) }
					SelectedOption={ _CredentialsContext.PersonalSettings.BoulderGradeSystem }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/>

				<Dropdown
					OnOptionSelect={ (SelectedOption: IOption) => _CredentialsContext.DispatchPersonalSettings({ Payload: SelectedOption, Type: "CHANGE_ROUTE_GRADE_SYSTEM" }) }
					Options={ Locale === "bg-BG" ? GRADE_SYSTEM_ROUTE_BG_BG : GRADE_SYSTEM_ROUTE_EN_US }
					OptionsShown={ 5 }
					Placeholder={ I18N.t(["PersonalSettingsScreen", "RouteGradeSystem"]) }
					SelectedOption={ _CredentialsContext.PersonalSettings.RouteGradeSystem }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/>

				<Text style={ Styles.UnitSystem_Label }>{ I18N.t(["PersonalSettingsScreen", "WhatsYourPreferredUnitSystem"]) }</Text>
				<Dropdown
					OnOptionSelect={ (SelectedOption: IOption) => _CredentialsContext.DispatchPersonalSettings({ Payload: SelectedOption, Type: "CHANGE_UNIT_SYSTEM" }) }
					Options={ Locale === "bg-BG" ? UNIT_SYSTEM_BG_BG : UNIT_SYSTEM_EN_US }
					OptionsShown={ 5 }
					Placeholder={ I18N.t(["PersonalSettingsScreen", "UnitSystem"]) }
					SelectedOption={ _CredentialsContext.PersonalSettings.UnitSystem }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/>

				<Text style={ Styles.UnitSystem_Label }>{ I18N.t(["PersonalSettingsScreen", "WhatsYourReach"]) }</Text>
				<Input
					Label={ I18N.t(["PersonalSettingsScreen", "Reach"]) }
					OnChange={ (Text: string) => _CredentialsContext.DispatchCredentials({ Payload: parseInt(Text, 10) ? Text.replace(/^0+/, "") : "", Type: "CHANGE_REACH" }) }
					Placeholder={ I18N.t(["PersonalSettingsScreen", "EnterReach"]) }
					Value={ _CredentialsContext.Credentials.Reach }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/>

				{ Object.keys(_CredentialsContext.PersonalSettings.BoulderGradeSystem.ID).length && Object.keys(_CredentialsContext.PersonalSettings.RouteGradeSystem.ID).length ? <Text style={ Styles.HardestSend_Label }>{ I18N.t(["PersonalSettingsScreen", "WhatsYourHardestSend"]) }</Text> : <></> }
				{ Object.keys(_CredentialsContext.PersonalSettings.BoulderGradeSystem.ID).length && Object.keys(_CredentialsContext.PersonalSettings.RouteGradeSystem.ID).length ? <Text style={ Styles.Hint_Label }>{ I18N.t(["PersonalSettingsScreen", "ThisInformationWillHelpPersonalizeYourApplicationExperience"]) }</Text> : <></> }

				{ Object.keys(_CredentialsContext.PersonalSettings.BoulderGradeSystem.ID).length && Object.keys(_CredentialsContext.PersonalSettings.RouteGradeSystem.ID).length ? <Dropdown
					OnOptionSelect={ (SelectedOption: IOption) => _CredentialsContext.DispatchPersonalSettings({ Payload: SelectedOption, Type: "CHANGE_BOULDER_GRADE" }) }
					Options={ _CredentialsContext.PersonalSettings.BoulderGradeSystem.ID === "0" ? BOULDER_GRADES[0] : BOULDER_GRADES[1] }
					OptionsShown={ 5 }
					Placeholder={ I18N.t(["PersonalSettingsScreen", "BoulderGrade"]) }
					SelectedOption={ _CredentialsContext.PersonalSettings.BoulderGrade }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/> : <></> }

				{ Object.keys(_CredentialsContext.PersonalSettings.BoulderGradeSystem.ID).length && Object.keys(_CredentialsContext.PersonalSettings.RouteGradeSystem.ID).length ? <Dropdown
					OnOptionSelect={ (SelectedOption: IOption) => _CredentialsContext.DispatchPersonalSettings({ Payload: SelectedOption, Type: "CHANGE_ROUTE_GRADE" }) }
					Options={ _CredentialsContext.PersonalSettings.RouteGradeSystem.ID === "0" ? ROUTE_GRADES["0"] : _CredentialsContext.PersonalSettings.RouteGradeSystem.ID === "1" ? ROUTE_GRADES["1"] : _CredentialsContext.PersonalSettings.RouteGradeSystem.ID === "2" ? ROUTE_GRADES["2"] : ROUTE_GRADES["3"] }
					OptionsShown={ 5 }
					Placeholder={ I18N.t(["PersonalSettingsScreen", "RouteGrade"]) }
					SelectedOption={ _CredentialsContext.PersonalSettings.RouteGrade }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/> : <></> }

				<SideLabel
					Label={ I18N.t(["PersonalSettingsScreen", "Skip"]) }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelOnRight
					OnPress={ () => Loading ? {} : Skip() }
				/>

				{ (_CredentialsContext.PersonalSettings.BoulderGrade.ID && _CredentialsContext.PersonalSettings.BoulderGradeSystem.ID && ALL_DIGITS_REGEXP.test(_CredentialsContext.Credentials.Reach) && _CredentialsContext.PersonalSettings.RouteGrade.ID && _CredentialsContext.PersonalSettings.RouteGradeSystem.ID && _CredentialsContext.PersonalSettings.UnitSystem.ID) ? <Button
					Label={ I18N.t(["PersonalSettingsScreen", "Next"]) }
					OnPress={ () => Next() }
					Theme="#ED2020"
					Disabled={ Loading }
					ContainerStyles={ {  marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/> : <></> }

				{ (_CredentialsContext.PersonalSettings.BoulderGrade.ID || _CredentialsContext.PersonalSettings.BoulderGradeSystem.ID || parseInt(_CredentialsContext.Credentials.Reach, 10) || _CredentialsContext.PersonalSettings.RouteGrade.ID || _CredentialsContext.PersonalSettings.RouteGradeSystem.ID || _CredentialsContext.PersonalSettings.UnitSystem.ID) ? <Button
					Label={ I18N.t(["PersonalSettingsScreen", "ClearPersonalSettings"]) }
					OnPress={ () => {
						_CredentialsContext.DispatchCredentials({ Payload: "", Type: "CHANGE_REACH" });
						_CredentialsContext.DispatchPersonalSettings({ Payload: { ID: "", Label: "", Value: "" }, Type: "CLEAR_PERSONAL_SETTINGS" });
					} }
					Theme="#000"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/> : <></> }

				<Button
					Label={ I18N.t(["PersonalSettingsScreen", "QuitSignUpWizard"]) }
					OnPress={ () => {
						_CredentialsContext.DispatchCredentials({ Payload: "", Type: "QUIT_SIGN_UP_WIZARD" });
						_CredentialsContext.DispatchPersonalSettings({ Payload: { ID: "", Label: "", Value: "" }, Type: "QUIT_SIGN_UP_WIZARD" });

						Navigation.dispatch(StackActions.pop(2));
					} }
					Theme="#000"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/>

				<AnimatedModal Title={ Modal.Title } Message={ Modal.Message } Visible={ Modal.Visible }>
					<Button
						Label={ I18N.t(["PersonalSettingsScreen", "Dismiss"]) }
						OnPress={ () => {
							SetModal({ Title: "", Message: "", Visible: false });
							Navigation.dispatch(StackActions.pop(1));
						} }
						Disabled={ Loading }
						Theme="#000"
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						LoaderColor="#FFF"
					/>
				</AnimatedModal>
			</ScrollView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	GradeSystem_Label: {
		color: "#000",
		fontFamily: "Montserrat-Bold",
		fontSize: 20,
		marginBottom: 25,
		textAlign: "center",
		width: SideGutter(50, 50, 768)
	},
	UnitSystem_Label: {
		color: "#000",
		fontFamily: "Montserrat-Bold",
		fontSize: 20,
		marginBottom: 25,
		textAlign: "center",
		width: SideGutter(50, 50, 768)
	},
	HardestSend_Label: {
		color: "#000",
		fontFamily: "Montserrat-Bold",
		fontSize: 20,
		marginBottom: 10,
		textAlign: "center",
		width: SideGutter(50, 50, 768)
	},
	Hint_Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 12,
		marginBottom: 25,
		textAlign: "center",
		width: SideGutter(50, 50, 768)
	}
});

export { PersonalSettings };
