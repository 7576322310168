import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import { Image } from "expo-image";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { CommonActions, StackActions, useFocusEffect, useIsFocused, useNavigation, useRoute } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useContext, useEffect, useState, useCallback } from "react";
import { KeyboardAvoidingView, Platform, SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";
import { SheetManager } from "react-native-actions-sheet";

import { AnimatedModal, Button, FabButton, IComment, IOption, StarRating, Tabulator } from "../../../Components";
import { Heading } from "../../../Components/Heading";
import { BookmarkIcon } from "../../../Components/Heading/Icons";
import { CommentsList, GymsList, IGymsPayload, IRoutesPayload, RoutesList } from "../../../Components/Lists";
import { IManageComment, IReportRoute } from "../../../Components/Sheets";
import { SideLabel } from "../../../Components/Typography";

import { ROUTE_GRADES, ROUTE_GRADES_REVERSE, SORT_CRITERIA_BG_BG, SORT_CRITERIA_EN_US, eEWallsAPI } from "../../../Constants";

import { IGymEntity, IGymsListEntity, IRouteEntity, IUserEntity } from "../../../Interfaces/Entities";

import { AuthenticationContext, IAuthenticationContext } from "../../../Providers/Authentication-Provider";
import { HTTPClientContext, IHTTPClientContext } from "../../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../../Providers/Keyboard-Provider";

import { BuildFilterQuery, SideGutter, TopGutter } from "../../../Utilities";
import { ICommentPayload, ICommentsListControls } from "../../../Components/Comment";

const RouteDetails = (): JSX.Element => {

	const Navigation = useNavigation();
	const _Route = useRoute();
	const IsFocused = useIsFocused();

	const _AuthenticationContext: IAuthenticationContext = useContext<IAuthenticationContext>(AuthenticationContext);
	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N, Locale }: II18NContext = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const [Modal, SetModal] = useState({ Title: "", Message: "", Visible: false });
	const [Route, SetRoute] = useState<IRouteEntity>(null as any);
	const [Project, SetProject] = useState<boolean>(false);
	const [Tops, SetTops] = useState([]);
	const [Rating, SetRating] = useState<number>(0);
	const [RouteGrade, SetRouteGrade] = useState<IOption>({ ID: "", Label: "", Value: "" });

	const [Tab, SetTab] = useState<string>("Similar Routes");
	const [MapTab, SetMapTab] = useState<string>("Video");

	const [SimilarRoutes, SetSimilarRoutes] = useState<IRoutesPayload>({ Fetching: false, Limit: 5, Loading: false, More: false, Page: 1, Payload: [] });
	const [Gyms, SetGyms] = useState<IGymsPayload>({ Fetching: false, Limit: 5, Loading: false, More: false, Page: 1, Payload: [] });
	const [Comments, SetComments] = useState<ICommentPayload>({ Fetching: false, Limit: 5, Loading: false, More: false, Page: 1, Payload: [] });
	const [CommentsListControls, SetCommentsListControls] = useState<ICommentsListControls>({ Fetching: false, Loading: false, More: false, Page: 1, Payload: [] });
	const [User, SetUser] = useState<IUserEntity | null>(null);

	const [RouteFilters, SetRouteFilters] = useState<IRouteFilters>({ Active: false, BoulderGradeFrom: 1, BoulderGradeTo: 26, FilterBy: { ID: "", Label: "", Value: "" }, Locale, RatingFrom: 0, RatingTo: 5, RouteGradeFrom: 1, RouteGradeTo: 37, RouteType: null, I18N });
	const [SortCriterion, SetSortCriterion] = useState<IOption>({ ID: "", Label: "", Value: "" });

	useFocusEffect(useCallback(() => {
		if (!Route) {
			return;
		}

		(async () => {
			const AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get<string, AxiosResponse<string, void>, void>("users/current-user");
			SetUser(AxiosResponse_Result.data);

			const RouteGrade = Array.of(Route.grade);
			const RouteGradeSystem = AxiosResponse_Result.data.routeGradeSystem;
			const RouteGradeTransformed: IOption = (ROUTE_GRADES[ROUTE_GRADES_REVERSE[RouteGradeSystem]] as IOption[]).filter((value: IOption) => value.Value === JSON.stringify(RouteGrade))[0];

			SetRouteGrade(RouteGradeTransformed);
		})();
	}, [Route]));

	useEffect(() => {
		getRouteDetails();
	}, [_Route.params]);

	useEffect(() => {
		if (IsFocused) {
			getRouteDetails();
		}
	}, [IsFocused]);

	useEffect(() => {
		(async() => {
			const AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get(`routes?limit=${ SimilarRoutes.Limit }&page=${ 1 }&ratingFrom=${ RouteFilters.RatingFrom >= 0 ? RouteFilters.RatingFrom : null }&ratingTo=${ RouteFilters.RatingTo >= 0 ? RouteFilters.RatingTo : null }&sortBy=${ SortCriterion.Value ? SortCriterion.Value : null }&${ BuildFilterQuery(RouteFilters, User) }`);
			SetSimilarRoutes({ ...SimilarRoutes, Page: 1, Payload: AxiosResponse_Result.data.data, Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [RouteFilters.BoulderGradeFrom, RouteFilters.BoulderGradeTo, RouteFilters.RatingFrom, RouteFilters.RatingTo, RouteFilters.RouteGradeFrom, RouteFilters.RouteGradeTo, RouteFilters.RouteType, SortCriterion.Value]);

	useEffect(() => {
		if (SimilarRoutes.Page === 1) {
			return;
		}

		(async() => {
			SetSimilarRoutes({ ...SimilarRoutes, Fetching: SimilarRoutes.Page === 1 ? false : true, Loading: SimilarRoutes.Page === 1 ? true : false });
			const AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get(`routes?limit=${ SimilarRoutes.Limit }&page=${ SimilarRoutes.Page }&ratingFrom=${ RouteFilters.RatingFrom >= 0 ? RouteFilters.RatingFrom : null }&ratingTo=${ RouteFilters.RatingTo >= 0 ? RouteFilters.RatingTo : null }&sortBy=${ SortCriterion.Value ? SortCriterion.Value : null }&${ BuildFilterQuery(RouteFilters, User) }`);
			SetSimilarRoutes({ ...SimilarRoutes, Payload: [...SimilarRoutes.Payload, ...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [SimilarRoutes.Page]);

	useEffect(() => {
		(async () => {
			SetGyms({ ...Gyms, Fetching: Gyms.Page === 1 ? false : true, Loading: Gyms.Page === 1 ? true : false });
			const AxiosResponse_Result: AxiosResponse<IGymsListEntity> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`locations?limit=${ Gyms.Limit }&page=${ Gyms.Page }`);
			SetGyms({ ...Gyms, Payload: [...Gyms.Payload, ...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [Gyms.Page]);

	const getRouteDetails = async () => {
		const routeId = _Route.params.id;

		let AxiosResponse_Result: AxiosResponse<any> = await _HTTPClientContext.HTTPClient.get("routes/" + routeId);
		SetRoute(AxiosResponse_Result.data);
		SetProject(AxiosResponse_Result.data.isProject)
		SetRating(AxiosResponse_Result.data.rating);
		SetTops(AxiosResponse_Result.data.tops);

		AxiosResponse_Result = await _HTTPClientContext.HTTPClient.get("comments/by-route/" + routeId);
		SetComments({ ...Comments, Payload: [...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
	};

	const OpenLogAscentSheet = async (): Promise<void> => {
		const Payload = await SheetManager.show<any, any>("Log-Ascent-Sheet", { payload: { RouteGrade: Route.grade, HTTPClient: _HTTPClientContext, Route, User: _AuthenticationContext.User, I18N } });

		if (!Payload) {
			return;
		}

		getRouteDetails();
	};

	const OpenReportRouteSheet = async (): Promise<void> => {
		SheetManager.show<IReportRoute, IReportRoute>("Report-Route-Sheet", { payload: { Route, I18N }});
	};

	const OpenPublicVoteSheet = async (): Promise<void> => {
		SheetManager.show("Public-Vote-Sheet", { payload: { I18N, RouteID: Route.id } });
	};

	const OpenCommentSheet = async (Comment: IComment): Promise<void> => {
		const Payload: IManageComment = await SheetManager.show<IManageComment, IManageComment>("Manage-Comment-Sheet", { payload: { I18N, Comment, RouteID: Route.id, UserID: _AuthenticationContext.User?.id as string } })

		if (!Payload) {
			return;
		}
		if(Payload.Deleted && Comments.Payload) {
			SetComments({ ...Comments, Payload: Comments.Payload.filter((e) => e.id !== Payload.Deleted) });
			return;
		}
		const ModifiedCommentList: IComment[] = Comments.Payload.map((Comment: IComment) => Comment.id === Payload.Comment.id ? { ...Payload.Comment } : Comment);
		SetComments({ ...Comments, Payload: ModifiedCommentList });
	
	};

	const OnGymBookmarkPress = (Gym: IGymEntity): void => {
		if (!Gym.isFavourite) {
			(async () => {
				await _HTTPClientContext.HTTPClient.post("favourite-locations", { location: Gym.id });
			})();

			SetGyms({ ...Gyms, Payload: Gyms.Payload.map<IGymEntity>((_Gym: IGymEntity) => {
				if (_Gym.id === Gym.id) {
					_Gym.isFavourite = true;
				}
				return _Gym;
			}) });

		} else {
			(async () => {
				await _HTTPClientContext.HTTPClient.delete(`favourite-locations/${ Gym.id }`);
			})();

			SetGyms({ ...Gyms, Payload: Gyms.Payload.filter((_Gym: IGymEntity) => {
				if (_Gym.id === Gym.id) {
					_Gym.isFavourite = !_Gym.isFavourite;
				}
				return _Gym;
			}) });
		}
	};

	const OnRouteBookmarkPress = (Route: IRouteEntity): void => {
		if (!Route.isFavourite) {
			(async () => {
				await _HTTPClientContext.HTTPClient.post("favourite-routes", { route: Route.id });
			})();

			SetSimilarRoutes({ ...SimilarRoutes, Payload: SimilarRoutes.Payload.map((entry: IRouteEntity) => {
				if (entry.id === Route.id) {
					entry.isFavourite = true;
				}
				return entry;
			}) });

		} else {
			(async () => {
				await _HTTPClientContext.HTTPClient.delete(`favourite-routes/${ Route.id }`);
			})();

			SetSimilarRoutes({ ...SimilarRoutes, Payload: SimilarRoutes.Payload.filter((_Route: IRouteEntity) => {
				if (_Route.id === Route.id) {
					_Route.isFavourite = !_Route.isFavourite;
				}

				return _Route;
			}) });
		}
	};

	const VoteForRenewal = async (): Promise<void> => {
		await _HTTPClientContext.HTTPClient.post("renewal-votes", { routeId: Route.id });
	}

	return (
		<SafeAreaView collapsable>
			<KeyboardAvoidingView collapsable>
				<ScrollView collapsable contentInsetAdjustmentBehavior="automatic" keyboardShouldPersistTaps="never" showsVerticalScrollIndicator>
					<View collapsable style={ Styles.Flex_Container }>
						<Heading
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
							Right={ <BookmarkIcon Favorite={ Route?.isFavourite ? true : false } OnPress={ () => {
								SetRoute((Route: any) => Route ? { ...Route, isFavourite: !Route.isFavourite } : null);

								(async () => {
									if(!Route?.isFavourite) {
										await _HTTPClientContext.HTTPClient.post("favourite-routes", { route: Route ? Route.id : "" });

									} else {
										await _HTTPClientContext.HTTPClient.delete("favourite-routes/" + Route.id);
									}
								})();
							 } } /> }
							Title={ Route ? Route.name : "" }
						/>

						<Tabulator
							OnPress={ (SelectedTab: string) => SetMapTab(SelectedTab) }
							SelectedTab={ MapTab }
							Tabs={ ["Video"] }
							BG_Tabs={ ["Видео"] }
							ContainerStyles={{ marginBottom: 10, width: SideGutter(0, 0, 768) }}
							Locale={ Locale }
						/>

						{ MapTab === "Video" ? Route?.location?.map?.path && (
							<Image
								collapsable
								contentFit="contain"
								placeholder="L2HC1R~q00IU?bM{ofj[000000%M"
								placeholderContentFit="contain"
								priority="high"
								source={ Route.gallery && Route.gallery.length ? `${ eEWallsAPI.VERSIONED_URL + Route.gallery[0].path.path }` : require("../../../../assets/Icon.png") }
								style={{ height: SideGutter(20, 20, 768), width: SideGutter(20, 20, 768), marginBottom: 25 }}
							/>
						) : <></> }

						<View collapsable style={ Styles.FabButtonsContainer }>
							<FabButton
								Icon={ <MaterialCommunityIcons color={ Project ? "#FFF" : "#000" } name="crosshairs-gps" size={ 35 } style={ [Styles.Icon, Project ? { backgroundColor: "#000" } : {}] } /> }
								Label={ I18N.t(["RouteDetailsScreen", "Project"]) }
								OnPress={ async () => {
									SetProject(!Project);

									if(!Project) {
										await _HTTPClientContext.HTTPClient.post("projects", { route: Route ? Route.id : "" });

									} else {
										await _HTTPClientContext.HTTPClient.delete("projects/" + Route.id);
									}
								} }
							/>

							<FabButton
								Icon={ <MaterialCommunityIcons color="#000" name="trending-up" size={ 35 } style={ Styles.Icon } /> }
								Label={ I18N.t(["RouteDetailsScreen", "LogAscent"]) }
								OnPress={ () => OpenLogAscentSheet() }
							/>

							<FabButton
								Icon={ <MaterialCommunityIcons color="#000" name="vote" size={ 35 } style={ Styles.Icon } /> }
								Label={ I18N.t(["RouteDetailsScreen", "PublicVote"]) }
								OnPress={ () => OpenPublicVoteSheet() }
							/>
						</View>

						<Heading
							ContainerStyles={ { justifyContent: "space-between", marginBottom: 25, width: SideGutter(20, 20, 768) } }
							Left={
								<Button
									Label={ I18N.t(["RouteDetailsScreen", "Tops"]) }
									OnPress={ () => Navigation.dispatch(StackActions.push("Tops", { Tops })) }
									Theme="#ED2020"
									ContainerStyles={ { marginRight: 25, width: 100 } }
									LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingHorizontal: 10, paddingVertical: 4 } }
								/>
							}
							Right={
								<View collapsable style={ Styles.TotalFlex_Container }>
									<FontAwesome5 color="#000" name="user" size={ 25 } />
									<Text ellipsizeMode="tail" numberOfLines={ 1 } style={ Styles.TotalLabel }>{ I18N.t(["RouteDetailsScreen", "Total"]) }: { Tops.length }</Text>
								</View>
							}
						/>

						<Heading
							ContainerStyles={ { justifyContent: "space-between", marginBottom: 25, width: SideGutter(20, 20, 768) } }
							Left={ <Text ellipsizeMode="tail" numberOfLines={ 1 } style={ Styles.RatingLabel }>{ I18N.t(["RouteDetailsScreen", "Rating"]) }</Text> }
							Right={
								<StarRating
									OnPress={ (Rating: number) => SetRating(Rating) }
									Rating={ Rating }
									Disabled
									ContainerStyles={ { marginLeft: 25 } }
								/>
							}
						/>

						<View collapsable style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 20, width: SideGutter(20, 20, 768) }}>
							<Text style={{ color: "#000", fontSize: 16, fontFamily: "Montserrat-Medium" }}>{ I18N.t(["RouteDetailsScreen", "RouteGrade"]) }</Text>
							<Text style={{ color: Route && Route.color === '#000' || '#000000' ? "#FFF" :"#000", backgroundColor: (Route && isNaN(Route.color)) ? Route.color : "#000", padding: 10, minWidth: 35, textAlign: "center", borderRadius: 8, fontFamily: "Montserrat-Medium" }}>{ RouteGrade.Label }</Text>
						</View>

						<SideLabel Label={ I18N.t(["RouteDetailsScreen", "Comments"]) } ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } } />
						<CommentsList
							Comments={ Comments.Payload }
							EmptyListLabel={ I18N.t(["RouteDetailsScreen", "NoCommentsWerePostedYet"]) }
							Fetching={ CommentsListControls.Fetching }
							Loading={ CommentsListControls.Loading }
							More={ CommentsListControls.More }
							OnPress={ (Comment: IComment) => OpenCommentSheet(Comment) }
							OnLongPress={ (Comment: IComment) => OpenCommentSheet(Comment) }
							Pagination={
								<Button
									Label="More Comments"
									OnPress={ () => SetCommentsListControls({ ...CommentsListControls, Page: CommentsListControls.Page + 1 }) }
									Theme="#ED2020"
									ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
									LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
								/>
							}
							ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
						/>

						<SideLabel Label={ I18N.t(["RouteDetailsScreen", "RouteSetter"]) } ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } } />
						<Heading
							ContainerStyles={ { justifyContent: "space-between", marginBottom: 25, width: SideGutter(20, 20, 768) } }
							Left={ <Text ellipsizeMode="tail" numberOfLines={ 1 } style={ Styles.RouteSetterLabel }>{ Route?.routeSetter ? `${ Route.routeSetter.firstName } ${ Route.routeSetter.lastName }` : "" }</Text> }
							Right={
								<Button
									Label={ I18N.t(["RouteDetailsScreen", "Profile"]) }
									OnPress={ () => Navigation.dispatch(StackActions.push("Route Setter Profile", { id: Route?.routeSetter?.id })) }
									Theme="#ED2020"
									ContainerStyles={ { marginLeft: 25, width: 100 } }
									LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingHorizontal: 10, paddingVertical: 4 } }
								/>
							}
						/>

						<Tabulator
							OnPress={ (SelectedTab: string) => SetTab(SelectedTab) }
							SelectedTab={ Tab }
							Tabs={ ["Similar Routes", "In Other Gyms"] }
							BG_Tabs={ ["Подобни Маршрути", "В Други Фитнеси"] }
							ContainerStyles={ { marginBottom: 10, width: SideGutter(0, 0, 768) } }
							Locale={ Locale }
						/>

						{ Tab === "Similar Routes" ? <RoutesList
							EmptyListLabel={ I18N.t(["RouteDetailsScreen", "NoSimilarRoutesFound"]) }
							Fetching={ SimilarRoutes.Fetching }
							Loading={ SimilarRoutes.Loading }
							More={ SimilarRoutes.More }
							OnRouteBookmarkPress={ (RelatedRoute: IRouteEntity) => OnRouteBookmarkPress(RelatedRoute) }
							OnRouteCardPress={ (RelatedRoute: IRouteEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Route Details", params: { id: RelatedRoute.id } })) }
							Pagination={
								<Button
									Label={ I18N.t(["RouteDetailsScreen", "ShowMoreSimilarRoutes"]) }
									OnPress={ () => SetSimilarRoutes({ ...SimilarRoutes, Page: SimilarRoutes.Page + 1 }) }
									Theme="#ED2020"
									ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
									LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
								/>
							}
							Routes={ SimilarRoutes.Payload }
							ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
							Header={
								<ScrollView collapsable contentContainerStyle={ Styles.ScrollViewContent_Container } horizontal showsHorizontalScrollIndicator={ false } style={ Styles.ScrollView_Container }>
									<Text style={ Styles.SortCriterion_Label }>{ I18N.t(["RouteDetailsScreen", "SortBy"]) }</Text>

									{ (Locale === "bg-BG" ? SORT_CRITERIA_BG_BG : SORT_CRITERIA_EN_US).map<JSX.Element>((Criterion: IOption) => <Button
										key={ Criterion.ID }
										Label={ Criterion.Label }
										OnPress={ () => SortCriterion.Value === Criterion.Value ? SetSortCriterion({ ID: "", Label: "", Value: "" }) : SetSortCriterion(Criterion) }
										Theme={ SortCriterion.ID === Criterion.ID ? "#000" : "#FFF" }
										ContainerStyles={ { marginHorizontal: 10 } }
										LabelStyles={ { color: SortCriterion.ID === Criterion.ID ? "#FFF" : "#000", fontFamily: "Montserrat-Medium", fontSize: 14, marginHorizontal: 5, paddingHorizontal: 10, paddingVertical: 4 } }
									/>) }
								</ScrollView>
							}
						/> : <></> }

						{ Tab === "In Other Gyms" ? <GymsList
							EmptyListLabel={ I18N.t(["RouteDetailsScreen", "NoGymsFound"]) }
							Fetching={ Gyms.Fetching }
							Gyms={ Gyms.Payload }
							Loading={ Gyms.Loading }
							More={ Gyms.More }
							OnGymBookmarkPress={ (Gym: IGymEntity) => OnGymBookmarkPress(Gym) }
							OnGymCardPress={ (Gym: IGymEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Location Details", params: { id: Gym.id } })) }
							Pagination={
								<Button
									Label={ I18N.t(["RouteDetailsScreen", "ShowMoreGyms"]) }
									OnPress={ () => SetGyms({ ...Gyms, Page: Gyms.Page + 1 }) }
									Theme="#ED2020"
									ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
									LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
								/>
							}
							ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
						/> : <></> }

						{ Route?.disabled ? <Button
							Label={ I18N.t(["RouteDetailsScreen", "VoteForRenewal"]) }
							OnPress={ VoteForRenewal }
							Theme="#D6F85C"
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
							LabelStyles={ { color: "#000", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/> : <></> }

						{ !Route?.disabled ? <Button
							Label={ I18N.t(["RouteDetailsScreen", "ReportRoute"]) }
							OnPress={ () => OpenReportRouteSheet() }
							Theme="#000"
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/> : <></> }

						<AnimatedModal Title={ Modal.Title } Message={ Modal.Message } Visible={ Modal.Visible }>
							<Button
								Label={ I18N.t(["RouteDetailsScreen", "Dismiss"]) }
								OnPress={ () => SetModal({ Title: "", Message: "", Visible: false }) }
								Theme="#000"
								LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
							/>
						</AnimatedModal>

						{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
					</View>
				</ScrollView>
			</KeyboardAvoidingView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		flex: 1,
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	FabButtonsContainer: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-around",
		marginBottom: 25,
		width: SideGutter(20, 20, 768)
	},
	ScrollViewContent_Container: {
		alignItems: "center",
		paddingBottom: 10
	},
	ScrollView_Container: {
		width: SideGutter(0, 0, 768)
	},
	Icon: {
		backgroundColor: "#FFF",
		borderRadius: 100,
		elevation: 3,
		padding: 5,
		shadowColor: "#000",
		shadowOffset: {
			height: 1,
			width: 0
		},
		shadowOpacity: 0.2,
		shadowRadius: 1.4
	},
	SortCriterion_Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 14,
		marginLeft: 15
	},
	TotalFlex_Container: {
		flexDirection: "row",
		alignItems: "center"
	},
	TotalLabel: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginLeft: 10
	},
	RatingLabel: {
		color: "#000",
		flex: 1,
		fontFamily: "Montserrat-Medium",
		fontSize: 16
	},
	RouteSetterLabel: {
		color: "#000",
		flex: 1,
		fontFamily: "Montserrat-Bold",
		fontSize: 16
	},
	AbsoluteHeader: {
		position: "absolute",
		top: Platform.OS === 'android' ? 130 : 100,
		zIndex: 30,
	},
	FloatButton: {
		backgroundColor: 'lightgray',
		padding: 10,
		borderRadius: 10,
	}
});

export { RouteDetails };
