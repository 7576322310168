import { StyleSheet, Text, View, ViewStyle } from "react-native";

interface IBadgeProps {
	Icon: JSX.Element;
	Label: string;
	Value: string | number;
	ContainerStyles?: ViewStyle;
};

const Badge = (Props: IBadgeProps): JSX.Element => {

	const { Icon, Label, Value, ContainerStyles } = Props;

	return (
		<View collapsable style={ [Styles.Flex_Container, ContainerStyles] }>
			{ Icon }

			<View collapsable style={ Styles.FlexContent_Container }>
				<Text style={ Styles.Value }>{ Value }</Text>
				<Text style={ Styles.Label }>{ Label }</Text>
			</View>
		</View>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	FlexContent_Container: {
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 10
	},
	Value: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16
	},
	Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 14
	}
});

export { Badge };
