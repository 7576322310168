import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";

interface IStarRatingProps {
	OnPress: (Rating: number) => void;
	Rating: number;
	ContainerStyles?: ViewStyle;
	Disabled?: boolean;
	LabelStyles?: TextStyle;
	Size?: number;
};

const StarRating = (Props: IStarRatingProps): JSX.Element => {

	const { OnPress, Rating, ContainerStyles, Disabled, LabelStyles, Size } = Props;

	return (
		<View collapsable style={ [Styles.Flex_Container, ContainerStyles] }>
			<TouchableOpacity disabled={ Disabled ? true : false } onPress={ () => OnPress(1) } style={ LabelStyles }>
				<MaterialIcons color={ Rating >= 1 ? "#ED2020" : "#000"} name={ Rating >= 1 ? "star" : "star-border" } size={ Size ? Size : 25 } />
			</TouchableOpacity>

			<TouchableOpacity disabled={ Disabled ? true : false } onPress={ () => OnPress(2) } style={ LabelStyles }>
				<MaterialIcons color={ Rating >= 2 ? "#ED2020" : "#000"} name={ Rating >= 2 ? "star" : "star-border" } size={ Size ? Size : 25 } />
			</TouchableOpacity>

			<TouchableOpacity disabled={ Disabled ? true : false } onPress={ () => OnPress(3) } style={ LabelStyles }>
				<MaterialIcons color={ Rating >= 3 ? "#ED2020" : "#000"} name={ Rating >= 3 ? "star" : "star-border" } size={ Size ? Size : 25 } />
			</TouchableOpacity>

			<TouchableOpacity disabled={ Disabled ? true : false } onPress={ () => OnPress(4) } style={ LabelStyles }>
				<MaterialIcons color={ Rating >= 4 ? "#ED2020" : "#000"} name={ Rating >= 4 ? "star" : "star-border" } size={ Size ? Size : 25 } />
			</TouchableOpacity>

			<TouchableOpacity disabled={ Disabled ? true : false } onPress={ () => OnPress(5) } style={ LabelStyles }>
				<MaterialIcons color={ Rating >= 5 ? "#ED2020" : "#000"} name={ Rating >= 5 ? "star" : "star-border" } size={ Size ? Size : 25 } />
			</TouchableOpacity>
		</View>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	}
});

export { StarRating };
