import { NavigationContainer } from "@react-navigation/native";
import { SheetProvider } from "react-native-actions-sheet";

import { RootNavigator } from "./Controllers";

import { AuthenticationProvider } from "./Providers/Authentication-Provider";
import { HTTPClientProvider } from "./Providers/HTTP-Client-Provider";
import { I18NProvider } from "./Providers/I18N-Provider";

import "./Shared/Action-Sheets-Registry";

const App = (): JSX.Element => {

	const Linking = {
		prefixes: [
			"http://127.0.0.1:19006",
			"https://app.e-walls.eu"
		],
		config: {
			screens: {
				"Get Started": "",
				"Login": "login",
				"Forgot Password": "forgot-password",
				"Sign Up": "sign-up",
				"Personal Settings": "personal-settings",
				"Home Tab": "home",
				"Locations Tab": {
					path: "locations",
					screens: {
						"Explore": "explore",
						"Search Gyms": "search-gyms",
						"Search Routes": "search-routes",
						"Tops": "tops",
						"Image Gallery": "image-gallery",
						"Location Details": "location-details/:id",
						"Route Details": "route-details/:id",
						"Route Setter Profile": "route-setter-profile/:id"
					}
				},
				"Profile Tab": {
					path: "profile",
					screens: {
						"Your Profile": "your-profile",
						"Edit Profile": "edit-profile",
						"My Projects": "my-projects",
						"Climbed Routes": "climbed-routes"
					}
				},
				"Public Profile": "public-profile/:id"
			}
		}
	};

	return (
		<NavigationContainer documentTitle={ { enabled: true } } linking={ Linking }>
			<SheetProvider>
				<AuthenticationProvider>
					<HTTPClientProvider>
						<I18NProvider>
							<RootNavigator />
						</I18NProvider>
					</HTTPClientProvider>
				</AuthenticationProvider>
			</SheetProvider>
		</NavigationContainer>
	);
};

export { App };
