import { useFocusEffect, useRoute } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";

import { Badge, IOption, Statistics, Tabulator } from "../Components";
import { Heading } from "../Components/Heading";
import { ProfileIcon, ShareIcon } from "../Components/Heading/Icons";
import { IGymsPayload, IRoutesPayload, RoutesList } from "../Components/Lists";
import { SideLabel } from "../Components/Typography";

import { GRADE_SYSTEM_ROUTE_EN_US, ROUTE_GRADES } from "../Constants";

import { IGymEntity, IGymsListEntity, IRouteEntity, IRouteListEntity, IUserEntity } from "../Interfaces/Entities";

import { HTTPClientContext, IHTTPClientContext } from "../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../Providers/Keyboard-Provider";

import { SideGutter, TopGutter } from "../Utilities";
import { Entypo, MaterialCommunityIcons } from "@expo/vector-icons";

const PublicProfile = () => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N }: II18NContext = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const Route = useRoute();

	const [User, SetUser] = useState<IUserEntity | null>(null);
	const [Picture, SetPicture] = useState<string>("");
	const [TopGrade, SetTopGrade] = useState<string>("");

	const [FavoriteGyms, SetFavoriteGyms] = useState<IGymsPayload>({ Fetching: false, Limit: 100, Loading: false, More: false, Page: 1, Payload: [] });
	const [FavoriteRoutes, SetFavoriteRoutes] = useState<IRoutesPayload>({ Fetching: false, Limit: 100, Loading: false, More: false, Page: 1, Payload: [] });

	useFocusEffect(
		useCallback(() => {
			(async () => {
				let AxiosResponse_Result: AxiosResponse<IUserEntity, void> = await _HTTPClientContext.HTTPClient.get<IUserEntity, AxiosResponse<IUserEntity, void>, IUserEntity>(`users/public-user/${ Route.params?.id }`);
				SetUser(AxiosResponse_Result.data);

				const RouteGradeSystem:IOption = GRADE_SYSTEM_ROUTE_EN_US.filter((Entry: IOption) => Entry.Value === AxiosResponse_Result.data.routeGradeSystem)[0];
				const RouteGrade: IOption = (RouteGradeSystem.ID === "0" ? ROUTE_GRADES[0] : RouteGradeSystem.ID === "1" ? ROUTE_GRADES[1] : ROUTE_GRADES[2]).filter((Entry: IOption) => Entry.Value === AxiosResponse_Result.data.routeGrade)[0];

				SetTopGrade(RouteGrade.Label)

				if(AxiosResponse_Result.data.photo) {
					SetPicture("https://e-walls.eu/api/" + AxiosResponse_Result.data.photo.path);
				}
			})();

			(async () => {
				const AxiosResponse_Result: AxiosResponse<IGymsListEntity> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`favourite-locations?limit=${ FavoriteGyms.Limit }&page=${ FavoriteGyms.Page }`);
				SetFavoriteGyms({ ...FavoriteGyms, Payload: [...AxiosResponse_Result.data.data.map((Entry: IGymEntity) => ({ ...Entry, isFavourite: true }))], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
			})();

			(async () => {
				const AxiosResponse_Result = await _HTTPClientContext.HTTPClient.get<IRouteListEntity, AxiosResponse<IRouteListEntity, void>, void>(`favourite-routes?limit=${ FavoriteRoutes.Limit }&page=${ FavoriteRoutes.Page }`);
				SetFavoriteRoutes({ ...FavoriteRoutes, Payload: [...FavoriteRoutes.Payload, ...AxiosResponse_Result.data.data.map<IRouteEntity>((Entry: IRouteEntity) => ({ ...Entry, isFavourite: true }))], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
			})();
		}, [])
	);

	useEffect(() => {
		(async () => {
			SetFavoriteGyms({ ...FavoriteGyms, Fetching: FavoriteGyms.Page === 1 ? false : true, Loading: FavoriteGyms.Page === 1 ? true : false });
			const AxiosResponse_Result: AxiosResponse<IGymsListEntity> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`favourite-locations?limit=${ FavoriteGyms.Limit }&page=${ FavoriteGyms.Page }`);
			SetFavoriteGyms({ ...FavoriteGyms, Payload: [...FavoriteGyms.Payload, ...AxiosResponse_Result.data.data.map<IGymEntity>((Entry: IGymEntity) => ({ ...Entry, isFavourite: true }))], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [FavoriteGyms.Page]);

	useEffect(() => {
		(async () => {
			SetFavoriteRoutes({ ...FavoriteRoutes, Fetching: FavoriteRoutes.Page === 1 ? false : true, Loading: FavoriteRoutes.Page === 1 ? true : false });
			const AxiosResponse_Result = await _HTTPClientContext.HTTPClient.get<IRouteListEntity, AxiosResponse<IRouteListEntity, void>, void>(`favourite-routes?limit=${ FavoriteRoutes.Limit }&page=${ FavoriteRoutes.Page }`);
			SetFavoriteRoutes({ ...FavoriteRoutes, Payload: [...FavoriteRoutes.Payload, ...AxiosResponse_Result.data.data.map<IRouteEntity>((Entry: IRouteEntity) => ({ ...Entry, isFavourite: true }))], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [FavoriteRoutes.Page]);

	const ShareProfile = async (): Promise<void> => {};

	return (
		<SafeAreaView collapsable>
			<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" showsVerticalScrollIndicator>
				<Heading
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Right={ <ShareIcon OnPress={ () => ShareProfile() } /> }
					Title={ I18N.t(["YourProfileScreen", "PublicProfile"]) }
				/>

				<ProfileIcon
					ImageStyles={ { borderRadius: 5, flex: 1, width: "100%" } }
					OnPress={ () => {} }
					URL={ Picture }
					ContainerStyles={ { alignItems: "center", height: 100, marginBottom: 25, width: 100 } }
					Disabled
					Size={ 100 }
				/>

				<Text style={ Styles.User }>{ User ? `${ User.firstName } ${ User.lastName }` : "" }</Text>

				<Statistics
					Statistics={ [
						{ ID: "0", Label: I18N.t(["YourProfileScreen", "TotalSends"]), Value: User && User.totalSends ? User.totalSends : 0 },
						{ ID: "1", Label: I18N.t(["YourProfileScreen", "TopGrade"]), Value: User && User.topGrade ? TopGrade : 0 },
						{ ID: "2", Label: I18N.t(["YourProfileScreen", "Reach"]), Value: User && User.height ? User.height : 1 }
					] }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/>

				<SideLabel Label={ I18N.t(["YourProfileScreen", "YourAchievements"]) } ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } } />
				<Heading
					ContainerStyles={ { justifyContent: "space-between", marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Left={
						<Badge
							Icon={ <MaterialCommunityIcons color="#FFF" name="compare-vertical" size={ 25 } style={ Styles.Icon } /> }
							Label={ I18N.t(["YourProfileScreen", "TotalClimbs"]) }
							Value={ User && User.totalClimbs ? User.totalClimbs : 0 }
						/>
					}
					Right={
						<Badge
							Icon={ <Entypo color="#FFF" name="bar-graph" size={ 25 } style={ Styles.Icon } /> }
							Label={ I18N.t(["YourProfileScreen", "TopGrade"]) }
							Value={ User ? TopGrade : 0 }
						/>
					}
				/>

				<Tabulator
					OnPress={ (SelectedTab: string) => {} }
					SelectedTab="Posted Routes"
					Tabs={ ["Posted Routes"] }
					BG_Tabs={ ["Маршрути"] }
					ContainerStyles={ { marginBottom: 10, width: SideGutter(0, 0, 768) } }
					Locale="en-US"
				/>

				<RoutesList
					EmptyListLabel={ I18N.t(["RouteSetterProfileScreen", "NoPostedRoutesFound"]) }
					Fetching={ false }
					Loading={ false }
					More={ false }
					OnRouteBookmarkPress={ (RelatedRoute: IRouteEntity) => {} }
					OnRouteCardPress={ (RelatedRoute: IRouteEntity) => {} }
					Pagination={ <></> }
					Routes={ User?.routes ? User?.routes : [] }
					ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
					BookmarkHidden
				/>

				{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
			</ScrollView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	User: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 20,
		marginBottom: 25
	},
	Icon: {
		backgroundColor: "#ED2020",
		borderRadius: 8,
		padding: 10
	},
	RouteSetterLabel: {
		color: "#ED2020",
		fontFamily: "Montserrat-Bold",
		fontSize: 16
	},
	RouteSetterToggleLabel: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 25,
		width: SideGutter(20, 20, 768)
	}
});

export { PublicProfile };
