import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { TouchableOpacity } from "react-native";

interface IAccordionIconProps {
	Expanded: boolean;
	OnPress: () => void;
};

const AccordionIcon = (Props: IAccordionIconProps): JSX.Element => {

	const { Expanded, OnPress } = Props;

	return (
		<TouchableOpacity onPress={ () => OnPress() }>
			<MaterialCommunityIcons color="#000" name={ Expanded ? "chevron-up" : "chevron-down" } size={ 25 } />
		</TouchableOpacity>
	);
};

export { AccordionIcon };
