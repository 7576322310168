import { StyleSheet, Text, View, ViewStyle } from "react-native";

interface IWelcomeSloganProps {
	Name: string;
	Slogan: string;
	Timing: string;
	ContainerStyles?: ViewStyle;
};

const WelcomeSlogan = (Props: IWelcomeSloganProps): JSX.Element => {

	const { Name, Slogan, Timing, ContainerStyles } = Props;

	return (
		<View collapsable style={ ContainerStyles }>
			<Text style={ Styles.Welcome }>{ `${ Timing }, ${ Name } !` }</Text>
			<Text style={ Styles.Slogan }>{ Slogan }</Text>
		</View>
	);
};

const Styles = StyleSheet.create({
	Welcome: {
		color: "#000",
		fontFamily: "Montserrat-Bold",
		fontSize: 16,
		marginBottom: 10
	},
	Slogan: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 14
	}
});

export { WelcomeSlogan };
