import Ionicons from "@expo/vector-icons/Ionicons";
import { useContext, useState } from "react";
import { ScrollView, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from "react-native";

import { I18NContext, II18NContext } from "../Providers/I18N-Provider";

interface IOption {
	ID: string;
	Label: string;
	Value: string | number | number[] | string[];
};

interface IDropdownProps {
	OnOptionSelect: (SelectedOption: IOption) => void;
	Options: IOption[];
	OptionsShown: number;
	Placeholder: string;
	SelectedOption: IOption;
	ContainerStyles?: ViewStyle;
};

const Dropdown = (Props: IDropdownProps): JSX.Element => {

	const { OnOptionSelect, Options, OptionsShown, Placeholder, SelectedOption, ContainerStyles } = Props;

	const { ID, Label } = SelectedOption;

	const { Locale } = useContext<II18NContext>(I18NContext);

	const [Expanded, SetExpanded] = useState<boolean>(false);

	return (
		<View collapsable style={ ContainerStyles }>
			<Text style={ Styles.Title }>{ Placeholder }</Text>

			<View collapsable style={ [Styles.Container, Expanded && OptionsShown > 0 ? { marginBottom: 10 } : {}] }>
				<TouchableOpacity disabled={ Options.length === 0 ? true : false } onPress={ () => SetExpanded(!Expanded) } style={ Styles.FlexTouchableOpacity }>
					<Text style={ Styles.Label }>{ ID ? `${ Label }` : `${ Locale === "bg-BG" ? "Изберете" : "Select" } ${ Placeholder }` }</Text>

					{ Options.length > 0 ? <Ionicons color="#000" name={ Expanded ? "caret-up" : "caret-down" } size={ 25 } /> : <></> }
				</TouchableOpacity>
			</View>

			{ Expanded && Options.length > 0 && OptionsShown > 0 ? (
				<View collapsable style={ [Styles.List_Container, Options.length > OptionsShown ? { height: OptionsShown * Styles.FlexTouchableOpacity.height + 20 } : {}] }>
					<ScrollView collapsable nestedScrollEnabled persistentScrollbar showsVerticalScrollIndicator>
						{ Options.map<JSX.Element>((Option: IOption) => (
							<TouchableOpacity key={ Option.ID } onPress={ () => {
								OnOptionSelect(Option);
								SetExpanded(!Expanded);
							} } style={ [Styles.FlexTouchableOpacity, Options.length > OptionsShown ? { marginRight: 10 } : {}, ID === Option.ID ? { backgroundColor: "#000" } : {}] }>
								<Text style={ [Styles.Label, ID === Option.ID ? { color: "#FFF" } : {}] }>{ Option.Label }</Text>
							</TouchableOpacity>
						)) }
					</ScrollView>
				</View>
			) : <></> }
		</View>
	);
};

const Styles = StyleSheet.create({
	Title: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginBottom: 10
	},
	Container: {
		borderRadius: 8
	},
	FlexTouchableOpacity: {
		backgroundColor: "#FFF",
		borderColor: "#E3E5E5",
		borderRadius: 8,
		borderWidth: 1,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		height: 50,
		padding: 10
	},
	Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 14
	},
	List_Container: {
		backgroundColor: "#F5F5F5",
		borderRadius: 8,
		elevation: 3,
		padding: 10,
		shadowColor: "#000",
		shadowOffset: {
			height: 1,
			width: 0
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22
	}
});

export { Dropdown, IOption };
