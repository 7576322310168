import Ionicons from "@expo/vector-icons/Ionicons";
import { Image } from "expo-image";
import { StyleSheet, Text, TouchableHighlight, TouchableOpacity, View, ViewStyle } from "react-native";

import { eEWallsAPI } from "../Constants";

import { IGymEntity } from "../Interfaces/Entities";

interface IGymCardProps {
	Gym: IGymEntity;
	OnGymBookmarkPress: (Gym: IGymEntity) => void;
	OnGymCardPress: (Gym: IGymEntity) => void;
	ContainerStyles?: ViewStyle;
};

const GymCard = (Props: IGymCardProps): JSX.Element => {

	const { Gym, OnGymBookmarkPress, OnGymCardPress, ContainerStyles } = Props;

	return (
		<TouchableHighlight onPress={ () => OnGymCardPress(Gym) } style={ [Styles.TouchableHighlight, ContainerStyles] }>
			<View collapsable style={ [Styles.Flex_Container, ContainerStyles ? { width: ContainerStyles.width } : {}] }>
				<View collapsable style={ Styles.FlexContent_Container }>
					<Image
						collapsable
						contentFit="contain"
						placeholder="L2HC1R~q00IU?bM{ofj[000000%M"
						placeholderContentFit="contain"
						priority="high"
						source={ Gym.gallery && Gym.gallery.length ? `${ eEWallsAPI.VERSIONED_URL + Gym.gallery[0].path.path }` : require("../../assets/Icon.png") }
						style={ Styles.Image }
					/>

					<View collapsable style={ Styles.Information_Container }>
						<View collapsable style={ Styles.First_Row_Container }>
							<Text ellipsizeMode="tail" numberOfLines={ 2 } style={ Styles.Name }>{ Gym.title ? Gym.title : "No Gym Name Available !" }</Text>
						</View>
						<View collapsable style={ Styles.Address_Container }>
							<Ionicons color="#000" name="location" size={ 15 } />
							<Text ellipsizeMode="tail" numberOfLines={ 2 } style={ Styles.Address }>{ Gym.address ? Gym.address : "No Gym Address Available !" }</Text>
						</View>
					</View>
				</View>

				<TouchableOpacity onPress={ () => OnGymBookmarkPress(Gym) } style={ Styles.Bookmark }>
					<Ionicons color="#ED2020" name={ Gym.isFavourite ? "bookmark" : "bookmark-outline" } size={ 25 } />
				</TouchableOpacity>
			</View>
		</TouchableHighlight>
	);
};

const Styles = StyleSheet.create({
	TouchableHighlight: {
		borderRadius: 8
	},
	Flex_Container: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		elevation: 3,
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between",
		shadowColor: "#000",
		shadowOffset: {
			height: 1,
			width: 0
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22
	},
	FlexContent_Container: {
		flex: 1,
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "center"
	},
	Image: {
		borderBottomLeftRadius: 8,
		borderTopLeftRadius: 8,
		height: 100,
		width: 100
	},
	Information_Container: {
		flex: 1,
		padding: 7,
	},
	First_Row_Container: {
		flex: 1,
	},
	Name: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginBottom: 10
	},
	Address_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	Address: {
		color: "#000",
		flex: 1,
		fontFamily: "Montserrat-Medium",
		fontSize: 14,
		marginLeft: 5
	},
	Bookmark: {
		padding: 5
	}
});

export { GymCard };
