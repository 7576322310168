import { StyleSheet, View, ViewStyle } from "react-native";

import { EmptyListPlaceholder } from "./Empty-List-Placeholder";

import { GymCard } from "../Gym-Card";
import { Loader } from "../Loader";

import { IGymEntity } from "../../Interfaces/Entities";

import { SideGutter } from "../../Utilities";

interface IGymsListControls {
	Fetching: boolean;
	Limit: number;
	Loading: boolean;
	More: boolean;
	Page: number;
};

interface IGymsListProps {
	EmptyListLabel: string;
	Fetching: boolean;
	Gyms: IGymEntity[];
	Loading: boolean;
	More: boolean;
	OnGymBookmarkPress: (Gym: IGymEntity) => void;
	OnGymCardPress: (Gym: IGymEntity) => void;
	Pagination: JSX.Element;
	ContainerStyles?: ViewStyle;
};

interface IGymsPayload {
	Fetching: boolean;
	Limit: number;
	Loading: boolean;
	More: boolean;
	Page: number;
	Payload: IGymEntity[];
};

const GymsList = (Props: IGymsListProps): JSX.Element => {

	const { EmptyListLabel, Fetching, Gyms, Loading, More, OnGymBookmarkPress, OnGymCardPress, Pagination, ContainerStyles } = Props;

	if (Loading) {
		return <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } } />;
	}

	if (Gyms.length === 0) {
		return <EmptyListPlaceholder Label={ EmptyListLabel } ContainerStyle={ { marginBottom: 25, width: SideGutter(20, 20, 768) } } />;
	}

	return (
		<View collapsable style={ ContainerStyles }>
			{ Gyms.map<JSX.Element>((Gym: IGymEntity) => <GymCard
				key={ Gym.id }
				Gym={ Gym }
				OnGymBookmarkPress={ (Gym: IGymEntity) => OnGymBookmarkPress(Gym) }
				OnGymCardPress={ (Gym: IGymEntity) => OnGymCardPress(Gym) }
				ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } }
			/>) }

			{ Fetching ? <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } } /> : More ? Pagination : <View collapsable style={ Styles.Placeholder } /> }
		</View>
	)
};

const Styles = StyleSheet.create({
	Placeholder: {
		marginBottom: 15
	}
});

export { GymsList };
export { IGymsListControls };
export { IGymsPayload };
