import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from "react-native";

interface ITabulatorProps {
	OnPress: (SelectedTab: string) => void;
	SelectedTab: string;
	Tabs: string[];
	ContainerStyles?: ViewStyle;
	BG_Tabs: string[];
	Locale: string;
};

const Tabulator = (Props: ITabulatorProps): JSX.Element => {

	const { OnPress, SelectedTab, Tabs, BG_Tabs, ContainerStyles, Locale } = Props;

	return (
		<View collapsable style={ [Styles.Flex_Container, ContainerStyles] }>
			{ Tabs.map<JSX.Element>((Tab: string, Index: number) => (
				<TouchableOpacity key={ Tab } onPress={ () => OnPress(Tab) } style={ [Styles.Tab, SelectedTab === Tab ? { borderBottomColor: "#ED2020" } : {}] }>
					<Text style={ [Styles.Tab, SelectedTab === Tab ? { color: "#ED2020" } : {}] }>{ Locale === "bg-BG" ? BG_Tabs[Index] : Tabs[Index] }</Text>
				</TouchableOpacity>
			)) }
		</View>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	Tab: {
		borderBottomColor: "rgba(0, 0, 0, 0)",
		borderBottomWidth: 1,
		color: "#000",
		flex: 1,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		textAlign: "center"
	}
});

export { Tabulator };
