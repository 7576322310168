import FontAwesome from "@expo/vector-icons/FontAwesome";
import { Image, ImageStyle } from "expo-image";
import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";

interface IProfileIconProps {
	ImageStyles: ImageStyle;
	OnPress: () => void;
	URL: string;
	ContainerStyles?: ViewStyle;
	Disabled?: boolean;
	Icon?: JSX.Element;
	IconStyles?: ViewStyle;
	Size?: number;
};

const ProfileIcon = (Props: IProfileIconProps): JSX.Element => {

	const { ImageStyles, OnPress, URL, ContainerStyles, Disabled, Icon, IconStyles, Size } = Props;

	return (
		<TouchableOpacity disabled={ Disabled ? true : false } onPress={ () => OnPress() } style={ [Styles.RelativeTouchableOpacity, ContainerStyles] }>
			{ URL ? (
				<>
					<Image
						collapsable
						contentFit="cover"
						placeholder="L2HC1R~q00IU?bM{ofj[000000%M"
						placeholderContentFit="cover"
						priority="high"
						source={ URL }
						style={ ImageStyles }
					/>

					{ Icon ? Icon : <></> }
				</>
			) : <FontAwesome color="#000" name="user-o" size={ Size ? Size : 25 } style={ IconStyles } /> }
		</TouchableOpacity>
	);
};

const Styles = StyleSheet.create({
	RelativeTouchableOpacity: {
		position: "relative"
	}
});

export { ProfileIcon };
