import { NavigationProp, StackActions, useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { ImageBackground, StyleSheet, Text, View } from "react-native";

import { Button } from "../../Components";

import { I18NContext, II18NContext } from "../../Providers/I18N-Provider";

import { SideGutter } from "../../Utilities";

const GetStarted = (): JSX.Element => {

	const { I18N } = useContext<II18NContext>(I18NContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();

	return (
		<ImageBackground resizeMode="cover" source={ require("../../../assets/Background.png") } style={ Styles.ImageBackground }>
			<View collapsable style={ Styles.Flex_Container }>
				<Text style={ Styles.Slogan }>{ I18N.t(["GetStartedScreen", "WelcomeToEWalls"]) }</Text>

				<Button
					Label={ I18N.t(["GetStartedScreen", "GetStarted"]) }
					OnPress={ () => Navigation.dispatch(StackActions.push("Login")) }
					Theme="#ED2020"
					ContainerStyles={ { width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/>
			</View>
		</ImageBackground>
	);
};

const Styles = StyleSheet.create({
	ImageBackground: {
		flex: 1
	},
	Flex_Container: {
		backgroundColor: "rgba(0, 0, 0, 0.25)",
		flex: 1,
		alignItems: "center",
		justifyContent: "center"
	},
	Slogan: {
		color: "#FFF",
		fontFamily: "Montserrat-Bold",
		fontSize: 20,
		marginBottom: 25
	}
});

export { GetStarted };
