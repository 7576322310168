import { Fragment } from "react";
import { StyleSheet, Text, View, ViewStyle } from "react-native";

interface IRecord {
	ID: string;
	Label: string;
	Value: string | number;
};

interface IStatisticsProps {
	Statistics: IRecord[];
	ContainerStyles: ViewStyle;
};

const Statistics = (Props: IStatisticsProps): JSX.Element => {

	const { Statistics, ContainerStyles } = Props;

	return (
		<View collapsable style={ [Styles.Flex_Container, ContainerStyles] }>
			{ Statistics.map<JSX.Element>((Record: IRecord, Index: number) => (
				<Fragment key={ Record.ID }>
					<View collapsable style={ Styles.Stretched }>
						<Text style={ Styles.Value }>{ Record.Value }</Text>
						<Text style={ Styles.Label }>{ Record.Label }</Text>
					</View>

					{ Statistics.length > Index + 1 ? <View collapsable style={ Styles.Line } /> : <></> }
				</Fragment>
			)) }
		</View>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	},
	Stretched: {
		flex: 1
	},
	Value: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		textAlign: "center"
	},
	Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 14,
		textAlign: "center"
	},
	Line: {
		borderRightColor: "#000",
		borderRightWidth: 1,
		height: "100%"
	}
});

export { IRecord, Statistics };
