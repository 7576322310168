import { StyleSheet, Text, TextInput, View, ViewStyle } from "react-native";

interface IInputProps {
	Label: string;
	OnChange: (Text: string) => void;
	Placeholder: string;
	Value: string;
	ContainerStyles?: ViewStyle;
	Disabled?: boolean;
	IsEmail?: boolean;
	Lines?: number;
	Multiline?: boolean;
};

const Input = (Props: IInputProps): JSX.Element => {

	const { Label, OnChange, Placeholder, Value, ContainerStyles, Disabled, IsEmail, Lines, Multiline } = Props;

	return (
		<View collapsable style={ ContainerStyles }>
			<Text style={ Styles.Label }>{ Label }</Text>

			<TextInput
				autoCapitalize="none"
				autoComplete="off"
				autoCorrect={ false }
				collapsable
				cursorColor="#000"
				editable={ Disabled ? false: true }
				keyboardType={ IsEmail ? "email-address" : "default" }
				multiline={ Multiline ? true : false }
				numberOfLines={ Lines ? 5 : 1 }
				onChangeText={ (Text: string) => OnChange(Text) }
				placeholder={ Placeholder }
				placeholderTextColor="#000"
				style={ Styles.Input }
				textAlignVertical={ Multiline ? "top" : "center" }
				textContentType={ IsEmail ? "emailAddress" : "none" }
				value={ Value }
			/>
		</View>
	);
};

const Styles = StyleSheet.create({
	Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginBottom: 10
	},
	Input: {
		backgroundColor: "#FFF",
		borderColor: "#E3E5E5",
		borderRadius: 8,
		borderWidth: 1,
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		padding: 10
	}
});

export { Input };
