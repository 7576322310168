import { CommonActions, NavigationProp, useFocusEffect, useNavigation } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";
import { SheetManager } from "react-native-actions-sheet";

import { Button, IOption } from "../../../Components";
import { Heading } from "../../../Components/Heading";
import { IRoutesPayload, RoutesList } from "../../../Components/Lists";
import { WelcomeSlogan } from "../../../Components/Typography";

import { SORT_CRITERIA_BG_BG, SORT_CRITERIA_EN_US } from "../../../Constants";

import { IRouteEntity, IUserEntity } from "../../../Interfaces/Entities";

import { HTTPClientContext, IHTTPClientContext } from "../../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../../Providers/Keyboard-Provider";

import { BuildFilterQuery, SideGutter, TimeGreeting, TopGutter } from "../../../Utilities";

const MyProjects = (): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N, Locale } = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();

	const [User, SetUser] = useState<IUserEntity | null>(null);
	const [Routes, SetRoutes] = useState<IRoutesPayload>({ Fetching: false, Limit: 5, Loading: false, More: false, Page: 1, Payload: [] });

	const [RouteFilters, SetRouteFilters] = useState<IRouteFilters>({ Active: false, BoulderGradeFrom: 1, BoulderGradeTo: 26, FilterBy: { ID: "", Label: "", Value: "" }, Locale, RatingFrom: 0, RatingTo: 5, RouteGradeFrom: 1, RouteGradeTo: 37, RouteType: null, I18N });
	const [SortCriterion, SetSortCriterion] = useState<IOption>({ ID: "", Label: "", Value: "" });

	const OpenRouteFiltersSheet = async (): Promise<void> => {
		const RouteFilters_Result: IRouteFilters = await SheetManager.show<IRouteFilters, IRouteFilters>("Route-Filters-Sheet", { payload: RouteFilters });

		if (RouteFilters_Result) {
			SetRouteFilters(RouteFilters_Result);
		}
	};

	useFocusEffect(
		useCallback(() => {
			(async () => {
				const AxiosResponse_Result_Projects: AxiosResponse = await _HTTPClientContext.HTTPClient.get<IRouteEntity, AxiosResponse<IRouteEntity, void>, void>(`projects?limit=${ Routes.Limit }&page=${ 1 }&ratingFrom=${ RouteFilters.RatingFrom >= 0 ? RouteFilters.RatingFrom : null }&ratingTo=${ RouteFilters.RatingTo >= 0 ? RouteFilters.RatingTo : null }&sortBy=${ SortCriterion.Value ? SortCriterion.Value : null }&${ BuildFilterQuery(RouteFilters, User) }`);
				SetRoutes({ ...Routes, Payload: AxiosResponse_Result_Projects.data.data, Loading: false, Page: 1, More: AxiosResponse_Result_Projects.data.hasNextPage });

				const AxiosResponse_Result_User: AxiosResponse<IUserEntity, void> = await _HTTPClientContext.HTTPClient.get<IUserEntity, AxiosResponse<IUserEntity, void>, IUserEntity>("users/current-user");
				SetUser(AxiosResponse_Result_User.data);
			})();
		}, [])
	);

	useEffect(() => {
		(async() => {
			const AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get<IRouteEntity, AxiosResponse<IRouteEntity, void>, void>(`projects?limit=${ Routes.Limit }&page=${ 1 }&ratingFrom=${ RouteFilters.RatingFrom >= 0 ? RouteFilters.RatingFrom : null }&ratingTo=${ RouteFilters.RatingTo >= 0 ? RouteFilters.RatingTo : null }&sortBy=${ SortCriterion.Value ? SortCriterion.Value : null }&${ BuildFilterQuery(RouteFilters, User) }`);
			SetRoutes({ ...Routes, Page: 1, Payload: AxiosResponse_Result.data.data, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [RouteFilters.BoulderGradeFrom, RouteFilters.BoulderGradeTo, RouteFilters.RatingFrom, RouteFilters.RatingTo, RouteFilters.RouteGradeFrom, RouteFilters.RouteGradeTo, RouteFilters.RouteType, SortCriterion.Value]);

	useEffect(() => {
		if (Routes.Page === 1) {
			return;
		}

		(async() => {
			const AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get<IRouteEntity, AxiosResponse<IRouteEntity, void>, void>(`projects?limit=${ Routes.Limit }&page=${ Routes.Page }&ratingFrom=${ RouteFilters.RatingFrom >= 0 ? RouteFilters.RatingFrom : null }&ratingTo=${ RouteFilters.RatingTo >= 0 ? RouteFilters.RatingTo : null }&sortBy=${ SortCriterion.Value ? SortCriterion.Value : null }&${ BuildFilterQuery(RouteFilters, User) }`);
			SetRoutes({ ...Routes, Payload: [...Routes.Payload, ...AxiosResponse_Result.data.data], More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [Routes.Page]);

	const OnRouteBookmarkPress = (Route: IRouteEntity): void => {
		if (!Route.isFavourite) {
			(async () => {
				await _HTTPClientContext.HTTPClient.post<string, AxiosResponse<string, void>>("favourite-routes", { route: Route.id });
			})();

			SetRoutes({ ...Routes, Payload: Routes.Payload.map<IRouteEntity>((Entry: IRouteEntity) => {
				if (Entry.id === Route.id) {
					Entry.isFavourite = true;
				}

				return Entry;
			}) });

		} else {
			(async () => {
				await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>(`favourite-routes/${ Route.id }`);
			})();

			SetRoutes({ ...Routes, Payload: Routes.Payload.filter((Entry: IRouteEntity) => {
				if (Entry.id === Route.id) {
					Entry.isFavourite = !Entry.isFavourite;

				}

				return Entry;
			}) });
		}
	};

	return (
		<SafeAreaView collapsable>
			<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" showsVerticalScrollIndicator>
				<Heading
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Title={ I18N.t(["MyProjectsScreen", "MyProjects"]) }
				/>

				<WelcomeSlogan
					Name={ User ? `${ User.firstName } ${ User.lastName }` : "" }
					Slogan={ I18N.t(["MyProjectsScreen", "CheckYourProjectsHere"]) }
					Timing={ TimeGreeting(Locale) }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/>

				<RoutesList
					EmptyListLabel={ I18N.t(["MyProjectsScreen", "NoProjectsFound"]) }
					Fetching={ Routes.Fetching }
					Loading={ Routes.Loading }
					More={ Routes.More }
					OnRouteBookmarkPress={ (Project: IRouteEntity) => OnRouteBookmarkPress(Project) }
					OnRouteCardPress={ (Project: IRouteEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Route Details", params: { id: Project.id } })) }
					Pagination={
						<Button
							Label={ I18N.t(["MyProjectsScreen", "ShowMoreProjects"]) }
							OnPress={ () => SetRoutes({ ...Routes, Page: Routes.Page + 1 }) }
							Theme="#ED2020"
							ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/>
					}
					Routes={ Routes.Payload }
					ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
					Header={
						<ScrollView collapsable contentContainerStyle={ Styles.ScrollViewContent_Container } horizontal showsHorizontalScrollIndicator={ false } style={ Styles.ScrollView_Container }>
							<Text style={ Styles.SortCriterion_Label }>{ I18N.t(["MyProjectsScreen", "SortBy"]) }</Text>

							{ (Locale === "bg-BG" ? SORT_CRITERIA_BG_BG : SORT_CRITERIA_EN_US).map<JSX.Element>((Criterion: IOption) => <Button
								key={ Criterion.ID }
								Label={ Criterion.Label }
								OnPress={ () => SortCriterion.Value === Criterion.Value ? SetSortCriterion({ ID: "", Label: "", Value: "" }) : SetSortCriterion(Criterion) }
								Theme={ SortCriterion.ID === Criterion.ID ? "#000" : "#FFF" }
								ContainerStyles={ { marginHorizontal: 10 } }
								LabelStyles={ { color: SortCriterion.ID === Criterion.ID ? "#FFF" : "#000", fontFamily: "Montserrat-Medium", fontSize: 14, marginHorizontal: 5, paddingHorizontal: 10, paddingVertical: 4 } }
							/>) }
						</ScrollView>
					}
				/>

				{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
			</ScrollView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	ScrollViewContent_Container: {
		alignItems: "center",
		paddingBottom: 10
	},
	ScrollView_Container: {
		width: SideGutter(0, 0, 768)
	},
	SortCriterion_Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 14,
		marginLeft: 15
	}
});

export { MyProjects };
