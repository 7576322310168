import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { useFonts } from "expo-font";
import { Image } from "expo-image";
import { useContext } from "react";
import { StyleSheet, View } from "react-native";

import { AuthenticationFlow } from "./Authentication-Flow";
import { UserFlow } from "./User-Flow";

import { Fonts } from "../Fonts";

import { AuthenticationContext, IAuthenticationContext } from "../Providers/Authentication-Provider";
import { CredentialsProvider } from "../Providers/Credentials-Provider";
import { HTTPClientContext, IHTTPClientContext } from "../Providers/HTTP-Client-Provider";
import { KeyboardProvider } from "../Providers/Keyboard-Provider";

const RootNavigator = (): JSX.Element => {

	const _AuthenticationContext: IAuthenticationContext = useContext<IAuthenticationContext>(AuthenticationContext);
	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);

	const [Montserrat, Error] = useFonts(Fonts);

	if (_AuthenticationContext.JWT && Montserrat) {
		_HTTPClientContext.HTTPClient.interceptors.request.clear();
		_HTTPClientContext.HTTPClient.interceptors.response.clear();

		_HTTPClientContext.HTTPClient.interceptors.request.use(
			(RequestConfig: InternalAxiosRequestConfig) => {
				RequestConfig.headers.Authorization = `Bearer ${ _AuthenticationContext.JWT }`;
				return RequestConfig;
			},
			() => {}
		);

		_HTTPClientContext.HTTPClient.interceptors.response.use(
			(AxiosResponse: AxiosResponse) => AxiosResponse,
			() => {}
		);

		return (
			<KeyboardProvider>
				<UserFlow />
			</KeyboardProvider>
		);

	} else if (!_AuthenticationContext.JWT && Montserrat) {
		return (
			<CredentialsProvider>
				<AuthenticationFlow />
			</CredentialsProvider>
		);

	} else {
		return (
			<View collapsable style={ Styles.Flex_Container }>
				<Image collapsable source={ require("../../assets/Icon.png") } style={ Styles.Icon } />
			</View>
		);
	}
};

const Styles = StyleSheet.create({
	Flex_Container: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center"
	},
	Icon: {
		borderRadius: 8,
		height: 125,
		width: 125
	}
});

export { RootNavigator };
