import { ImagePickerAsset, ImagePickerResult, MediaTypeOptions, launchImageLibraryAsync } from "expo-image-picker";
import { AxiosResponse } from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import { KeyboardAvoidingView, SafeAreaView, ScrollView, StyleSheet, View } from "react-native";

import { AnimatedModal, Button, Dropdown, IOption, Input, PasswordInput, Tabulator } from "../../../Components";
import { Heading } from "../../../Components/Heading";
import { ProfileIcon } from "../../../Components/Heading/Icons";

import { BOULDER_GRADES, GRADE_SYSTEM_BOULDER, GRADE_SYSTEM_ROUTE_BG_BG, GRADE_SYSTEM_ROUTE_EN_US, ROUTE_GRADES, UNIT_SYSTEM_BG_BG, UNIT_SYSTEM_EN_US } from "../../../Constants";

import { IUserEntity } from "../../../Interfaces/Entities";

import { HTTPClientContext, IHTTPClientContext } from "../../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../../Providers/Keyboard-Provider";

import { IsMobileDevice, SideGutter, TopGutter } from "../../../Utilities";

const EditProfile = (): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N, Locale }: II18NContext = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const [Tab, SetTab] = useState<string>("General Settings");
	const [User, SetUser] = useState<IUserEntity | null>(null);
	const [Loaders, SetLoaders] = useState({ UpdatePasswordLoader: false, UpdateProfileSettingsLoader: false });

	const [Picture, SetPicture] = useState<ImagePickerAsset>();
	const [Name, SetName] = useState<string>("");
	const [LastName, SetLastName] = useState<string>("");
	const [Email, SetEmail] = useState<string>("");
	const [Reach, SetReach] = useState<number>(1);
	const [GradeSystemBoulder, SetBoulderGradeSystem] = useState<IOption>({ ID: "", Label: "", Value: "" });
	const [GradeSystemRoute, SetRouteGradeSystem] = useState<IOption>({ ID: "", Label: "", Value: "" });
	const [UnitSystem, SetUnitSystem] = useState<IOption>({ ID: "", Label: "", Value: "" });
	const [BoulderingGrade, SetBoulderingGrade] = useState<IOption>({ ID: "", Label: "", Value: "" });
	const [RouteGrade, SetRouteGrade] = useState<IOption>({ ID: "", Label: "", Value: "" });

	const [OldPassword, SetOldPassword] = useState<string>("");
	const [NewPassword, SetNewPassword] = useState<string>("");
	const [ConfirmNewPassword, SetConfirmNewPassword] = useState<string>("");
	const [PasswordVisibility, SetNewPasswordVisibility] = useState<boolean>(false);

	const [Modal, SetModal] = useState({ Title: "", Message: "", Visible: false });

	const UploadPicture = async (): Promise<void> => {
		const ImagePickerResult_Result: ImagePickerResult = await launchImageLibraryAsync({
			allowsEditing: true,
			aspect: [4, 3],
			base64: false,
			exif: false,
			mediaTypes: MediaTypeOptions.Images,
			quality: 1
		});

		if (ImagePickerResult_Result.canceled) {
			return;
		}

		SetPicture(ImagePickerResult_Result.assets[0]);
	};

	const DeletePicture = async (): Promise<void> => {
		SetPicture({ uri: "", delete: true });
	};

	const UpdateProfileSettings = async (): Promise<void> => {
		SetLoaders({ ...Loaders, UpdateProfileSettingsLoader: true });

		let Response = {};

		if(Picture) {

			if(Picture.type) {
				const FilePath = Picture.uri;
				const Tokens = FilePath.split(".");
		
				const _FormData = new FormData();

				_FormData.append("file", {
					uri: FilePath,
					type: "image/" + Tokens[Tokens.length - 1],
					name: "profile." + Tokens[Tokens.length - 1],
				});

				Response = await _HTTPClientContext.HTTPClient.post("files/upload", _FormData);
				Response = await _HTTPClientContext.HTTPClient.patch("users/profile-picture", { picture: Response.data.id });
			}

			if(Picture.delete) {
				Response = await _HTTPClientContext.HTTPClient.patch("users/profile-picture", { picture: "none" });
			}
		}

		const Data = {
			firstName: Name,
			lastName: LastName,
			height: Reach,
			unitSystem: UnitSystem.Value,
			routeGradeSystem: GradeSystemRoute.Value,
			boulderGradeSystem: GradeSystemBoulder.Value,
			boulderGrade: BoulderingGrade.Value,
			routeGrade: RouteGrade.Value,
		};

		Response = await _HTTPClientContext.HTTPClient.patch<string, AxiosResponse<string, void>>("users/basic/" + (User ? User.id : ""), Data);

		SetLoaders({ ...Loaders, UpdateProfileSettingsLoader: false });
		SetModal({ Title: I18N.t(["EditProfileScreen", "Success"]), Message: I18N.t(["EditProfileScreen", "ProfileUpdated"]), Visible: true });
	};

	const ChangePassword = async (): Promise<void> => {
		if(!OldPassword || !NewPassword || !ConfirmNewPassword) {
			SetModal({ Title: I18N.t(["EditProfileScreen", "MissingFields"]), Message: I18N.t(["EditProfileScreen", "PleaseFillAllTheFields"]), Visible: true });
			return;
		}

		if(NewPassword !== ConfirmNewPassword) {
			SetModal({ Title: I18N.t(["EditProfileScreen", "PasswordMissmatch"]), Message: I18N.t(["EditProfileScreen", "YourNewPasswordIsNotTheSameOnBothFields"]), Visible: true });
			return;
		}

		try {
			SetLoaders({ ...Loaders, UpdatePasswordLoader: true });

			await _HTTPClientContext.HTTPClient.patch<string, AxiosResponse<string, void>>("auth/change-password", {
				OldPassword,
				NewPassword,
				ConfirmNewPassword
			});

			SetLoaders({ ...Loaders, UpdatePasswordLoader: false });
			SetModal({ Title: I18N.t(["EditProfileScreen", "Success"]), Message: I18N.t(["EditProfileScreen", "ProfileUpdated"]), Visible: true });

		} catch(Error) {
			SetLoaders({ ...Loaders, UpdatePasswordLoader: true });
			SetModal({ Title: I18N.t(["EditProfileScreen", "PasswordUpdateFailed"]), Message: I18N.t(["EditProfileScreen", "ThereWasAProblemUpdatingThePassword"]), Visible: true });
		}
	};

	useEffect(() => {
		(async () => {
			let AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get<string, AxiosResponse<string, void>, void>("users/current-user");
			const User: IUserEntity = AxiosResponse_Result.data;

			if(User.photo) {
				SetPicture({ uri: "https://e-walls.eu/api/" + AxiosResponse_Result.data.photo.path });
			}

			const UnitSystem: IOption = UNIT_SYSTEM_EN_US.filter((Entry: IOption) => Entry.Value === User.unitSystem)[0];

			const BoulderGradeSystem: IOption = GRADE_SYSTEM_BOULDER.filter((Entry: IOption) => Entry.Value === User.boulderGradeSystem)[0];
			const BoulderGrade: IOption = (BoulderGradeSystem.ID === "0" ? BOULDER_GRADES[0] : BOULDER_GRADES[1]).filter((Entry: IOption) => Entry.Value === User.boulderGrade)[0];

			const RouteGradeSystem: IOption = GRADE_SYSTEM_ROUTE_EN_US.filter((Entry: IOption) => Entry.Value === User.routeGradeSystem)[0];
			const RouteGrade: IOption = (RouteGradeSystem.ID === "0" ?  ROUTE_GRADES[0] : RouteGradeSystem.ID === "1" ? ROUTE_GRADES[1] : RouteGradeSystem.ID === "2" ? ROUTE_GRADES[2] : ROUTE_GRADES[3]).filter((Entry: IOption) => Entry.Value === User.routeGrade)[0];

			SetUser(User);
			SetName(User.firstName);
			SetLastName(User.lastName);
			SetEmail(User.email);
			SetReach(User.height);
			SetUnitSystem(UnitSystem);
			SetBoulderGradeSystem(BoulderGradeSystem);
			SetBoulderingGrade(BoulderGrade);
			SetRouteGradeSystem(RouteGradeSystem);
			SetRouteGrade(RouteGrade);
		})();
	}, []);

	return (
		<SafeAreaView collapsable>
			<KeyboardAvoidingView collapsable>
				<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" keyboardShouldPersistTaps="handled" showsVerticalScrollIndicator>
					<Heading
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						Title={ I18N.t(["EditProfileScreen", "EditProfile"]) }
					/>

					<Tabulator
						OnPress={ (SelectedTab: string) => SetTab(SelectedTab) }
						SelectedTab={ Tab }
						Tabs={  ["General Settings", "Change Password"] }
						BG_Tabs={ ["Основни Настройки", "Смяна На Парола"] }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(0, 0, 768) } }
						Locale={ Locale }
					/>

					{ Tab === "General Settings" ? <Fragment>
					<ProfileIcon
							ImageStyles={ { borderRadius: 5, flex: 1, width: "100%" } }
							OnPress={ () => {} }
							URL={ Picture ? Picture.uri : "" }
							ContainerStyles={ { alignItems: "center", marginBottom: 25, height: 100, width: 100 } }
							Disabled
							Size={ 100 }
						/>

						<Button
							Label={ I18N.t(["EditProfileScreen", "UploadPicture"]) }
							OnPress={ () => UploadPicture() }
							Theme="#ED2020"
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/>

						{ !IsMobileDevice() && Picture ? <Button
							Label={ I18N.t(["EditProfileScreen", "ClearPicture"]) }
							OnPress={ () => SetPicture(null) }
							Theme="#000"
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/> : <></> }

						<Input
							Label={ I18N.t(["EditProfileScreen", "Name"]) }
							OnChange={ (Text: string) => SetName(Text) }
							Placeholder={ I18N.t(["EditProfileScreen", "EnterName"]) }
							Value={ Name }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						/>

						<Input
							Label={ I18N.t(["EditProfileScreen", "Surname"]) }
							OnChange={ (Text: string) => SetLastName(Text) }
							Placeholder={ I18N.t(["EditProfileScreen", "EnterSurname"]) }
							Value={ LastName }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						/>

						<Input
							Label={ I18N.t(["EditProfileScreen", "Email"]) }
							OnChange={ (Text: string) => SetEmail(Text) }
							Placeholder={ I18N.t(["EditProfileScreen", "EnterEmail"]) }
							Value={ Email }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						/>

						<Input
							Label={ I18N.t(["EditProfileScreen", "Reach"]) }
							OnChange={ (Text: string) => SetReach(+Text) }
							Placeholder={ I18N.t(["EditProfileScreen", "EnterReach"]) }
							Value={ Reach.toString() }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						/>

						<Dropdown
							OnOptionSelect={ (SelectedOption: IOption) => SetBoulderGradeSystem(SelectedOption) }
							Options={ GRADE_SYSTEM_BOULDER }
							OptionsShown={ 5 }
							Placeholder={ I18N.t(["EditProfileScreen", "BoulderGradeSystem"]) }
							SelectedOption={ GradeSystemBoulder }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						/>

						<Dropdown
							OnOptionSelect={ (SelectedOption: IOption) => SetRouteGradeSystem(SelectedOption) }
							Options={ Locale === "bg-BG" ? GRADE_SYSTEM_ROUTE_BG_BG : GRADE_SYSTEM_ROUTE_EN_US }
							OptionsShown={ 5 }
							Placeholder={ I18N.t(["EditProfileScreen", "RouteGradeSystem"]) }
							SelectedOption={ GradeSystemRoute }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						/>

						<Dropdown
							OnOptionSelect={ (SelectedOption: IOption) => SetUnitSystem(SelectedOption) }
							Options={ Locale === "bg-BG" ? UNIT_SYSTEM_BG_BG : UNIT_SYSTEM_EN_US }
							OptionsShown={ 5 }
							Placeholder={ I18N.t(["EditProfileScreen", "UnitSystem"]) }
							SelectedOption={ UnitSystem }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						/>

						<Dropdown
							OnOptionSelect={ (SelectedOption: IOption) => SetBoulderingGrade(SelectedOption) }
							Options={ GradeSystemBoulder.ID === "0" ? BOULDER_GRADES[0] : BOULDER_GRADES[1] }
							OptionsShown={ 5 }
							Placeholder={ I18N.t(["EditProfileScreen", "BoulderGrade"]) }
							SelectedOption={ BoulderingGrade }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						/>

						<Dropdown
							OnOptionSelect={ (SelectedOption: IOption) => SetRouteGrade(SelectedOption) }
							Options={ GradeSystemRoute.ID === "0" ? ROUTE_GRADES[0] : GradeSystemRoute.ID === "1" ? ROUTE_GRADES[1] : GradeSystemRoute.ID === "2" ? ROUTE_GRADES[2] : ROUTE_GRADES[3] }
							OptionsShown={ 5 }
							Placeholder={ I18N.t(["EditProfileScreen", "RouteGrade"]) }
							SelectedOption={ RouteGrade }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						/>
					</Fragment> : <></> }

					{ Tab === "General Settings" ? <Button
						Label={ I18N.t(["EditProfileScreen", "UpdateProfileSettings"]) }
						Disabled={ Loaders.UpdateProfileSettingsLoader }
						OnPress={ () => UpdateProfileSettings() }
						Theme="#ED2020"
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
					/> : <></> }

					{ Tab === "Change Password" ? <Fragment>
						<PasswordInput
							Label={ I18N.t(["EditProfileScreen", "OldPassword"]) }
							OnChange={ (Password: string) => SetOldPassword(Password) }
							Placeholder={ I18N.t(["EditProfileScreen", "EnterOldPassword"]) }
							Value={ OldPassword }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
							PasswordVisibility={ PasswordVisibility }
						/>

						<PasswordInput
							Label={ I18N.t(["EditProfileScreen", "EnterNewPassword"]) }
							OnChange={ (Password: string) => SetNewPassword(Password) }
							Placeholder={ I18N.t(["EditProfileScreen", "EnterNewPassword"]) }
							Value={ NewPassword }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
							PasswordVisibility={ PasswordVisibility }
							TogglePasswordVisibility={ () => SetNewPasswordVisibility(!PasswordVisibility) }
							WithPasswordVisibilityIcon
						/>

						<PasswordInput
							Label={ I18N.t(["EditProfileScreen", "NewPassword"]) }
							OnChange={ (Password: string) => SetConfirmNewPassword(Password) }
							Placeholder={ I18N.t(["EditProfileScreen", "ConfirmNewPassword"]) }
							Value={ ConfirmNewPassword }
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
							PasswordVisibility={ PasswordVisibility }
							TogglePasswordVisibility={ () => SetNewPasswordVisibility(!PasswordVisibility) }
						/>
					</Fragment> : <></> }

					{ Tab === "Change Password" ? <Button
							Label={ I18N.t(["EditProfileScreen", "ChangePassword"]) }
							Disabled={ Loaders.UpdateProfileSettingsLoader }
							OnPress={ () => ChangePassword() }
							Theme="#ED2020"
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
					/> : <></> }

					<AnimatedModal Title={ Modal.Title } Message={ Modal.Message } Visible={ Modal.Visible }>
						<Button
							Label={ I18N.t(["EditProfileScreen", "Dismiss"]) }
							OnPress={ () => SetModal({ Title: "", Message: "", Visible: false }) }
							Theme="#000"
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/>
					</AnimatedModal>

					{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
				</ScrollView>
			</KeyboardAvoidingView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	}
});

export { EditProfile };
