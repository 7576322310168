import { NativeStackNavigationOptions, createNativeStackNavigator } from "@react-navigation/native-stack";

import { Explore, ImageGallery, LocationDetails, RouteDetails, RouteSetterProfile, SearchGyms, SearchRoutes, Tops } from "../../Screens/User-Flow/Locations";

const LocationsFlow = (): JSX.Element => {

	const NativeStack = createNativeStackNavigator();

	const NativeStackScreenOptions: NativeStackNavigationOptions = {
		animation: "slide_from_right",
		contentStyle: {
			backgroundColor: "#F5F5F5"
		},
		headerShown: false,
		statusBarStyle: "dark",
		statusBarHidden: true,
		statusBarTranslucent: true
	};

	return (
		<NativeStack.Navigator initialRouteName="Explore" screenOptions={ NativeStackScreenOptions }>
			<NativeStack.Screen name="Explore" component={ Explore } />
			<NativeStack.Screen name="Image Gallery" component={ ImageGallery } />
			<NativeStack.Screen name="Location Details" component={ LocationDetails } />
			<NativeStack.Screen name="Route Details" component={ RouteDetails } />
			<NativeStack.Screen name="Route Setter Profile" component={ RouteSetterProfile } />
			<NativeStack.Screen name="Search Gyms" component={ SearchGyms } />
			<NativeStack.Screen name="Search Routes" component={ SearchRoutes } />
			<NativeStack.Screen name="Tops" component={ Tops } />
		</NativeStack.Navigator>
	);
};

export { LocationsFlow };
