import Entypo from "@expo/vector-icons/Entypo";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { CommonActions, NavigationProp, StackActions, useFocusEffect, useNavigation } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";

import { AnimatedModal, Badge, Button, GymCard, IOption, Statistics } from "../../../Components";
import { Heading } from "../../../Components/Heading";
import { ProfileIcon, ShareIcon } from "../../../Components/Heading/Icons";
import { EmptyListPlaceholder, FavoriteGymsList, FavoriteRoutesList, IGymsPayload, IRoutesPayload } from "../../../Components/Lists";
import { SideLabel } from "../../../Components/Typography";

import { GRADE_SYSTEM_ROUTE_EN_US, ROUTE_GRADES } from "../../../Constants";

import { IGymEntity, IGymsListEntity, IRouteEntity, IRouteListEntity, IUserEntity } from "../../../Interfaces/Entities";

import { AuthenticationContext, IAuthenticationContext } from "../../../Providers/Authentication-Provider";
import { HTTPClientContext, IHTTPClientContext } from "../../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../../Providers/Keyboard-Provider";

import { SideGutter, TopGutter } from "../../../Utilities";

const YourProfile = (): JSX.Element => {

	const _AuthenticationContext: IAuthenticationContext = useContext<IAuthenticationContext>(AuthenticationContext);
	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N }: II18NContext = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();

	const [User, SetUser] = useState<IUserEntity | null>(null);
	const [Picture, SetPicture] = useState<string>("");
	const [TopGrade, SetTopGrade] = useState<string>("");

	const [CurrentGym, SetCurrentGym] = useState<IGymEntity | null>(null);

	const [FavoriteGyms, SetFavoriteGyms] = useState<IGymsPayload>({ Fetching: false, Limit: 100, Loading: false, More: false, Page: 1, Payload: [] });
	const [FavoriteRoutes, SetFavoriteRoutes] = useState<IRoutesPayload>({ Fetching: false, Limit: 100, Loading: false, More: false, Page: 1, Payload: [] });

	const [Modal, SetModal] = useState({ Title: "", Message: "", Visible: false });

	useFocusEffect(
		useCallback(() => {
			(async () => {
				let AxiosResponse_Result: AxiosResponse<IUserEntity, void> = await _HTTPClientContext.HTTPClient.get<IUserEntity, AxiosResponse<IUserEntity, void>, IUserEntity>("users/current-user");
				SetUser(AxiosResponse_Result.data);

				const RouteGradeSystem:IOption = GRADE_SYSTEM_ROUTE_EN_US.filter((Entry: IOption) => Entry.Value === AxiosResponse_Result.data.routeGradeSystem)[0];
				const RouteGrade: IOption = (RouteGradeSystem.ID === "0" ? ROUTE_GRADES[0] : RouteGradeSystem.ID === "1" ? ROUTE_GRADES[1] : RouteGradeSystem.ID === "2" ? ROUTE_GRADES[2] : ROUTE_GRADES[3]).filter((Entry: IOption) => Entry.Value === AxiosResponse_Result.data.routeGrade)[0];

				SetTopGrade(RouteGrade.Label)

				if(AxiosResponse_Result.data.photo) {
					SetPicture("https://e-walls.eu/api/" + AxiosResponse_Result.data.photo.path);
				}

				if(AxiosResponse_Result.data.currentGym) {
					AxiosResponse_Result = await _HTTPClientContext.HTTPClient.get("locations/" + AxiosResponse_Result.data.currentGym.id);
					SetCurrentGym(AxiosResponse_Result.data);

				} else {
					SetCurrentGym(null);
				}
			})();

			(async () => {
				const AxiosResponse_Result: AxiosResponse<IGymsListEntity> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`favourite-locations?limit=${ FavoriteGyms.Limit }&page=${ FavoriteGyms.Page }`);
				SetFavoriteGyms({ ...FavoriteGyms, Payload: [...AxiosResponse_Result.data.data.map((Entry: IGymEntity) => ({ ...Entry, isFavourite: true }))], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
			})();

			(async () => {
				const AxiosResponse_Result = await _HTTPClientContext.HTTPClient.get<IRouteListEntity, AxiosResponse<IRouteListEntity, void>, void>(`favourite-routes?limit=${ FavoriteRoutes.Limit }&page=${ FavoriteRoutes.Page }`);
				SetFavoriteRoutes({ ...FavoriteRoutes, Payload: [...FavoriteRoutes.Payload, ...AxiosResponse_Result.data.data.map<IRouteEntity>((Entry: IRouteEntity) => ({ ...Entry, isFavourite: true }))], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
			})();
		}, [])
	);

	useEffect(() => {
		(async () => {
			SetFavoriteGyms({ ...FavoriteGyms, Fetching: FavoriteGyms.Page === 1 ? false : true, Loading: FavoriteGyms.Page === 1 ? true : false });
			const AxiosResponse_Result: AxiosResponse<IGymsListEntity> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`favourite-locations?limit=${ FavoriteGyms.Limit }&page=${ FavoriteGyms.Page }`);
			SetFavoriteGyms({ ...FavoriteGyms, Payload: [...FavoriteGyms.Payload, ...AxiosResponse_Result.data.data.map<IGymEntity>((Entry: IGymEntity) => ({ ...Entry, isFavourite: true }))], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [FavoriteGyms.Page]);

	useEffect(() => {
		(async () => {
			SetFavoriteRoutes({ ...FavoriteRoutes, Fetching: FavoriteRoutes.Page === 1 ? false : true, Loading: FavoriteRoutes.Page === 1 ? true : false });
			const AxiosResponse_Result = await _HTTPClientContext.HTTPClient.get<IRouteListEntity, AxiosResponse<IRouteListEntity, void>, void>(`favourite-routes?limit=${ FavoriteRoutes.Limit }&page=${ FavoriteRoutes.Page }`);
			SetFavoriteRoutes({ ...FavoriteRoutes, Payload: [...FavoriteRoutes.Payload, ...AxiosResponse_Result.data.data.map<IRouteEntity>((Entry: IRouteEntity) => ({ ...Entry, isFavourite: true }))], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [FavoriteRoutes.Page]);

	const ShareProfile = async (): Promise<void> => {};

	const OnGymBookmarkPress = (Gym: IGymEntity): void => {
		if (!Gym.isFavourite) {
			(async () => {
				await _HTTPClientContext.HTTPClient.post<string, AxiosResponse<string, void>>("favourite-locations", { location: Gym.id });
			})();

			SetCurrentGym(() => CurrentGym ? { ...CurrentGym, isFavourite: true } : null);

		} else {
			(async () => {
				await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>(`favourite-locations/${ Gym.id }`);
			})();

			SetCurrentGym(() => CurrentGym ? { ...CurrentGym, isFavourite: false } : null);
		}
	};

	const Logout = (): void => {
		_AuthenticationContext.Logout();
	};

	return (
		<SafeAreaView collapsable>
			<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" showsVerticalScrollIndicator>
				<Heading
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Right={ <ShareIcon OnPress={ () => ShareProfile() } /> }
					Title={ I18N.t(["YourProfileScreen", "YourProfile"]) }
				/>

				<ProfileIcon
					ImageStyles={ { borderRadius: 5, flex: 1, width: "100%" } }
					OnPress={ () => {} }
					URL={ Picture }
					ContainerStyles={ { alignItems: "center", height: 100, marginBottom: 25, width: 100 } }
					Disabled
					Size={ 100 }
				/>

				<Text style={ Styles.User }>{ User ? `${ User.firstName } ${ User.lastName }` : "" }</Text>

				<Button
					Label={ I18N.t(["YourProfileScreen", "EditProfile"]) }
					OnPress={ () => Navigation.dispatch(StackActions.push("Edit Profile")) }
					Theme="#ED2020"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/>

				<Statistics
					Statistics={ [
						{ ID: "0", Label: I18N.t(["YourProfileScreen", "TotalSends"]), Value: User && User.totalSends ? User.totalSends : 0 },
						{ ID: "1", Label: I18N.t(["YourProfileScreen", "TopGrade"]), Value: User && User.topGrade ? TopGrade : 0 },
						{ ID: "2", Label: I18N.t(["YourProfileScreen", "Reach"]), Value: User && User.height ? User.height : 1 }
					] }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/>

				<Heading
					ContainerStyles={ { justifyContent: "space-between", marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Left={
						<Button
							Label={ I18N.t(["YourProfileScreen", "ClimbedRoutes"]) }
							OnPress={ () => Navigation.dispatch(StackActions.push("Climbed Routes", { User })) }
							Theme="#ED2020"
							ContainerStyles={ { width: 150 } }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 14, paddingVertical: 10 } }
						/>
					}
					Right={
						<Button
							Label={ I18N.t(["YourProfileScreen", "MyProjects"]) }
							OnPress={ () => Navigation.dispatch(StackActions.push("My Projects")) }
							Theme="#ED2020"
							ContainerStyles={ { width: 150 } }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 14, paddingVertical: 10 } }
						/>
					}
				/>

				<SideLabel Label={ I18N.t(["YourProfileScreen", "YourCurrentGym"]) } ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } } />

				{ CurrentGym ? <GymCard
					Gym={ CurrentGym }
					OnGymBookmarkPress={ (Gym: IGymEntity) => OnGymBookmarkPress(Gym) }
					OnGymCardPress={ (Gym: IGymEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Location Details", params: { id: Gym.id } })) }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/> : <EmptyListPlaceholder Label={ I18N.t(["YourProfileScreen", "NoCurrentGymDetected"]) } ContainerStyle={ { marginBottom: 25, width: SideGutter(20, 20, 768) } } /> }

				<FavoriteGymsList
					EmptyListLabel={ I18N.t(["YourProfileScreen", "NoFavoriteGymsFound"]) }
					FavoriteGyms={ FavoriteGyms.Payload }
					Fetching={ FavoriteGyms.Fetching }
					Label={ I18N.t(["YourProfileScreen", "YourFavoriteGyms"]) }
					Loading={ FavoriteGyms.Loading }
					More={ FavoriteGyms.More }
					OnFavoriteGymBookmarkPress={ async (FavoriteGym: IGymEntity) => {
						SetFavoriteGyms({ ...FavoriteGyms, Payload: FavoriteGyms.Payload.filter((Entry: IGymEntity) => Entry.id !== FavoriteGym.id) });
						await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>(`favourite-locations/${ FavoriteGym.id }`);
					} }
					OnFavoriteGymCardPress={ (FavoriteGym: IGymEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Location Details", params: { id: FavoriteGym.id } })) }
					Pagination={
						<Button
							Label={ I18N.t(["YourProfileScreen", "ShowMoreFavoriteGyms"]) }
							OnPress={ () => SetFavoriteGyms({ ...FavoriteGyms, Page: FavoriteGyms.Page + 1 }) }
							Theme="#ED2020"
							ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/>
					}
					ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
				/>

				<FavoriteRoutesList
					EmptyListLabel={ I18N.t(["YourProfileScreen", "NoFavoriteRoutesFound"]) }
					FavoriteRoutes={ FavoriteRoutes.Payload }
					Fetching={ FavoriteRoutes.Fetching }
					Label={ I18N.t(["YourProfileScreen", "YourFavoriteRoutes"]) }
					Loading={ FavoriteRoutes.Loading }
					More={ FavoriteRoutes.More }
					OnFavoriteRouteBookmarkPress={ async (FavoriteRoute: IRouteEntity) => {
						SetFavoriteRoutes({ ...FavoriteRoutes, Payload: FavoriteRoutes.Payload.filter((Entry: IRouteEntity) => Entry.id !== FavoriteRoute.id) });
						await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>(`favourite-routes/${ FavoriteRoute.id }`);
					} }
					OnFavoriteRouteCardPress={ (FavoriteRoute: IRouteEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Route Details", params: { id: FavoriteRoute.id } })) }
					Pagination={
						<Button
							Label={ I18N.t(["YourProfileScreen", "ShowMoreFavoriteRoutes"]) }
							OnPress={ () => SetFavoriteRoutes({ ...FavoriteRoutes, Page: FavoriteRoutes.Page + 1 }) }
							Theme="#ED2020"
							ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/>
					}
					ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
				/>

				<SideLabel Label={ I18N.t(["YourProfileScreen", "YourAchievements"]) } ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } } />
				<Heading
					ContainerStyles={ { justifyContent: "space-between", marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Left={
						<Badge
							Icon={ <MaterialCommunityIcons color="#FFF" name="compare-vertical" size={ 25 } style={ Styles.Icon } /> }
							Label={ I18N.t(["YourProfileScreen", "TotalClimbs"]) }
							Value={ User && User.totalClimbs ? User.totalClimbs : 0 }
						/>
					}
					Right={
						<Badge
							Icon={ <Entypo color="#FFF" name="bar-graph" size={ 25 } style={ Styles.Icon } /> }
							Label={ I18N.t(["YourProfileScreen", "TopGrade"]) }
							Value={ User ? TopGrade : 0 }
						/>
					}
				/>

				<Button
					Label={ I18N.t(["YourProfileScreen", "Logout"]) }
					OnPress={ () => Logout() }
					Theme="#000"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/>

				<Button
					Label={ I18N.t(["YourProfileScreen", "DeleteAccount"]) }
					OnPress={ () => SetModal({ Title: I18N.t(["YourProfileScreen", "AreYouSureYouWantToDeleteYourAccount"]), Message: I18N.t(["YourProfileScreen", "ThisProcessIsIrreversible"]), Visible: true }) }
					Theme="#000"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/>

				<AnimatedModal Title={ Modal.Title } Message={ Modal.Message } Visible={ Modal.Visible }>
					<Button
						Label={ I18N.t(["YourProfileScreen", "Confirm"]) }
						OnPress={ async () => {
							await _HTTPClientContext.HTTPClient.delete<void, AxiosResponse<void, void>, void>("auth/me");

							SetModal({ Title: "", Message: "", Visible: false });

							_AuthenticationContext.Logout();
						} }
						Theme="#ED2020"
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						ContainerStyles={{ marginBottom: 25 }}
					/>

					<Button
						Label={ I18N.t(["YourProfileScreen", "Decline"]) }
						OnPress={ () => SetModal({ Title: "", Message: "", Visible: false }) }
						Theme="#000"
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
					/>
				</AnimatedModal>

				{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
			</ScrollView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	User: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 20,
		marginBottom: 25
	},
	Icon: {
		backgroundColor: "#ED2020",
		borderRadius: 8,
		padding: 10
	},
	RouteSetterLabel: {
		color: "#ED2020",
		fontFamily: "Montserrat-Bold",
		fontSize: 16
	},
	RouteSetterToggleLabel: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 25,
		width: SideGutter(20, 20, 768)
	}
});

export { YourProfile };
