import Axios, { AxiosInstance } from "axios";
import { Context, ReactNode, createContext } from "react";

import { eEWallsAPI } from "../Constants/Enums";

interface IHTTPClientContext {
	HTTPClient: AxiosInstance;
};

interface IHTTPClientProviderProps {
	children: ReactNode;
};

const HTTPClient: AxiosInstance = Axios.create({
	baseURL: eEWallsAPI.VERSIONED_URL
});

const HTTPClientContext: Context<IHTTPClientContext> = createContext<IHTTPClientContext>({
	HTTPClient
});

const HTTPClientProvider = (Props: IHTTPClientProviderProps): JSX.Element => {

	const { children } = Props;

	return (
		<HTTPClientContext.Provider value={ { HTTPClient } }>
			{ children }
		</HTTPClientContext.Provider>
	);
};

export { HTTPClientContext };
export { HTTPClientProvider };
export { IHTTPClientContext };
