import { Fragment, MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text } from 'react-native';
import ActionSheet, { ActionSheetRef, SheetManager, SheetProps, useScrollHandlers } from 'react-native-actions-sheet';
import { I18n } from 'i18n-js';

import { Button, IComment, Input } from "../";

import { HTTPClientContext, IHTTPClientContext } from '../../Providers/HTTP-Client-Provider';
import { I18NContext, II18NContext } from '../../Providers/I18N-Provider';

import { SideGutter } from "../../Utilities";

interface IManageComment {
	Comment: IComment;
	RouteID: string;
	UserID: string;
	I18N: I18n;
	Deleted: string;
};

const ManageCommentSheet = (Props: SheetProps<IManageComment>): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N }: II18NContext = useContext<II18NContext>(I18NContext);

	const { sheetId, payload } = Props;

	const ManageCommentSheet_Ref: MutableRefObject<ActionSheetRef | null> = useRef<ActionSheetRef | null>(null);

	const ScrollHandlers = useScrollHandlers<ScrollView>("Report-Route-Sheet", ManageCommentSheet_Ref);

	const [Comment, SetComment] = useState<IComment>();
	const [Loading, SetLoading] = useState<boolean>(false);

	useEffect(() => {
		if (!payload) {
			return;
		}

		SetComment(payload.Comment);
	}, [payload]);

	return (
		<ActionSheet containerStyle={ Styles.ActionSheetContent_Container } defaultOverlayOpacity={ 0.5 } gestureEnabled id={ sheetId } indicatorStyle={ Styles.Indicator } initialSnapIndex={ 2 } ref={ ManageCommentSheet_Ref } snapPoints={ [33, 66, 100] }>
			<ScrollView collapsable contentContainerStyle={ Styles.ScrollViewContent_Container } contentInsetAdjustmentBehavior="automatic" { ...ScrollHandlers } showsVerticalScrollIndicator>
				<Text style={ Styles.Title }>{ payload?.Comment.userId.id === payload?.UserID ? payload?.I18N.t(["ManageCommentsSheet", "EditComment"]) : payload?.I18N.t(["ManageCommentsSheet", "ReportComment"]) }</Text>

				<Input
					Label={ payload?.Comment.userId.id === payload?.UserID ? payload?.I18N.t(["ManageCommentsSheet", "EditYourComment"]) : payload?.I18N.t(["ManageCommentsSheet", "ReportThisComment"]) }
					OnChange={ (Text: string) => SetComment({ ...Comment as IComment, text: Text }) }
					Placeholder=""
					Value={ Comment?.text as string }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Disabled={ payload?.UserID === Comment?.userId.id ? false: true }
					Lines={ 5 }
					Multiline
				/>

				{ payload?.UserID === Comment?.userId.id ? (
					<Fragment>
						{ Comment?.text ? <Button
							Label={ payload?.I18N.t(["ManageCommentsSheet", "SubmitComment"]) }
							OnPress={ async () => {
								SetLoading(true);
								await _HTTPClientContext.HTTPClient.patch(`comments/${ payload?.Comment.id }`, { routeId: payload?.RouteID, text: Comment.text });

								SheetManager.hide<IManageComment>("Manage-Comment-Sheet", { payload: { I18N, Comment, RouteID: payload?.RouteID as string, UserID: payload?.UserID as string } })
							} }
							Theme="#ED2020"
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
							Disabled={ Loading }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/> : <></> }

						{ Comment?.text ? <Button
							Label={ payload?.I18N.t(["ManageCommentsSheet", "ClearComment"]) }
							OnPress={ async () => {
								SetLoading(true);
								await _HTTPClientContext.HTTPClient.delete(`comments/${ payload?.Comment.id }`);

								SheetManager.hide<IManageComment>("Manage-Comment-Sheet", { payload: { Deleted: payload?.Comment.id } })
							} }
							Theme="#000"
							ContainerStyles={ { width: SideGutter(20, 20, 768) } }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/> : <></> }
					</Fragment>
				) : (
					<Button
						Label={ payload?.I18N.t(["ManageCommentsSheet", "ReportComment"]) }
						OnPress={ async () => {
							SetLoading(true);
							await _HTTPClientContext.HTTPClient.post(`comments/report/${ payload?.Comment?.id }`, {});

							SheetManager.hide<IManageComment>("Manage-Comment-Sheet");
						} }
						Theme="#ED2020"
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						Disabled={ Loading }
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
					/>
				) }
			</ScrollView>
		</ActionSheet>
	);
};

const Styles = StyleSheet.create({
	ActionSheetContent_Container: {
		borderTopLeftRadius: 25,
		borderTopRightRadius: 25,
		backgroundColor: "#F5F5F5",
		paddingTop: 10,
		flex: 0.75
	},
	Indicator: {
		backgroundColor: "#000",
		width: 50
	},
	ScrollViewContent_Container: {
		backgroundColor: "#F5F5F5",
		alignItems: "center",
		paddingTop: 10
	},
	Title: {
		color: "#000",
		fontFamily: "Montserrat-Bold",
		fontSize: 18,
		marginBottom: 25
	}
});

export { IManageComment, ManageCommentSheet };
