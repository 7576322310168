import { IOption } from "../Components";

const UNIT_SYSTEM_BG_BG: IOption[] = [
	{ ID: "0", Label: "Футове", Value: "Feet" },
	{ ID: "1", Label: "Метри", Value: "Meter" }
];

const UNIT_SYSTEM_EN_US: IOption[] = [
	{ ID: "0", Label: "Feet", Value: "Feet" },
	{ ID: "1", Label: "Meter", Value: "Meter" }
];

export { UNIT_SYSTEM_BG_BG };
export { UNIT_SYSTEM_EN_US };
