import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text } from 'react-native';
import ActionSheet, { ActionSheetRef, SheetManager, SheetProps, useScrollHandlers } from 'react-native-actions-sheet';

import { Button, StarRating } from "../";

import { SideGutter } from "../../Utilities";
import { HTTPClientContext, IHTTPClientContext } from '../../Providers/HTTP-Client-Provider';
import { SideLabel } from '../Typography';
import { AxiosResponse } from 'axios';

const PublicVoteSheet = (Props: SheetProps): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);

	const { sheetId, payload } = Props;

	const LogAscentSheet_Ref: MutableRefObject<ActionSheetRef | null> = useRef<ActionSheetRef | null>(null);

	const ScrollHandlers = useScrollHandlers<ScrollView>("Log-Ascent-Sheet", LogAscentSheet_Ref);

	const [Rating, SetRating] = useState<number>(0);
	const [PreviouslyVoted, SetPreviouslyVoted] = useState<boolean>(false);
	const [VoteID, SetVoteID] = useState<string>("");

	useEffect(() => {
		if (!payload) {
			return;
		}

		(async() => {
			const AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get(`public-vote/route/${ payload.RouteID }`);
			if (AxiosResponse_Result.data) {
				SetPreviouslyVoted(true);
				SetVoteID(AxiosResponse_Result.data.id);
				SetRating(AxiosResponse_Result.data.rating);

			} else {
				SetPreviouslyVoted(false);
				SetVoteID("");
				SetRating(5);
			}
		})();
	}, [payload]);

	const Vote = async (): Promise<void> => {
		if (PreviouslyVoted) {
			const Axios_Response = await _HTTPClientContext.HTTPClient.patch(`public-vote/${ VoteID }`, { rating: Rating });

		} else {
			const Axios_Response = await _HTTPClientContext.HTTPClient.post(`public-vote/route/${ payload.RouteID }`, { rating: Rating });
		}

		SheetManager.hide(sheetId);
	};

	return (
		<ActionSheet containerStyle={ Styles.ActionSheetContent_Container } defaultOverlayOpacity={ 0.5 } gestureEnabled id={ sheetId } indicatorStyle={ Styles.Indicator } initialSnapIndex={ 2 } ref={ LogAscentSheet_Ref } snapPoints={ [33, 66, 100] }>
			<ScrollView collapsable contentContainerStyle={ Styles.ScrollViewContent_Container } contentInsetAdjustmentBehavior="automatic" { ...ScrollHandlers } showsVerticalScrollIndicator>
				<Text style={ Styles.Title }>{ payload.I18N.t(["PublicVoteSheet", "PublicVote"]) }</Text>

				<SideLabel Label={ payload.I18N.t(["PublicVoteSheet", "TapToRate"]) } ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } } />
				<StarRating
					OnPress={ (Rating: number) => SetRating(Rating) }
					Rating={ Rating }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { marginHorizontal: 5 } }
					Size={ 30 }
				/>

				<Button
					Label={ PreviouslyVoted ? payload.I18N.t(["PublicVoteSheet", "ChangeYourVote"]) : payload.I18N.t(["PublicVoteSheet", "Vote"]) }
					OnPress={ () => Vote() }
					Theme="#ED2020"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/>
			</ScrollView>
		</ActionSheet>
	);
};

const Styles = StyleSheet.create({
	ActionSheetContent_Container: {
		borderTopLeftRadius: 25,
		borderTopRightRadius: 25,
		backgroundColor: "#F5F5F5",
		paddingTop: 10,
		flex: 0.75
	},
	Indicator: {
		backgroundColor: "#000",
		width: 50
	},
	ScrollViewContent_Container: {
		backgroundColor: "#F5F5F5",
		alignItems: "center",
		paddingTop: 10
	},
	Title: {
		color: "#000",
		fontFamily: "Montserrat-Bold",
		fontSize: 18,
		marginBottom: 25
	}
});

export { PublicVoteSheet };
