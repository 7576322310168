import Geolocation, { GeolocationResponse } from "@react-native-community/geolocation";
import { CommonActions, NavigationProp, useFocusEffect, useNavigation } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { KeyboardAvoidingView, RefreshControl, SafeAreaView, ScrollView, StyleSheet, View } from "react-native";

import { Button, SearchInput, Tabulator } from "../../../Components";
import { Heading } from "../../../Components/Heading";
import { ProfileIcon, ScanIcon } from "../../../Components/Heading/Icons";
import { GymsList, IGymsPayload } from "../../../Components/Lists";

import { IGymEntity, IGymsListEntity, IUserEntity } from "../../../Interfaces/Entities";

import { HTTPClientContext, IHTTPClientContext } from "../../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../../Providers/Keyboard-Provider";

import { SideGutter, TopGutter } from "../../../Utilities";

const Explore = (): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N, Locale }: II18NContext = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();

	const [User, SetUser] = useState<IUserEntity | null>(null);

	const [Refreshing, SetRefreshing] = useState<boolean>(false);
	const [CurrentPosition, SetCurrentPosition] = useState<GeolocationResponse | null>(null);

	const [Tab, SetTab] = useState<string>("Gyms");

	const [Gyms, SetGyms] = useState<IGymsPayload>({ Fetching: false, Limit: 5, Loading: false, More: false, Page: 1, Payload: [] });
	const [SelectedGym, SetSelectedGym] = useState<IGymEntity | null>(null);

	useFocusEffect(
		useCallback(() => {
			(async () => {
				const AxiosResponse_Result: AxiosResponse<IUserEntity, void> = await _HTTPClientContext.HTTPClient.get<IUserEntity, AxiosResponse<IUserEntity, void>, IUserEntity>("users/current-user");
				SetUser(AxiosResponse_Result.data);
			})();
		}, [])
	);

	useEffect(() => {
		(async () => {
			GetCurrentLocation();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			SetGyms({ ...Gyms, Fetching: Gyms.Page === 1 ? false : true, Loading: Gyms.Page === 1 ? true : false });
			const AxiosResponse_Result: AxiosResponse<IGymsListEntity> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`locations?latitude=${ CurrentPosition ? CurrentPosition.coords.latitude : null }&longitude=${ CurrentPosition ? CurrentPosition.coords.longitude : null }&limit=${ Gyms.Limit }&page=${ 1 }`);
			SetGyms({ ...Gyms, Page: 1, Payload: [...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [CurrentPosition?.coords.latitude, CurrentPosition?.coords.longitude]);

	useEffect(() => {
		if (Gyms.Page === 1) {
			return;
		}

		(async () => {
			SetGyms({ ...Gyms, Fetching: Gyms.Page === 1 ? false : true, Loading: Gyms.Page === 1 ? true : false });
			const AxiosResponse_Result: AxiosResponse<IGymsListEntity> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`locations?latitude=${ CurrentPosition ? CurrentPosition.coords.latitude : null }&longitude=${ CurrentPosition ? CurrentPosition.coords.longitude : null }&limit=${ Gyms.Limit }&page=${ Gyms.Page }`);
			SetGyms({ ...Gyms, Payload: [...Gyms.Payload, ...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [Gyms.Page]);

	const OnGymBookmarkPress = (Gym: IGymEntity): void => {
		if (!Gym.isFavourite) {
			(async () => {
				await _HTTPClientContext.HTTPClient.post("favourite-locations", { location: Gym.id });
			})();

			SetGyms({ ...Gyms, Payload: Gyms.Payload.map<IGymEntity>((Entry: IGymEntity) => {
				if (Entry.id === Gym.id) {
					Entry.isFavourite = true;
				}

				return Entry;
			}) });

		} else {
			(async () => {
				await _HTTPClientContext.HTTPClient.delete(`favourite-locations/${ Gym.id }`);
			})();

			SetGyms({ ...Gyms, Payload: Gyms.Payload.filter((Entry: IGymEntity) => {
				if (Entry.id === Gym.id) {
					Entry.isFavourite = !Entry.isFavourite;
				}

				return Entry;
			}) });
		}
	};

	const GetCurrentLocation = () => {
		Geolocation.getCurrentPosition(
			(position: GeolocationResponse) => {
				SetCurrentPosition(position);
				SetRefreshing(false);

				(async () => {
					SetGyms({ ...Gyms, Fetching: Gyms.Page === 1 ? false : true, Loading: Gyms.Page === 1 ? true : false });
					const AxiosResponse_Result: AxiosResponse<IGymsListEntity> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`locations?latitude=${ position.coords.latitude }&longitude=${ position.coords.longitude }&limit=${ Gyms.Limit }&page=${ 1 }`);
					SetGyms({ ...Gyms, Page: 1, Payload: [...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
				})();
			},
			() => {
				SetCurrentPosition(null);
				SetRefreshing(false);

				(async () => {
					SetGyms({ ...Gyms, Fetching: Gyms.Page === 1 ? false : true, Loading: Gyms.Page === 1 ? true : false });
					const AxiosResponse_Result: AxiosResponse<IGymsListEntity> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`locations?latitude=null&longitude=null&limit=${ Gyms.Limit }&page=${ 1 }`);
					SetGyms({ ...Gyms, Page: 1, Payload: [...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
				})();
			},
			{ enableHighAccuracy: true, maximumAge: 0 }
		);
	};

	return (
		<SafeAreaView collapsable>
			<KeyboardAvoidingView collapsable>
			<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" keyboardShouldPersistTaps="never" refreshControl={ Tab === "Gyms" ? <RefreshControl refreshing={ Refreshing } onRefresh={ () => { SetRefreshing(true); GetCurrentLocation(); } } /> : <></> } showsVerticalScrollIndicator>
					<Heading
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						Left={ <ScanIcon OnPress={ () => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Scan" })) } /> }
						Right={
							<ProfileIcon
								ImageStyles={ { borderRadius: 5, flex: 1, width: "100%" } }
								OnPress={ () => Navigation.dispatch(CommonActions.navigate("Profile Tab", { screen: "Your Profile" })) }
								URL={ `https://app.e-walls.eu/api/${ User ? User.photo.path : "" }` }
								ContainerStyles={ { height: 25, margin: 5, width: 25 } }
							/>
						}
						Title={ I18N.t(["ExploreScreen", "Explore"]) }
					/>

					{ Tab === "Gyms" ? <SearchInput
						Placeholder={ `${ Locale === "bg-BG" ? "Търси Фитнеси" : "Search Gyms" }...` }
						OnFocus={ () => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Search Gyms" })) }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					/> : <></> }

					<Tabulator
						OnPress={ (SelectedTab: string) => SetTab(SelectedTab) }
						SelectedTab={ Tab }
						Tabs={ ["Gyms"] }
						BG_Tabs={ ["Фитнеси"] }
						ContainerStyles={ { marginBottom: 10, width: SideGutter(0, 0, 768) } }
						Locale={ Locale }
					/>

					{ Tab === "Gyms" ? <GymsList
						EmptyListLabel={ I18N.t(["ExploreScreen", "NoGymsFound" ]) }
						Fetching={ Gyms.Fetching }
						Gyms={ Gyms.Payload }
						Loading={ Gyms.Loading }
						More={ Gyms.More }
						OnGymBookmarkPress={ (Gym: IGymEntity) => OnGymBookmarkPress(Gym) }
						OnGymCardPress={ (Gym: IGymEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Location Details", params: { id: Gym.id } })) }
						Pagination={
							<Button
								Label={ I18N.t(["ExploreScreen", "ExploreMoreGyms"]) }
								OnPress={ () => SetGyms({ ...Gyms, Page: Gyms.Page + 1 }) }
								Theme="#ED2020"
								ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
								LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
							/>
						}
						ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
					/> : <></> }

					{ SelectedGym && Tab === "Map" ? <Button
						Label={ I18N.t(["ExploreScreen", "ClearSelectedGym"]) }
						OnPress={ () => {
							SetGyms({ ...Gyms, Payload: Gyms.Payload });
							SetSelectedGym(null);
						} }
						Theme="#000"
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
					/> : <></> }

					{ Tab === "Map" ? <GymsList
						EmptyListLabel={ I18N.t(["ExploreScreen", "NoGymsFoundNearby"]) }
						Fetching={ Gyms.Fetching }
						Gyms={ CurrentPosition ? SelectedGym ? [SelectedGym] : Gyms.Payload : [] }
						Loading={ Gyms.Loading }
						More={ Gyms.More }
						OnGymBookmarkPress={ (Gym: IGymEntity) => OnGymBookmarkPress(Gym) }
						OnGymCardPress={ (Gym: IGymEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Location Details", params: { id: Gym.id } })) }
						Pagination={ SelectedGym ? <></> : (
							<Button
								Label={ I18N.t(["ExploreScreen", "ExploreMoreNearbyGyms"]) }
								OnPress={ () => SetGyms({ ...Gyms, Page: Gyms.Page + 1 }) }
								Theme="#ED2020"
								ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
								LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
							/>
						) }
						ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
					/> : <></> }

					{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
				</ScrollView>
			</KeyboardAvoidingView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	MapView: {
		flex: 1,
		width: "100%"
	}
});

export { Explore };
