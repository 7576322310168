import Ionicons from "@expo/vector-icons/Ionicons";
import { Image } from "expo-image";
import { useContext } from "react";
import { StyleSheet, Text, TouchableHighlight, TouchableOpacity, View, ViewStyle } from "react-native";

import { IRouteEntity } from "../Interfaces/Entities";

import { I18NContext, II18NContext } from "../Providers/I18N-Provider";

import { eEWallsAPI } from "../Constants";

const image = require("../../assets/Icon.png");

interface IRouteCardProps {
	OnRouteBookmarkPress: (Route: IRouteEntity) => void;
	OnRouteCardPress: (Route: IRouteEntity) => void;
	Route: IRouteEntity;
	ContainerStyles?: ViewStyle;
	BookmarkHidden?: boolean;
};

const RouteCard = (Props: IRouteCardProps): JSX.Element => {

	const { OnRouteBookmarkPress, OnRouteCardPress, Route, ContainerStyles, BookmarkHidden } = Props;
	let pic = image;

	const { I18N } = useContext<II18NContext>(I18NContext);

	if(Route.gallery && Route.gallery.length) {
		pic = eEWallsAPI.VERSIONED_URL + Route.gallery[0].path.path.replace('.', '-small.');
	}

	return (
		<TouchableHighlight onPress={ () => OnRouteCardPress(Route) } style={ [Styles.TouchableHighlight, ContainerStyles] }>
			<View collapsable style={ [Styles.Flex_Container, ContainerStyles ? { width: ContainerStyles.width } : {}] }>
				<View collapsable style={ Styles.FlexContent_Container }>
					<View collapsable style={{ position: "relative" }}>
						<Image
							collapsable
							contentFit="contain"
							placeholder="L2HC1R~q00IU?bM{ofj[000000%M"
							placeholderContentFit="contain"
							priority="high"
							source={ pic }
							style={ Styles.Image }
						/>

						{ Route.disabled ? <View style={ Styles.DisabledOverlay }>
							<Text style={ Styles.DraftLabel }>{ I18N.t(["Card", "Draft"]) }</Text>
						</View> : <></> }
					</View>

					<View collapsable style={ Styles.Information_Container }>
						<View collapsable style={ Styles.First_Row_Container }>
							<Text ellipsizeMode="tail" numberOfLines={ 2 } style={ Styles.Name }>{ Route.name ? Route.name : "No route name available !" }</Text>
						</View>

						<View collapsable style={ Styles.Address_Container }>
							<Ionicons color="#000" name="location" size={ 15 } />
							<Text ellipsizeMode="tail" numberOfLines={ 2 } style={ Styles.Address }>{ Route.location && Route.location.address ? Route.location.address : "No route address available !" }</Text>
						</View>
					</View>
				</View>

				{ !BookmarkHidden ? <TouchableOpacity onPress={ () => OnRouteBookmarkPress(Route) } style={ Styles.Bookmark }>
					<Ionicons color="#ED2020" name={ Route.isFavourite ? "bookmark" : "bookmark-outline" } size={ 25 } />
				</TouchableOpacity> : <></> }
			</View>
		</TouchableHighlight>
	);
};

const Styles = StyleSheet.create({
	TouchableHighlight: {
		borderRadius: 8
	},
	Flex_Container: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		elevation: 3,
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between",
		shadowColor: "#000",
		shadowOffset: {
			height: 1,
			width: 0
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22
	},
	FlexContent_Container: {
		flex: 1,
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "center"
	},
	Image: {
		borderBottomLeftRadius: 8,
		borderTopLeftRadius: 8,
		height: 100,
		width: 100
	},
	DisabledOverlay: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		borderTopLeftRadius: 8,
		borderBottomLeftRadius: 8,
		opacity: 0.75,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#CCC"
	},
	DraftLabel: {
		fontFamily: "Montserrat-Bold",
		color: "#000",
		fontSize: 16
	},
	First_Row_Container: {
		flex: 1,
	},
	Information_Container: {
		flex: 1,
		padding: 5
	},
	Name: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginBottom: 10
	},
	Address_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	Address: {
		color: "#000",
		flex: 1,
		fontFamily: "Montserrat-Medium",
		fontSize: 14,
		marginLeft: 5
	},
	Bookmark: {
		padding: 5
	}
});

export { RouteCard };
