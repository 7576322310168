import { CommonActions, NavigationProp, useFocusEffect, useNavigation } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useCallback, useContext, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";

import { SearchInput } from "../../../Components";
import { Heading } from "../../../Components/Heading";
import { RoutesList, IRoutesPayload } from "../../../Components/Lists";
import { WelcomeSlogan } from "../../../Components/Typography";

import { IRouteEntity, IUserEntity } from "../../../Interfaces/Entities";

import { HTTPClientContext, IHTTPClientContext } from "../../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../../Providers/Keyboard-Provider";

import { SideGutter, TimeGreeting, TopGutter } from "../../../Utilities";

const SearchRoutes = (): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N, Locale }: II18NContext = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();

	const [User, SetUser] = useState<IUserEntity | null>(null);
	const [Keyword, SetKeyword] = useState<string>("");
	const [Searching, SetSearching] = useState<boolean>(false);
	const [WasSearched, SetWasSearched] = useState<boolean>(false);

	const [Routes, SetRoutes] = useState<IRoutesPayload>({ Fetching: false, Limit: 25, Loading: false, More: false, Page: 1, Payload: [] });

	useFocusEffect(
		useCallback(() => {
			(async () => {
				const AxiosResponse_Result: AxiosResponse<IUserEntity, void> = await _HTTPClientContext.HTTPClient.get<IUserEntity, AxiosResponse<IUserEntity, void>, IUserEntity>("users/current-user");
				SetUser(AxiosResponse_Result.data);
			})();
		}, [])
	);

	const OnRouteBookmarkPress = (Route: IRouteEntity): void => {
		if (!Route.isFavourite) {
			(async () => {
				await _HTTPClientContext.HTTPClient.post<string, AxiosResponse<string, void>>("favourite-routes", { location: Route.id });
			})();

			SetRoutes({ ...Routes, Payload: Routes.Payload.map<IRouteEntity>((entry: IRouteEntity) => {
				if (entry.id === Route.id) {
					entry.isFavourite = true;
				}

				return entry;
			}) });

		} else {
			(async () => {
				await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>(`favourite-routes/${ Route.id }`);
			})();

			SetRoutes({ ...Routes, Payload: Routes.Payload.filter((entry: IRouteEntity) => {
				if (entry.id === Route.id) {
					entry.isFavourite = !entry.isFavourite;
				}

				return entry;
			}) });
		}
	};

	const Search = async (): Promise<void> => {
		SetSearching(true);
		SetRoutes({ ...Routes, Payload: [] });
		SetWasSearched(false);

		const AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get<IRouteEntity[], AxiosResponse<IRouteEntity[], void>, void>(`routes/search?keyword=${ Keyword.toLowerCase() }&limit=${ Routes.Limit }&page=${ Routes.Page }`);
		SetSearching(false);
		SetRoutes({ ...Routes, Payload: [...AxiosResponse_Result.data] });
		SetWasSearched(true);
	};

	return (
		<SafeAreaView collapsable>
			<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" showsVerticalScrollIndicator>
				<Heading
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Title={ I18N.t(["SearchRoutesScreen", "SearchRoutes"]) }
				/>

				<WelcomeSlogan
					Name={ User ? `${ User.firstName } ${ User.lastName }` : "" }
					Slogan={ I18N.t(["SearchRoutesScreen", "SearchForAnyRoutesYouWant"]) }
					Timing={ TimeGreeting(Locale) }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/>

				<SearchInput
					AutoFocus
					OnChange={ (Text: string) => SetKeyword(Text) }
					OnPress={ () => Search() }
					OnSubmit={ () => Keyword.length ? Search() : {} }
					Placeholder={ I18N.t(["SearchRoutesScreen", "Search"]) }
					Searching={ Searching }
					Value={ Keyword }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/>

				{ WasSearched ? <RoutesList
					EmptyListLabel={ I18N.t(["SearchRoutesScreen", "NoRoutesFound"]) }
					Fetching={ Routes.Fetching }
					Routes={ Routes.Payload }
					Loading={ Routes.Loading }
					More={ Routes.More }
					OnRouteBookmarkPress={ (Route: IRouteEntity) => OnRouteBookmarkPress(Route) }
					OnRouteCardPress={ (Route: IRouteEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Route Details", params: { id: Route.id } })) }
					Pagination={ <></> }
					ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
				/> : <></> }

				{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
			</ScrollView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	Placeholder: {
		marginBottom: 15
	}
});

export { SearchRoutes };
