import { IRouteFilters } from "../Components/Sheets";

import { BOULDER_GRADES, BOULDER_GRADES_REVERSE, ROUTE_GRADES, ROUTE_GRADES_REVERSE } from "../Constants";

import { IUserEntity } from "../Interfaces/Entities";

const BuildFilterQuery = (RouteFilters: IRouteFilters, User: IUserEntity | null): string => {

	switch (RouteFilters.RouteType) {
		case null: {
			return `type=${ null }`;
		};

		case "boulder": {
			const From = JSON.parse(BOULDER_GRADES[BOULDER_GRADES_REVERSE[User?.boulderGradeSystem]][RouteFilters.BoulderGradeFrom - 1].Value)[0];
			const length: number = (JSON.parse(BOULDER_GRADES[BOULDER_GRADES_REVERSE[User?.boulderGradeSystem]][RouteFilters.BoulderGradeTo - 1].Value) as any[]).length;
			const To = (JSON.parse(BOULDER_GRADES[BOULDER_GRADES_REVERSE[User?.boulderGradeSystem]][RouteFilters.BoulderGradeTo - 1].Value) as any[])[length - 1];

			return `gradeFrom=${ From }&gradeTo=${To }&type=boulder`;
		};

		case "route": {
			const From = JSON.parse(ROUTE_GRADES[ROUTE_GRADES_REVERSE[User?.routeGradeSystem]][RouteFilters.RouteGradeFrom - 1].Value)[0];
			const length: number = (JSON.parse(ROUTE_GRADES[ROUTE_GRADES_REVERSE[User?.routeGradeSystem]][RouteFilters.RouteGradeTo - 1].Value) as any[]).length;
			const To = (JSON.parse(ROUTE_GRADES[ROUTE_GRADES_REVERSE[User?.routeGradeSystem]][RouteFilters.RouteGradeTo - 1].Value) as any[])[length - 1];

			return `gradeFrom=${ From }&gradeTo=${ To }&type=route`;
		};

		default:
			return "";
	};
};

export { BuildFilterQuery };
