import { ParamListBase, RouteProp, useFocusEffect, useRoute } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";

import { ITop } from "../../../Components";
import { Heading } from "../../../Components/Heading";
import { ITopsListControls, TopsList } from "../../../Components/Lists";
import { WelcomeSlogan } from "../../../Components/Typography";

import { IUserEntity } from "../../../Interfaces/Entities";

import { HTTPClientContext, IHTTPClientContext } from "../../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../../Providers/Keyboard-Provider";

import { SideGutter, TimeGreeting, TopGutter } from "../../../Utilities";

const Tops = (): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N, Locale }: II18NContext = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const Route: RouteProp<ParamListBase> = useRoute<RouteProp<ParamListBase>>();

	const [User, SetUser] = useState<IUserEntity | null>(null);
	const [Tops, SetTops] = useState<ITop[]>([]);
	const [TopsListControls, SetTopsListControls] = useState<ITopsListControls>({ Fetching: false, Loading: false, More: false });

	useFocusEffect(
		useCallback(() => {
			(async () => {
				const AxiosResponse_Result: AxiosResponse<IUserEntity, void> = await _HTTPClientContext.HTTPClient.get<IUserEntity, AxiosResponse<IUserEntity, void>, IUserEntity>("users/current-user");
				SetUser(AxiosResponse_Result.data);
			})();
		}, [])
	);

	useEffect(() => {
		if (!Route.params) {
			return;
		}

		SetTops((Route.params as any).Tops);
	}, [Route.params]);

	return (
		<SafeAreaView collapsable>
			<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" showsVerticalScrollIndicator>
				<Heading
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Title={ I18N.t(["TopsScreen", "Tops"]) }
				/>

				<WelcomeSlogan
					Name={ User ? `${ User.firstName } ${ User.lastName }` : "" }
					Slogan={ I18N.t(["TopsScreen", "CheckAllTopsForThisRoute"]) }
					Timing={ TimeGreeting(Locale) }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/>

				<TopsList
					EmptyListLabel={ I18N.t(["TopsScreen", "NoTopsFound"]) }
					Fetching={ TopsListControls.Fetching }
					Loading={ TopsListControls.Loading }
					More={ TopsListControls.More }
					Pagination={ <></> }
					Tops={ Tops }
					ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
				/>

				{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
			</ScrollView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	}
});

export { Tops };
