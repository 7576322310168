import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { StyleSheet, Text, View } from "react-native";

import { Button } from "./Button";

import { SideGutter } from "../Utilities";

interface ILocationServicePlaceholder {
	PlaceholderText: string;
	ButtonLabel: string;
	OnPress: () => void;
};

const LocationServicePlaceholder = (Props: ILocationServicePlaceholder): JSX.Element => {

	const { ButtonLabel, PlaceholderText, OnPress } = Props;

	return (
		<View collapsable style={ Styles.LocationServicePlaceholderContainer }>
			<MaterialIcons name="location-off" size={ 64 } style={{ marginBottom: 25 }} />

			<Text style={{ color: "#000", fontFamily: "Montserrat-Medium", fontSize: 18, marginBottom: 25 }}>{ PlaceholderText }</Text>

			<Button
				Label={ ButtonLabel }
				OnPress={ () => OnPress() }
				Theme="#000"
				ContainerStyles={ { width: SideGutter(20, 20, 768) } }
				LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
			/>
		</View>
	);
};

const Styles = StyleSheet.create({
	LocationServicePlaceholderContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center"
	}
});

export { LocationServicePlaceholder };
