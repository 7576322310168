import { useState } from "react";
import { StyleSheet, Text, View, ViewStyle } from "react-native";

import { EmptyListPlaceholder } from "./Empty-List-Placeholder";

import { Heading } from "../Heading";
import { AccordionIcon } from "../Heading/Icons";
import { Loader } from "../Loader";
import { RouteCard } from "../Route-Card";

import { IRouteEntity } from "../../Interfaces/Entities";

import { SideGutter } from "../../Utilities";

interface IFavoriteRoutesListControls {
	Fetching: boolean;
	Limit: number;
	Loading: boolean;
	More: boolean;
	Page: number;
};

interface IFavoriteRoutesListProps {
	EmptyListLabel: string;
	FavoriteRoutes: IRouteEntity[];
	Fetching: boolean;
	Label: string;
	Loading: boolean;
	More: boolean;
	OnFavoriteRouteBookmarkPress: (FavoriteRoute: IRouteEntity) => void;
	OnFavoriteRouteCardPress: (FavoriteRoute: IRouteEntity) => void;
	Pagination: JSX.Element;
	ContainerStyles?: ViewStyle;
};

const FavoriteRoutesList = (Props: IFavoriteRoutesListProps): JSX.Element => {

	const { EmptyListLabel, FavoriteRoutes, Fetching, Label, Loading, More, OnFavoriteRouteBookmarkPress, OnFavoriteRouteCardPress, Pagination, ContainerStyles } = Props;

	const [Expanded, SetExpanded] = useState<boolean>(true);

	if (Loading) {
		return <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } } />;
	}

	if (FavoriteRoutes.length === 0) {
		return <EmptyListPlaceholder Label={ EmptyListLabel } ContainerStyle={ { marginBottom: 25, width: SideGutter(20, 20, 768) } } />;
	}

	return (
		<View collapsable style={ ContainerStyles }>
			<Heading
				ContainerStyles={ { justifyContent: "space-between", marginBottom: Expanded ? 10 : 25, width: SideGutter(20, 20, 768) } }
				Left={ <Text style={ Styles.Label }>{ `${ Label }: ${ FavoriteRoutes.length }` }</Text> }
				Right={ <AccordionIcon Expanded={ Expanded } OnPress={ () => SetExpanded(!Expanded) } /> }
			/>

			{ Expanded ? FavoriteRoutes.map<JSX.Element>((FavoriteRoute: IRouteEntity, Index: number) => <RouteCard
				key={ FavoriteRoute.id }
				Route={ FavoriteRoute }
				OnRouteBookmarkPress={ (FavoriteRoute: IRouteEntity) => OnFavoriteRouteBookmarkPress(FavoriteRoute) }
				OnRouteCardPress={ (FavoriteRoute: IRouteEntity) => OnFavoriteRouteCardPress(FavoriteRoute) }
				ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } }
			/>) : <></> }

			{ Expanded ? Fetching ? <Loader Color="#000" Size={ 32 } ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } } /> : More ? Pagination : <View collapsable style={ Styles.Placeholder } /> : <></> }
		</View>
	)
};

const Styles = StyleSheet.create({
	Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16
	},
	Placeholder: {
		marginBottom: 15
	}
});

export { FavoriteRoutesList, IFavoriteRoutesListControls };
