import { Platform, StatusBar } from "react-native";

const TopGutter = (Android: number, IOS: number, NonMobile: number): number => {
	if (Platform.OS === "android") {
		return StatusBar.currentHeight ? StatusBar.currentHeight + Android : Android;

	} else if (Platform.OS === "ios") {
		return IOS;

	} else {
		return NonMobile;
	}
};

export { TopGutter };
