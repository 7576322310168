import { IOption } from "../Components";

const GRADE_SYSTEM_BOULDER: IOption[] = [
	{ ID: "0", Label: "V Grade Hueco", Value: "hueco" },
	{ ID: "1", Label: "Fontain Ebleau", Value: "ebleau" }
];

const GRADE_SYSTEM_ROUTE_BG_BG: IOption[] = [
	{ ID: "0", Label: "UIAA", Value: "UIAA" },
	{ ID: "1", Label: "Франция", Value: "French" },
	{ ID: "2", Label: "САЩ", Value: "US" },
	{ ID: "3", Label: "EWBANKS", Value: "EWBANKS" }
];

const GRADE_SYSTEM_ROUTE_EN_US: IOption[] = [
	{ ID: "0", Label: "UIAA", Value: "UIAA" },
	{ ID: "1", Label: "French", Value: "French" },
	{ ID: "2", Label: "US", Value: "US" },
	{ ID: "3", Label: "EWBANKS", Value: "EWBANKS" }
];

export { GRADE_SYSTEM_BOULDER };
export { GRADE_SYSTEM_ROUTE_BG_BG };
export { GRADE_SYSTEM_ROUTE_EN_US };
