import Ionicons from "@expo/vector-icons/Ionicons";
import { CommonActions, NavigationProp, ParamListBase, RouteProp, StackActions, useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { KeyboardAvoidingView, SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";

import { Button, IOption, SearchInput } from "../../../Components";
import { Heading } from "../../../Components/Heading";
import { BookmarkIcon } from "../../../Components/Heading/Icons";
import { IRoutesPayload, RoutesList } from "../../../Components/Lists";

import { SORT_CRITERIA_BG_BG, SORT_CRITERIA_EN_US } from "../../../Constants";

import { IGymEntity, IRouteEntity, IUserEntity } from "../../../Interfaces/Entities";

import { HTTPClientContext, IHTTPClientContext } from "../../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../../Providers/Keyboard-Provider";

import { SideGutter, TopGutter } from "../../../Utilities";

const LocationDetails = (): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N, Locale }: II18NContext = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();
	const Route: RouteProp<ParamListBase> = useRoute<RouteProp<ParamListBase>>();

	const [Gym, SetGym] = useState<IGymEntity | null>(null);
	const [Routes, SetRoutes] = useState<IRoutesPayload>({ Fetching: false, Limit: 100, Loading: false, More: false, Page: 1, Payload: [] });
	const [CurrentUser, SetCurrentUser] = useState<IUserEntity | null>(null);

	const [Active, SetActive] = useState<boolean>(false);

	const [SortCriterion, SetSortCriterion] = useState<IOption>({ ID: "", Label: "", Value: "" });

	useEffect(() => {
		(async () => {
			const AxiosResGym: AxiosResponse = await _HTTPClientContext.HTTPClient.get(`locations/${ Route.params?.id }`)
			SetGym(AxiosResGym.data);

			const AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get('users/current-user');
			SetCurrentUser(AxiosResponse_Result.data)

			if (AxiosResponse_Result.data.currentGym?.id === AxiosResGym.data.id) {
				SetActive(true);
			}
		})();
	}, [Route.params]);

	useFocusEffect(
		useCallback(() => {
			if (!Gym?.id && !CurrentUser?.id) {
				return;
			}

			(async () => {
				const AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get(`locations/${ Gym.id }?limit=${ Routes.Limit }&page=${ 1 }&sortBy=${ SortCriterion.Value ? SortCriterion.Value : null }`);
				const modified = (AxiosResponse_Result.data.routes as IRouteEntity[]).map((Entry: IRouteEntity) => ({ ...Entry, location: { address: Gym.address } }))
				SetRoutes({ ...Routes, Payload: [...modified] as any, Loading: false, More: AxiosResponse_Result.data.hasNextPage });
			})();
		}, [Gym?.id, SortCriterion.Value])
	);

	const OnRouteBookmarkPress = (Route: IRouteEntity): void => {
		if (!Route.isFavourite) {
			(async () => {
				await _HTTPClientContext.HTTPClient.post<string, AxiosResponse<string, void>>("favourite-routes", { route: Route.id });
			})();

			SetRoutes({ ...Routes, Payload: Routes.Payload.map<IRouteEntity>((Entry: IRouteEntity) => {
				if (Entry.id === Route.id) {
					Entry.isFavourite = true;
				}

				return Entry;
			}) });

		} else {
			(async () => {
				await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>(`favourite-routes/${ Route.id }`);
			})();

			SetRoutes({ ...Routes, Payload: Routes.Payload.filter((Entry: IRouteEntity) => {
				if (Entry.id === Route.id) {
					Entry.isFavourite = !Entry.isFavourite;

				}

				return Entry;
			}) });
		}
	};

	return (
		<SafeAreaView collapsable>
			<KeyboardAvoidingView collapsable>
				<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" keyboardShouldPersistTaps="never" showsVerticalScrollIndicator>
					<Heading
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						Right={
							<BookmarkIcon
								Favorite={ Gym ? Gym.isFavourite : false }
								OnPress={ () => {
									SetGym((Gym) => Gym ? { ...Gym, isFavourite: !Gym.isFavourite } : null);

									(async () => {
										if(!Gym?.isFavourite) {
											await _HTTPClientContext.HTTPClient.post<string, AxiosResponse<string, void>>("favourite-locations", { location: Gym ? Gym.id : "" });

										} else {
											await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>("favourite-locations/" + Gym.id);
										}
									})();
								} } /> }
						Title={ Gym ? Gym.title : "" }
					/>

					<View collapsable>{ Gym?.trivia ? <Text style={ { textAlign: "justify", fontFamily: "Montserrat-Medium", fontSize: 16, marginBottom: 25, width: SideGutter(20, 20, 768) } }>{ Gym.trivia }</Text> : <></> }</View>

					<Heading
						ContainerStyles={ { justifyContent: "space-between", marginBottom: 25, width: SideGutter(20, 20, 768) } }
						Left={
							<View collapsable style={ Styles.Address_Container }>
								<Ionicons color="#000" name="location" size={ 25 } />
								<Text ellipsizeMode="tail" numberOfLines={ 2 } style={ Styles.Address }>{ Gym ? Gym.address : "" }</Text>
							</View>
						}
					/>

					{ Gym && Gym.gallery && Gym.gallery.length > 0 ? <Button
						Label={ I18N.t(["LocationDetailsScreen", "Gallery"]) }
						OnPress={ () => Navigation.dispatch(StackActions.push("Image Gallery", { Gallery: Gym.gallery.map((Data) => Data.path.path), Address: Gym.address, Trivia: Gym.trivia })) }
						Theme="#ED2020"
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingHorizontal: 10, paddingVertical: 10 } }
					/> : <></> }

					<Button
						Label={ Active ? I18N.t(["LocationDetailsScreen", "IClimbHere"]) : I18N.t(["LocationDetailsScreen", "ClimbHere"]) }
						OnPress={ () => {
							SetActive(!Active);

							(async () => {
								if(!Active) {
									console.log(Gym?.id);
									
									await _HTTPClientContext.HTTPClient.patch<string, AxiosResponse<string, string>>("users/current-gym/" + Gym?.id);

								} else {
									await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>("users/current-gym/");
								}
							})();
						}}
						Theme={ Active ? "#000" : "#ED2020" }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						Icon={ <Ionicons color="#FFF" name={ Active ? "checkmark-circle-outline" : "location" } size={ 25 } /> }
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, marginLeft: 5, paddingVertical: 10 } }
					/>

					<SearchInput
						Placeholder={ I18N.t(["LocationDetailsScreen", "SearchRoutes"]) }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						OnFocus={ () => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Search Routes" })) }
					/>

					<RoutesList
						EmptyListLabel={ I18N.t(["LocationDetailsScreen", "NoRelatedRoutesFound"]) }
						Fetching={ Routes.Fetching }
						Loading={ Routes.Loading }
						More={ Routes.More }
						OnRouteBookmarkPress={ (RelatedRoute: IRouteEntity) => OnRouteBookmarkPress(RelatedRoute) }
						OnRouteCardPress={ (RelatedRoute: IRouteEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Route Details", params: { id: RelatedRoute.id } })) }
						Pagination={ <></> }
						Routes={ Routes.Payload }
						ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 0) } }
						Header={
							<ScrollView collapsable contentContainerStyle={ Styles.ScrollViewContent_Container } horizontal showsHorizontalScrollIndicator={ false } style={ Styles.ScrollView_Container }>
								<Text style={ Styles.SortCriterion_Label }>{ I18N.t(["LocationDetailsScreen", "SortBy"]) }</Text>

								{ (Locale === "bg-BG" ? SORT_CRITERIA_BG_BG : SORT_CRITERIA_EN_US).map<JSX.Element>((Criterion: IOption) => <Button
									key={ Criterion.ID }
									Label={ Criterion.Label }
									OnPress={ () => SortCriterion.Value === Criterion.Value ? SetSortCriterion({ ID: "", Label: "", Value: "" }) : SetSortCriterion(Criterion) }
									Theme={ SortCriterion.ID === Criterion.ID ? "#000" : "#FFF" }
									ContainerStyles={ { marginHorizontal: 10 } }
									LabelStyles={ { color: SortCriterion.ID === Criterion.ID ? "#FFF" : "#000", fontFamily: "Montserrat-Medium", fontSize: 14, marginHorizontal: 5, paddingHorizontal: 10, paddingVertical: 4 } }
								/>) }
							</ScrollView>
						}
					/>

					{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
				</ScrollView>
			</KeyboardAvoidingView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	Address_Container: {
		flex: 1,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	Address: {
		color: "#000",
		flex: 1,
		fontFamily: "Montserrat-Medium",
		fontSize: 15,
		marginLeft: 5,
	},
	ScrollViewContent_Container: {
		alignItems: "center",
		paddingBottom: 10
	},
	ScrollView_Container: {
		width: SideGutter(0, 0, 768)
	},
	SortCriterion_Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 14,
		marginLeft: 15
	},
	FloatButton: {
		backgroundColor: 'lightgray',
		padding: 10,
		borderRadius: 10,
	}
});

export { LocationDetails };
