import Entypo from "@expo/vector-icons/Entypo";
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import { IsMobileDevice } from "../../../Utilities";

interface IDeleteIconProps {
	OnPress: () => void;
	ContainerStyles?: ViewStyle;
};

const DeleteIcon = (Props: IDeleteIconProps): JSX.Element => {

	const { OnPress, ContainerStyles } = Props;

	if (!IsMobileDevice()) {
		return <View collapsable style={ Styles.Placeholder } />;
	}

	return (
		<TouchableOpacity onPress={ () => OnPress() } style={ ContainerStyles }>
			<Entypo color="#ED2020" name="circle-with-cross" size={ 25 } />
		</TouchableOpacity>
	);
};

const Styles = StyleSheet.create({
	Placeholder: {
		height: 25,
		margin: 5,
		width: 25
	}
});

export { DeleteIcon };
