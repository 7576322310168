import Feather from "@expo/vector-icons/Feather";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { Image } from "expo-image";
import { StyleSheet, Text, TextStyle, TouchableHighlight, TouchableOpacity, View } from "react-native";

import { eEWallsAPI } from "../Constants";
import { IUserEntity } from "../Interfaces/Entities";
interface IComment {
	text: string;
	userId: IUserEntity;
	id: string;
};

interface ICommentPayload {
	Fetching: boolean;
	Limit: number;
	Loading: boolean;
	More: boolean;
	Page: number;
	Payload: IComment[];
};

interface ICommentsListControls {
	Fetching: boolean;
	Loading: boolean;
	More: boolean;
	Page: number;
	Payload: IComment[];
};

interface ICommentProps {
	Comment: IComment;
	OnPress: (Comment: IComment) => void;
	OnLongPress: (Comment: IComment) => void;
	LabelStyles?: TextStyle;
	ContainerStyles?: TextStyle;
};

const Comment = (Props: ICommentProps): JSX.Element => {

	const { Comment, ContainerStyles, OnPress, OnLongPress } = Props;

	const { userId: user } = Comment;

	return (
		<TouchableHighlight onLongPress={ () => OnLongPress(Comment) } style={ [Styles.TouchableOpacity, ContainerStyles] }>
			<View collapsable style={ [Styles.Flex_Container, ContainerStyles ? { width: ContainerStyles.width } : {}] }>
				<View collapsable style={ Styles.FlexContent_Container }>
					<Image
						collapsable
						contentFit="cover"
						placeholder="L2HC1R~q00IU?bM{ofj[000000%M"
						placeholderContentFit="cover"
						priority="high"
						source={ user.photo && user.photo.path ? eEWallsAPI.BASE_URL + user.photo.path : require("../../assets/Icon.png") }
						style={ Styles.Image }
					/>

					<View collapsable style={ Styles.Information_Container }>
						<View collapsable style={ Styles.First_Row_Container }>
							<Text ellipsizeMode="tail" numberOfLines={ 2 } style={ Styles.Name }>{ user.firstName + " " + user.lastName }</Text>
						</View>

						<View collapsable style={ Styles.Address_Container }>
							<FontAwesome color="#000" name="comment" size={ 15 } />
							<Text ellipsizeMode="tail" numberOfLines={ 2 } style={ Styles.Address }>{ Comment.text }</Text>
						</View>
					</View>
				</View>

				<TouchableOpacity onPress={ () => OnPress(Comment) } style={ Styles.Bookmark }>
					<Feather color="#000" name="more-vertical" size={ 25 } />
				</TouchableOpacity>
			</View>
		</TouchableHighlight>
	);
};

const Styles = StyleSheet.create({
	TouchableOpacity: {
		borderRadius: 8
	},
	Flex_Container: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		elevation: 3,
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between",
		shadowColor: "#000",
		shadowOffset: {
			height: 1,
			width: 0
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22
	},
	FlexContent_Container: {
		flex: 1,
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "center"
	},
	Image: {
		borderBottomLeftRadius: 8,
		borderTopLeftRadius: 8,
		height: 100,
		width: 100
	},
	Information_Container: {
		flex: 1,
		padding: 7,
	},
	First_Row_Container: {
		flex: 1,
	},
	Name: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginBottom: 10
	},
	Address_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	Address: {
		color: "#000",
		flex: 1,
		fontFamily: "Montserrat-Medium",
		fontSize: 14,
		marginLeft: 5
	},
	Bookmark: {
		padding: 5
	}
});

export { Comment, IComment, ICommentPayload, ICommentsListControls };
