import { I18n } from 'i18n-js';
import { MutableRefObject, useContext, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text } from 'react-native';
import ActionSheet, { ActionSheetRef, SheetManager, SheetProps, useScrollHandlers } from 'react-native-actions-sheet';

import { Button, Input } from "../";

import { SideGutter } from "../../Utilities";
import { HTTPClientContext, IHTTPClientContext } from '../../Providers/HTTP-Client-Provider';

interface IReportRoute {
	I18N: I18n;
};

const ReportRouteSheet = (Props: SheetProps<IReportRoute>): JSX.Element => {
	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);

	const { sheetId, payload } = Props;

	const ReportRouteSheet_Ref: MutableRefObject<ActionSheetRef | null> = useRef<ActionSheetRef | null>(null);

	const ScrollHandlers = useScrollHandlers<ScrollView>("Report-Route-Sheet", ReportRouteSheet_Ref);

	const [Report, SetReport] = useState<string>("");

	const SubmitReport = async () => {
		await _HTTPClientContext.HTTPClient.post("reports", {
			routeId: (payload as any).Route.id,
			text: Report
		});

		SheetManager.hide<IReportRoute>(sheetId);
	};

	return (
		<ActionSheet containerStyle={ Styles.ActionSheetContent_Container } defaultOverlayOpacity={ 0.5 } gestureEnabled id={ sheetId } indicatorStyle={ Styles.Indicator } initialSnapIndex={ 2 } ref={ ReportRouteSheet_Ref } snapPoints={ [33, 66, 100] }>
			<ScrollView collapsable contentContainerStyle={ Styles.ScrollViewContent_Container } contentInsetAdjustmentBehavior="automatic" { ...ScrollHandlers } showsVerticalScrollIndicator>
				<Text style={ Styles.Title }>{ payload.I18N.t(["ReportRouteSheet", "ReportRoute"]) }</Text>

				<Input
					Label={ payload.I18N.t(["ReportRouteSheet", "Report"]) }
					OnChange={ (Text: string) => SetReport(Text) }
					Placeholder={ payload.I18N.t(["ReportRouteSheet", "ShareYourReportHere"]) }
					Value={ Report }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Lines={ 5 }
					Multiline
				/>

				{ Report ? <Button
					Label={ payload.I18N.t(["ReportRouteSheet", "SubmitReport"]) }
					OnPress={ () => SubmitReport() }
					Theme="#ED2020"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/> : <></> }

				{ Report ? <Button
					Label={ payload.I18N.t(["ReportRouteSheet", "ClearReport"]) }
					OnPress={ () => SetReport("") }
					Theme="#000"
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
				/> : <></> }
			</ScrollView>
		</ActionSheet>
	);
};

const Styles = StyleSheet.create({
	ActionSheetContent_Container: {
		borderTopLeftRadius: 25,
		borderTopRightRadius: 25,
		backgroundColor: "#F5F5F5",
		paddingTop: 10,
		flex: 0.75
	},
	Indicator: {
		backgroundColor: "#000",
		width: 50
	},
	ScrollViewContent_Container: {
		backgroundColor: "#F5F5F5",
		alignItems: "center",
		paddingTop: 10
	},
	Title: {
		color: "#000",
		fontFamily: "Montserrat-Bold",
		fontSize: 18,
		marginBottom: 25
	}
});

export { IReportRoute, ReportRouteSheet };
