import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from "react-native";

interface ISideLabelProps {
	Label: string;
	ContainerStyles?: ViewStyle;
	LabelOnRight?: boolean;
	OnPress?: () => void;
};

const SideLabel = (Props: ISideLabelProps): JSX.Element => {

	const { Label, ContainerStyles, LabelOnRight, OnPress } = Props;

	return (
		<View collapsable style={ [Styles.Flex_Container, ContainerStyles] }>
			{ LabelOnRight ? <View collapsable /> : <></> }

			<TouchableOpacity disabled={ OnPress ? false : true } onPress={ () => OnPress ? OnPress() : {} }>
				<Text style={ Styles.Label }>{ Label }</Text>
			</TouchableOpacity>
		</View>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	},
	Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16
	}
});

export { SideLabel };
