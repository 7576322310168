import Ionicons from "@expo/vector-icons/Ionicons";
import { ActivityIndicator, NativeSyntheticEvent, StyleSheet, TextInput, TextInputKeyPressEventData, TouchableOpacity, View, ViewStyle } from "react-native";

interface ISearchInputProps {
	Placeholder: string;
	AutoFocus?: boolean;
	ContainerStyles?: ViewStyle;
	OnBlur?: () => void;
	OnChange?: (Text: string) => void;
	OnFocus?: () => void;
	OnPress?: () => void;
	OnSubmit?: () => void;
	Searching?: boolean;
	Value?: string;
};

const SearchInput = (Props: ISearchInputProps): JSX.Element => {

	const { Placeholder, AutoFocus, ContainerStyles, OnBlur, OnChange, OnFocus, OnPress, OnSubmit, Searching, Value } = Props;

	return (
		<View collapsable style={ [Styles.FlexRelative_Container, ContainerStyles] }>
			<TextInput
				autoCapitalize="none"
				autoComplete="off"
				autoCorrect={ false }
				autoFocus={ AutoFocus ? true : false }
				collapsable
				cursorColor="#000"
				editable={ Searching ? false : true }
				keyboardType="default"
				onBlur={ () => OnBlur ? OnBlur() : {} }
				blurOnSubmit
				onChangeText={ (Text: string) => OnChange ? OnChange(Text) : {} }
				onSubmitEditing={ () => OnSubmit ? OnSubmit() : {} }
				onFocus={ () => OnFocus ? OnFocus() : {} }
				placeholder={ Placeholder }
				placeholderTextColor="#000"
				style={ Styles.SearchInput }
				textContentType="none"
				value={ Value ? Value : "" }
			/>

			{ Value ? (
				<TouchableOpacity disabled={ Searching ? true : false } onPress={ () => OnPress ? OnPress() : {} } style={ Styles.TouchableOpacity }>
					{ Searching ? <ActivityIndicator collapsable color="#000" size={ 25 } /> : <Ionicons color="#000" name="search" size={ 25 } /> }
				</TouchableOpacity>
			) : <></> }
		</View>
	);
};

const Styles = StyleSheet.create({
	FlexRelative_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		position: "relative"
	},
	SearchInput: {
		backgroundColor: "#FFF",
		borderColor: "#E3E5E5",
		borderRadius: 8,
		borderWidth: 1,
		color: "#000",
		flex: 1,
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		padding: 10
	},
	TouchableOpacity: {
		padding: 10,
		position: "absolute",
		right: 1
	}
});

export { SearchInput };
