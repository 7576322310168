import AntDesign from "@expo/vector-icons/AntDesign";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import Ionicons from "@expo/vector-icons/Ionicons";
import { CommonActions, NavigationProp, useNavigation } from "@react-navigation/native";
import { Platform, SafeAreaView, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from "react-native";

interface IBottomTabulatorProps {
	FocusedTab: string;
	Tabs: string[];
	ContainerStyles?: ViewStyle;
};

const BottomTabulator = (Props: IBottomTabulatorProps): JSX.Element => {

	const { FocusedTab, Tabs, ContainerStyles } = Props;

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();

	return (
		<SafeAreaView collapsable style={ [Styles.Flex_Container] }>
			<View collapsable style={ [Styles.FlexContent_Container, ContainerStyles] }>
				<TouchableOpacity onPress={ () => {
					Navigation.dispatch(CommonActions.navigate("Home Tab", { screen: "Home" }));
				} }>
					<AntDesign color={ /^Home Tab-.*$/.test(FocusedTab) ? "#ED2020" : "#000" } name="home" size={ 20 } style={ Styles.Icon } />
					<Text style={ /^Home Tab-.*$/.test(FocusedTab) ? { color: "#ED2020" } : { color: "#000" } }>{ Tabs[0] }</Text>
				</TouchableOpacity>

				<TouchableOpacity onPress={ () => {
					Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Explore" }));
				} }>
					<Ionicons color={ /^Locations Tab-.*$/.test(FocusedTab) ? "#ED2020" : "#000" } name="location-outline" size={ 20 } style={ Styles.Icon } />
					<Text style={ /^Locations Tab-.*$/.test(FocusedTab) ? { color: "#ED2020" } : { color: "#000" } }>{ Tabs[1] }</Text>
				</TouchableOpacity>

				<TouchableOpacity onPress={ () => {
					Navigation.dispatch(CommonActions.navigate("Profile Tab", { screen: "Your Profile" }));
				} }>
					<FontAwesome color={ /^Profile Tab-.*$/.test(FocusedTab) ? "#ED2020" : "#000" } name="user-o" size={ 20 } style={ Styles.Icon } />
					<Text style={ /^Profile Tab-.*$/.test(FocusedTab) ? { color: "#ED2020" } : { color: "#000" } }>{ Tabs[2] }</Text>
				</TouchableOpacity>
			</View>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		backgroundColor: "#F5F5F5",
		alignItems: "center",
		marginBottom: Platform.OS === 'ios' ? -30 : 0
	},
	FlexContent_Container: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		elevation: 3,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-evenly",
		shadowColor: "#000",
		shadowOffset: {
			height: 1,
			width: 0
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22
	},
	Icon: {
		textAlign: "center"
	},
	Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		textAlign: "center"
	}
});

export { BottomTabulator };
