import { CommonActions, NavigationProp, useFocusEffect, useNavigation } from "@react-navigation/native";
import Geolocation, { GeolocationResponse } from "@react-native-community/geolocation";
import { AxiosResponse } from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { KeyboardAvoidingView, RefreshControl, SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";
import { SheetManager } from "react-native-actions-sheet";

import { Button, IOption, SearchInput, Tabulator } from "../../Components";
import { Heading } from "../../Components/Heading";
import { ProfileIcon, ScanIcon } from "../../Components/Heading/Icons";
import { FavoriteGymsList, FavoriteRoutesList, GymsList, IGymsPayload, IRoutesPayload, RoutesList } from "../../Components/Lists";
import { IRouteFilters } from "../../Components/Sheets";
import { WelcomeSlogan } from "../../Components/Typography";

import { SORT_CRITERIA_BG_BG, SORT_CRITERIA_EN_US } from "../../Constants";

import { IGymEntity, IGymsListEntity, IRouteEntity, IRouteListEntity, IUserEntity } from "../../Interfaces/Entities";

import { HTTPClientContext, IHTTPClientContext } from "../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../Providers/Keyboard-Provider";

import { BuildFilterQuery, SideGutter, TimeGreeting, TopGutter } from "../../Utilities";

const Home = (): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N, Locale }: II18NContext = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();

	const [CurrentPosition, SetCurrentPosition] = useState<GeolocationResponse | null>(null);
	const [Refreshing, SetRefreshing] = useState<boolean>(false);
	const [User, SetUser] = useState<IUserEntity | null>(null);
	const [Tab, SetTab] = useState<string>("Gyms");

	const [FavoriteGyms, SetFavoriteGyms] = useState<IGymsPayload>({ Fetching: false, Limit: 100, Loading: false, More: false, Page: 1, Payload: [] });
	const [FavoriteRoutes, SetFavoriteRoutes] = useState<IRoutesPayload>({ Fetching: false, Limit: 100, Loading: false, More: false, Page: 1, Payload: [] });

	const [Gyms, SetGyms] = useState<IGymsPayload>({ Fetching: false, Limit: 5, Loading: false, More: false, Page: 1, Payload: [] });
	const [Routes, SetRoutes] = useState<IRoutesPayload>({ Fetching: false, Limit: 5, Loading: false, More: false, Page: 1, Payload: [] });

	const [RouteFilters, SetRouteFilters] = useState<IRouteFilters>({ Active: false, BoulderGradeFrom: 1, BoulderGradeTo: 23, FilterBy: { ID: "", Label: "", Value: "" }, Locale, RatingFrom: 0, RatingTo: 5, RouteGradeFrom: 1, RouteGradeTo: 32, RouteType: null, I18N });
	const [SortCriterion, SetSortCriterion] = useState<IOption>({ ID: "", Label: "", Value: "" });

	useEffect(() => {
		(async () => {
			GetCurrentLocation();
		})();
	}, []);

	useFocusEffect(
		useCallback(() => {
			(async () => {
				const AxiosResponse_Result: AxiosResponse<IUserEntity, void> = await _HTTPClientContext.HTTPClient.get<IUserEntity, AxiosResponse<IUserEntity, void>, IUserEntity>("users/current-user");
				SetUser(AxiosResponse_Result.data);
			})();

			(async () => {
				SetFavoriteGyms({ ...FavoriteGyms, Payload: [] });
				const AxiosResponse_Result: AxiosResponse<IGymsListEntity, void> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`favourite-locations?limit=${ FavoriteGyms.Limit }&page=${ FavoriteGyms.Page }`);
				SetFavoriteGyms({ ...FavoriteGyms, Payload: AxiosResponse_Result.data.data.map<IGymEntity>((Gym: IGymEntity) => ({ ...Gym, isFavourite: true })), Loading: false, More: AxiosResponse_Result.data.hasNextPage });
			})();

			(async () => {
				SetFavoriteRoutes({ ...FavoriteRoutes, Payload: [] });
				const AxiosResponse_Result: AxiosResponse<IRouteListEntity, void> = await _HTTPClientContext.HTTPClient.get<IRouteListEntity, AxiosResponse<IRouteListEntity, void>, void>(`favourite-routes?limit=${ FavoriteRoutes.Limit }&page=${ FavoriteRoutes.Page }`);
				SetFavoriteRoutes({ ...FavoriteRoutes, Payload: AxiosResponse_Result.data.data.map<IRouteEntity>((Route: IRouteEntity) => ({ ...Route, isFavourite: true })), Loading: false, More: AxiosResponse_Result.data.hasNextPage });
			})();
		}, [])
	);

	useEffect(() => {
		(async () => {
			SetFavoriteGyms({ ...FavoriteGyms, Fetching: FavoriteGyms.Page === 1 ? false : true, Loading: FavoriteGyms.Page === 1 ? true : false });
			const AxiosResponse_Result: AxiosResponse<IGymsListEntity, void> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`favourite-locations?limit=${ FavoriteGyms.Limit }&page=${ FavoriteGyms.Page }`);
			SetFavoriteGyms({ ...FavoriteGyms, Payload: [...FavoriteGyms.Payload, ...AxiosResponse_Result.data.data.map<IGymEntity>((Gym: IGymEntity) => ({ ...Gym, isFavourite: true }))], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [FavoriteGyms.Page]);

	useEffect(() => {
		(async () => {
			const AxiosResponse_Result:  AxiosResponse<IRouteListEntity, void> = await _HTTPClientContext.HTTPClient.get<IRouteListEntity, AxiosResponse<IRouteListEntity, void>, void>(`favourite-routes?limit=${ FavoriteRoutes.Limit }&page=${ FavoriteRoutes.Page }`);
			SetFavoriteRoutes({ ...FavoriteRoutes, Payload: [...FavoriteRoutes.Payload, ...AxiosResponse_Result.data.data.map<IRouteEntity>((Route: IRouteEntity) => ({ ...Route, isFavourite: true }))], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [FavoriteRoutes.Page]);

	useEffect(() => {
		(async () => {
			SetGyms({ ...Gyms, Fetching: Gyms.Page === 1 ? false : true, Loading: Gyms.Page === 1 ? true : false });
			const AxiosResponse_Result: AxiosResponse<IGymsListEntity, void> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`locations?latitude=${ CurrentPosition ? CurrentPosition.coords.latitude : null }&longitude=${ CurrentPosition ? CurrentPosition.coords.longitude : null }&limit=${ Gyms.Limit }&page=${ 1 }`);
			SetGyms({ ...Gyms, Page: 1, Payload: [...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [CurrentPosition?.coords.latitude, CurrentPosition?.coords.longitude]);

	useEffect(() => {
		(async () => {
			const AxiosResponse_Result: AxiosResponse<IRouteListEntity, void> = await _HTTPClientContext.HTTPClient.get<IRouteListEntity, AxiosResponse<IRouteListEntity, void>, void>(`routes?latitude=${ CurrentPosition ? CurrentPosition.coords.latitude : null }&longitude=${ CurrentPosition ? CurrentPosition.coords.longitude : null }&limit=${ Routes.Limit }&page=${ 1 }&ratingFrom=${ RouteFilters.RatingFrom >= 0 ? RouteFilters.RatingFrom : null }&ratingTo=${ RouteFilters.RatingTo >= 0 ? RouteFilters.RatingTo : null }&sortBy=${ SortCriterion.Value ? SortCriterion.Value : null }&${ BuildFilterQuery(RouteFilters) }`);
			SetRoutes({ ...Routes, Page: 1, Payload: [...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [CurrentPosition?.coords.latitude, CurrentPosition?.coords.longitude, RouteFilters.BoulderGradeFrom, RouteFilters.BoulderGradeTo, RouteFilters.RatingFrom, RouteFilters.RatingTo, RouteFilters.RouteGradeFrom, RouteFilters.RouteGradeTo, RouteFilters.RouteType, SortCriterion.Value]);

	useEffect(() => {
		if (Gyms.Page === 1) {
			return;
		}

		(async () => {
			SetGyms({ ...Gyms, Fetching: Gyms.Page === 1 ? false : true, Loading: Gyms.Page === 1 ? true : false });
			const AxiosResponse_Result: AxiosResponse<IGymsListEntity, void> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`locations?latitude=${ CurrentPosition ? CurrentPosition.coords.latitude : null }&longitude=${ CurrentPosition ? CurrentPosition.coords.longitude : null }&limit=${ Gyms.Limit }&page=${ Gyms.Page }`);
			SetGyms({ ...Gyms, Payload: [...Gyms.Payload, ...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [Gyms.Page]);

	useEffect(() => {
		if (Routes.Page === 1) {
			return;
		}

		(async () => {
			SetRoutes({ ...Routes, Fetching: Routes.Page === 1 ? false : true, Loading: Routes.Page === 1 ? true : false });
			const AxiosResponse_Result: AxiosResponse<IRouteListEntity, void> = await _HTTPClientContext.HTTPClient.get<IRouteListEntity, AxiosResponse<IRouteListEntity, void>, void>(`routes?latitude=${ CurrentPosition ? CurrentPosition.coords.latitude : null }&longitude=${ CurrentPosition ? CurrentPosition.coords.longitude : null }&limit=${ Routes.Limit }&page=${ Routes.Page }&ratingFrom=${ RouteFilters.RatingFrom >= 0 ? RouteFilters.RatingFrom : null }&ratingTo=${ RouteFilters.RatingTo >= 0 ? RouteFilters.RatingTo : null }&sortBy=${ SortCriterion.Value ? SortCriterion.Value : null }&${ BuildFilterQuery(RouteFilters) }`);
			SetRoutes({ ...Routes, Payload: [...Routes.Payload, ...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
		})();
	}, [Routes.Page]);

	const GetCurrentLocation = (): void => {
		Geolocation.getCurrentPosition(
			(position: GeolocationResponse) => {
				SetCurrentPosition(position);
				SetRefreshing(false);

				(async () => {
					SetGyms({ ...Gyms, Fetching: Gyms.Page === 1 ? false : true, Loading: Gyms.Page === 1 ? true : false });
					const AxiosResponse_Result: AxiosResponse<IGymsListEntity, void> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`locations?latitude=${ position.coords.latitude }&longitude=${ position.coords.longitude }&limit=${ Gyms.Limit }&page=${ 1 }`);
					SetGyms({ ...Gyms, Page: 1, Payload: [...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
				})();

				(async () => {
					SetRoutes({ ...Routes, Fetching: Routes.Page === 1 ? false : true, Loading: Routes.Page === 1 ? true : false });
					const AxiosResponse_Result: AxiosResponse<IRouteListEntity, void> = await _HTTPClientContext.HTTPClient.get<IRouteListEntity, AxiosResponse<IRouteListEntity, void>, void>(`routes?latitude=${ position.coords.latitude }&longitude=${ position.coords.longitude }&limit=${ Routes.Limit }&page=${ 1 }&ratingFrom=${ RouteFilters.RatingFrom >= 0 ? RouteFilters.RatingFrom : null }&ratingTo=${ RouteFilters.RatingTo >= 0 ? RouteFilters.RatingTo : null }&routeGradeFrom=${ RouteFilters.RouteGradeFrom }&routeGradeTo=${ RouteFilters.RouteGradeTo }&sortBy=${ SortCriterion.Value ? SortCriterion.Value : null }&type=${ RouteFilters.RouteType }`);
					SetRoutes({ ...Routes, Page: 1, Payload: [...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
				})();
			},
			() => {
				SetCurrentPosition(null);
				SetRefreshing(false);

				(async () => {
					SetGyms({ ...Gyms, Fetching: Gyms.Page === 1 ? false : true, Loading: Gyms.Page === 1 ? true : false });
					const AxiosResponse_Result: AxiosResponse<IGymsListEntity, void> = await _HTTPClientContext.HTTPClient.get<IGymsListEntity, AxiosResponse<IGymsListEntity, void>, void>(`locations?latitude=null&longitude=null&limit=${ Gyms.Limit }&page=${ 1 }`);
					SetGyms({ ...Gyms, Page: 1, Payload: [...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
				})();

				(async () => {
					SetRoutes({ ...Routes, Fetching: Routes.Page === 1 ? false : true, Loading: Routes.Page === 1 ? true : false });
					const AxiosResponse_Result: AxiosResponse<IRouteListEntity, void> = await _HTTPClientContext.HTTPClient.get<IRouteListEntity, AxiosResponse<IRouteListEntity, void>, void>(`routes?latitude=null&longitude=null&limit=${ Routes.Limit }&page=${ 1 }&ratingFrom=${ RouteFilters.RatingFrom >= 0 ? RouteFilters.RatingFrom : null }&ratingTo=${ RouteFilters.RatingTo >= 0 ? RouteFilters.RatingTo : null }&routeGradeFrom=${ RouteFilters.RouteGradeFrom }&routeGradeTo=${ RouteFilters.RouteGradeTo }&sortBy=${ SortCriterion.Value ? SortCriterion.Value : null }&type=${ RouteFilters.RouteType }`);
					SetRoutes({ ...Routes, Page: 1, Payload: [...AxiosResponse_Result.data.data], Loading: false, More: AxiosResponse_Result.data.hasNextPage });
				})();
			},
			{ enableHighAccuracy: true, maximumAge: 0 }
		);
	};

	const OpenRouteFiltersSheet = async (): Promise<void> => {
		const RouteFilters_Result: IRouteFilters = await SheetManager.show<IRouteFilters, IRouteFilters>("Route-Filters-Sheet", { payload: { ...RouteFilters, Locale } });

		if (RouteFilters_Result) {
			SetRouteFilters(RouteFilters_Result);
		}
	};

	const OnGymBookmarkPress = (Gym: IGymEntity): void => {
		if (!Gym.isFavourite) {
			(async () => {
				await _HTTPClientContext.HTTPClient.post<string, AxiosResponse<string, void>>("favourite-locations", { location: Gym.id });
			})();

			SetFavoriteGyms({ ...FavoriteGyms, Payload: [...FavoriteGyms.Payload, ...Gyms.Payload.filter((Entry: IGymEntity) => {
				if (Entry.id === Gym.id) {
					Entry.isFavourite = true;
					return Entry;
				}
			})] });

			SetGyms({ ...Gyms, Payload: Gyms.Payload.map<IGymEntity>((Entry: IGymEntity) => {
				if (Entry.id === Gym.id) {
					Entry.isFavourite = true;
				}

				return Entry;
			}) });

		} else {
			(async () => {
				await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>(`favourite-locations/${ Gym.id }`);
			})();

			SetFavoriteGyms({ ...FavoriteGyms, Payload: FavoriteGyms.Payload.filter((FavoriteGym: IGymEntity) => FavoriteGym.id !== Gym.id) });
			SetGyms({ ...Gyms, Payload: Gyms.Payload.filter((Entry: IGymEntity) => {
				if (Entry.id === Gym.id) {
					Entry.isFavourite = !Entry.isFavourite;

				}

				return Entry;
			}) });
		}
	};

	const OnRouteBookmarkPress = (Route: IRouteEntity): void => {
		if (!Route.isFavourite) {
			(async () => {
				await _HTTPClientContext.HTTPClient.post<string, AxiosResponse<string, void>>("favourite-routes", { route: Route.id });
			})();

			SetFavoriteRoutes({ ...FavoriteRoutes, Payload: [...FavoriteRoutes.Payload, ...Routes.Payload.filter((Entry: IRouteEntity) => {
				if (Entry.id === Route.id) {
					Entry.isFavourite = true;
					return Entry;
				}
			})] });

			SetRoutes({ ...Routes, Payload: Routes.Payload.map((Entry: IRouteEntity) => {
				if (Entry.id === Route.id) {
					Entry.isFavourite = true;
				}

				return Entry;
			}) });

		} else {
			(async () => {
				await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>(`favourite-routes/${ Route.id }`);
			})();

			SetFavoriteRoutes({ ...FavoriteRoutes, Payload: FavoriteRoutes.Payload.filter((Entry: IRouteEntity) => Entry.id !== Route.id) });
			SetRoutes({ ...Routes, Payload: Routes.Payload.filter((Entry: IRouteEntity) => {
				if (Entry.id === Route.id) {
					Entry.isFavourite = !Entry.isFavourite;

				}

				return Entry;
			}) });
		}
	};

	return (
		<SafeAreaView collapsable>
			<KeyboardAvoidingView collapsable>
				<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" keyboardShouldPersistTaps="never" refreshControl={ <RefreshControl onRefresh={ () => { SetRefreshing(true); GetCurrentLocation(); } } refreshing={ Refreshing } /> } showsVerticalScrollIndicator>
					<Heading
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						Left={ <ScanIcon OnPress={ () => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Scan" })) } /> }
						Right={
							<ProfileIcon
								ImageStyles={ { borderRadius: 5, flex: 1, width: "100%" } }
								OnPress={ () => Navigation.dispatch(CommonActions.navigate("Profile Tab", { screen: "Your Profile" })) }
								URL={ `https://app.e-walls.eu/api/${ User ? User.photo.path : "" }` }
								ContainerStyles={ { alignItems: "center", height: 25, margin: 5, width: 25 } }
							/>
						}
						Title={ I18N.t(["HomeScreen", "Home"]) }
					/>

					<WelcomeSlogan
						Name={ User ? `${ User.firstName } ${ User.lastName }` : "" }
						Slogan={ I18N.t(["HomeScreen", "Slogan"]) }
						Timing={ TimeGreeting(Locale) }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					/>

					<SearchInput
						Placeholder={ Locale === "bg-BG" ? `Търси ${ Tab === "Gyms" ? "Фитнеси" : "Маршрути" }...` : `Search ${ Tab }...` }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						OnFocus={ () => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: Tab === "Gyms" ? "Search Gyms" : "Search Routes" })) }
					/>

					{ Tab === "Gyms" ? <FavoriteGymsList
						EmptyListLabel={ I18N.t(["HomeScreen", "NoFavoriteGymsFound"]) }
						FavoriteGyms={ FavoriteGyms.Payload }
						Fetching={ FavoriteGyms.Fetching }
						Label={ I18N.t(["HomeScreen", "YourFavoriteGyms"]) }
						Loading={ FavoriteGyms.Loading }
						More={ FavoriteGyms.More }
						OnFavoriteGymBookmarkPress={ async (FavoriteGym: IGymEntity) => {
							SetFavoriteGyms({ ...FavoriteGyms, Payload: FavoriteGyms.Payload.filter((Entry: IGymEntity) => Entry.id !== FavoriteGym.id) });
							SetGyms({ ...Gyms, Payload: Gyms.Payload.filter((Entry) => {
								if (Entry.id === FavoriteGym.id) {
									Entry.isFavourite = !Entry.isFavourite;
								}

								return Entry;
							}) });

							await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>(`favourite-locations/${ FavoriteGym.id }`);
						} }
						OnFavoriteGymCardPress={ (FavoriteGym: IGymEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Location Details", params: { id: FavoriteGym.id } })) }
						Pagination={
							<Button
								Label={ I18N.t(["HomeScreen", "ShowMoreFavoriteGyms"]) }
								OnPress={ () => SetFavoriteGyms({ ...FavoriteGyms, Page: FavoriteGyms.Page + 1 }) }
								Theme="#ED2020"
								ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
								LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
							/>
						}
						ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
					/> : <></> }

					{ Tab === "Trending Routes" ? <FavoriteRoutesList
						EmptyListLabel={ I18N.t(["HomeScreen", "NoFavoriteRoutesFound"]) }
						FavoriteRoutes={ FavoriteRoutes.Payload }
						Fetching={ FavoriteRoutes.Fetching }
						Label={ I18N.t(["HomeScreen", "YourFavoriteRoutes"]) }
						Loading={ FavoriteRoutes.Loading }
						More={ FavoriteRoutes.More }
						OnFavoriteRouteBookmarkPress={ async (FavoriteRoute: IRouteEntity) => {
							SetFavoriteRoutes({ ...FavoriteRoutes, Payload: FavoriteRoutes.Payload.filter((Route: IRouteEntity) => Route.id !== FavoriteRoute.id) });
							SetRoutes({ ...Routes, Payload: Routes.Payload.filter((Route) => {
								if (Route.id === FavoriteRoute.id) {
									Route.isFavourite = !Route.isFavourite;
								}

								return Route;
							}) });

							await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>(`favourite-routes/${ FavoriteRoute.id }`);
						} }
						OnFavoriteRouteCardPress={ (FavoriteRoute: IRouteEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Route Details", params: { id: FavoriteRoute.id } })) }
						Pagination={
							<Button
								Label={ I18N.t(["HomeScreen", "ShowMoreFavoriteRoutes"]) }
								OnPress={ () => SetFavoriteRoutes({ ...FavoriteRoutes, Page: FavoriteRoutes.Page + 1 }) }
								Theme="#ED2020"
								ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
								LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
							/>
						}
						ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
					/> : <></> }

					<Tabulator
						OnPress={ (SelectedTab: string) => SetTab(SelectedTab) }
						SelectedTab={ Tab }
						Tabs={ ["Gyms", "Trending Routes"] }
						BG_Tabs={ ["Фитнеси", "Маршрути"] }
						ContainerStyles={ { marginBottom: 10, width: SideGutter(0, 0, 768) } }
						Locale={ Locale }
					/>

					{ Tab === "Gyms" ? <GymsList
						EmptyListLabel={ I18N.t(["HomeScreen", "NoGymsFound"]) }
						Fetching={ Gyms.Fetching }
						Gyms={ Gyms.Payload }
						Loading={ Gyms.Loading }
						More={ Gyms.More }
						OnGymBookmarkPress={ (Gym: IGymEntity) => OnGymBookmarkPress(Gym) }
						OnGymCardPress={ (Gym: IGymEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Location Details", params: { id: Gym.id } })) }
						Pagination={
							<Button
								Label={ I18N.t(["HomeScreen", "ShowMoreGyms"]) }
								OnPress={ () => SetGyms({ ...Gyms, Page: Gyms.Page + 1 }) }
								Theme="#ED2020"
								ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
								LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
							/>
						}
						ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
					/> : <></> }

					{ Tab === "Trending Routes" ? <RoutesList
						EmptyListLabel={ I18N.t(["HomeScreen", "NoTrendingRoutesFound"]) }
						Fetching={ Routes.Fetching }
						Loading={ Routes.Loading }
						More={ Routes.More }
						OnRouteBookmarkPress={ (TrendingRoute: IRouteEntity) => OnRouteBookmarkPress(TrendingRoute) }
						OnRouteCardPress={ (TrendingRoute: IRouteEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Route Details", params: { id: TrendingRoute.id } })) }
						Pagination={
							<Button
								Label={ I18N.t(["HomeScreen", "ShowMoreTrendingRoutes"]) }
								OnPress={ () => SetRoutes({ ...Routes, Page: Routes.Page + 1 }) }
								Theme="#ED2020"
								ContainerStyles={ { marginBottom: 25, marginTop: 15, width: SideGutter(20, 20, 768) } }
								LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
							/>
						}
						Routes={ Routes.Payload }
						ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
						Header={
							<ScrollView collapsable contentContainerStyle={ Styles.ScrollViewContent_Container } horizontal showsHorizontalScrollIndicator={ false } style={ Styles.ScrollView_Container }>
								<Text style={ Styles.SortCriterion_Label }>{ I18N.t(["HomeScreen", "SortBy"]) }</Text>

								{ (Locale === "bg-BG" ? SORT_CRITERIA_BG_BG : SORT_CRITERIA_EN_US).map<JSX.Element>((Criterion: IOption) => <Button
									key={ Criterion.ID }
									Label={ Criterion.Label }
									OnPress={ () => SortCriterion.Value === Criterion.Value ? SetSortCriterion({ ID: "", Label: "", Value: "" }) : SetSortCriterion(Criterion) }
									Theme={ SortCriterion.ID === Criterion.ID ? "#000" : "#FFF" }
									ContainerStyles={ { marginHorizontal: 10 } }
									LabelStyles={ { color: SortCriterion.ID === Criterion.ID ? "#FFF" : "#000", fontFamily: "Montserrat-Medium", fontSize: 14, marginHorizontal: 5, paddingHorizontal: 10, paddingVertical: 4 } }
								/>) }
							</ScrollView>
						}
					/> : <></> }

					{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
				</ScrollView>
			</KeyboardAvoidingView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	ScrollViewContent_Container: {
		alignItems: "center",
		paddingBottom: 10
	},
	ScrollView_Container: {
		width: SideGutter(0, 0, 768)
	},
	SortCriterion_Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 14,
		marginLeft: 15
	}
});

export { Home };
