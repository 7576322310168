import { StyleSheet, Text, View, ViewStyle } from "react-native";

interface IAlternativeHintProps {
	Hint: string;
	ContainerStyles?: ViewStyle;
};

const AlternativeHint = (Props: IAlternativeHintProps): JSX.Element => {

	const { Hint, ContainerStyles } = Props;

	return (
		<View collapsable style={ ContainerStyles }>
			<View collapsable style={ Styles.Line } />
			<Text style={ Styles.Hint }>{ Hint }</Text>
			<View collapsable style={ Styles.Line } />
		</View>
	);
};

const Styles = StyleSheet.create({
	Line: {
		borderBottomColor: "#000",
		borderBottomWidth: 1,
		flex: 1
	},
	Hint: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginHorizontal: 15,
		textAlign: "center"
	}
});

export { AlternativeHint };
