import { CommonActions, NavigationProp, ParamListBase, RouteProp, useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";

import { Button } from "../../../Components";
import { Heading } from "../../../Components/Heading";
import { IRoutesPayload, RoutesList } from "../../../Components/Lists";
import { WelcomeSlogan } from "../../../Components/Typography";

import { IRouteEntity, IUserEntity } from "../../../Interfaces/Entities";

import { HTTPClientContext, IHTTPClientContext } from "../../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../../Providers/Keyboard-Provider";

import { SideGutter, TimeGreeting, TopGutter } from "../../../Utilities";

const ClimbedRoutes = (): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N, Locale } = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();
	const Route: RouteProp<ParamListBase> = useRoute<RouteProp<ParamListBase>>();

	const [User, SetUser] = useState<IUserEntity | null>(null);
	const [Routes, SetRoutes] = useState<IRoutesPayload>({ Fetching: false, Limit: 5, Loading: false, More: false, Page: 1, Payload: [] });

	useFocusEffect(
		useCallback(() => {
			(async () => {
				const AxiosResponse_Result: AxiosResponse<IUserEntity, void> = await _HTTPClientContext.HTTPClient.get<IUserEntity, AxiosResponse<IUserEntity, void>, IUserEntity>("users/current-user");
				SetUser(AxiosResponse_Result.data);
			})();
		}, [])
	);

	useEffect(() => {
		if (!Route.params) {
			return;
		}

		SetRoutes({ ...Routes, Payload: (Route.params as any).User.climbed.map((Entity: any) => ({
			...Entity,
			location: {
				address: (new Date(Entity.date)).toLocaleDateString("en-US", { day: "numeric", month: "long", weekday: "long", year: "numeric" })
			}
		})), Loading: false });
	}, [Route.params]);

	const OnRouteBookmarkPress = (Route: IRouteEntity): void => {
		if (!Route.isFavourite) {
			(async () => {
				await _HTTPClientContext.HTTPClient.post<string, AxiosResponse<string, void>>("favourite-routes", { route: Route.id });
			})();

			SetRoutes({ ...Routes, Payload: Routes.Payload.map<IRouteEntity>((Entry: IRouteEntity) => {
				if (Entry.id === Route.id) {
					Entry.isFavourite = true;
				}

				return Entry;
			}) });

		} else {
			(async () => {
				await _HTTPClientContext.HTTPClient.delete<string, AxiosResponse<string, void>, void>(`favourite-routes/${ Route.id }`);
			})();

			SetRoutes({ ...Routes, Payload: Routes.Payload.filter((Entry: IRouteEntity) => {
				if (Entry.id === Route.id) {
					Entry.isFavourite = !Entry.isFavourite;
				}

				return Entry;
			}) });
		}
	};

	return (
		<SafeAreaView collapsable>
			<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" showsVerticalScrollIndicator>
				<Heading
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Title={ I18N.t(["ClimbedRoutesScreen", "ClimbedRoutes"]) }
				/>

				<WelcomeSlogan
					Name={ User ? `${ User.firstName } ${ User.lastName }` : "" }
					Slogan={ I18N.t(["ClimbedRoutesScreen", "CheckYourClimbedRoutesHere"]) }
					Timing={ TimeGreeting(Locale) }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/>

				<RoutesList
					EmptyListLabel={ I18N.t(["ClimbedRoutesScreen", "NoClimbedRoutesFound"]) }
					Fetching={ Routes.Fetching }
					Loading={ Routes.Loading }
					More={ Routes.More }
					OnRouteBookmarkPress={ (ClimbedRoute: IRouteEntity) => OnRouteBookmarkPress(ClimbedRoute) }
					OnRouteCardPress={ (RelatedRoute: IRouteEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Route Details", params: { id: RelatedRoute.id } })) }
					Pagination={
						<Button
							Label={ I18N.t(["ClimbedRoutesScreen", "ShowMoreClimbedRoutes"]) }
							OnPress={ () => SetRoutes({ ...Routes, Page: Routes.Page + 1 }) }
							Theme="#ED2020"
							ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/>
					}
					Routes={ Routes.Payload }
					ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
				/>

				{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
			</ScrollView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	ScrollViewContent_Container: {
		alignItems: "center",
		paddingBottom: 10
	},
	ScrollView_Container: {
		width: SideGutter(0, 0, 768)
	},
	SortCriterion_Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 14,
		marginLeft: 15
	}
});

export { ClimbedRoutes };
