const TimeGreeting = (Locale: string): string => {
	const Hour: number = new Date().getHours();

	if (Hour <= 11) {
		return Locale === "bg-BG" ?  "Добро утро" : "Good morning";

	} else if (Hour >= 12 && Hour <= 17) {
		return Locale === "bg-BG" ? "Добър ден" : "Good afternoon";

	} else {
		return Locale === "bg-BG" ? "Добър вечер" : "Good evening";
	}
};

export { TimeGreeting };
