import Ionicons from "@expo/vector-icons/Ionicons";
import { StyleSheet, Text, View, ViewStyle } from "react-native";

interface IEmptyListPlaceholderProps {
	Label: string;
	ContainerStyle?: ViewStyle;
};

const EmptyListPlaceholder = (Props: IEmptyListPlaceholderProps): JSX.Element => {

	const { Label, ContainerStyle } = Props;

	return (
		<View collapsable style={ [Styles.Flex_Container, ContainerStyle] }>
			<Ionicons color="#000" name="information-circle" size={ 64 } style={ Styles.Icon } />
			<Text style={ Styles.Label }>{ Label }</Text>
		</View>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		justifyContent: "center"
	},
	Icon: {
		marginBottom: 10
	},
	Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		textAlign: "center"
	}
});

export { EmptyListPlaceholder };
