import { Dimensions, Platform } from "react-native";

const SideGutter = (Mobile: number, NonMobile: number, MaxWidth: number): number => {
	if (Platform.OS === "android" || Platform.OS === "ios") {
		return Dimensions.get("screen").width - 2 * Mobile;

	} else {
		return Dimensions.get("window").width - 2 * NonMobile > MaxWidth ? MaxWidth : Dimensions.get("window").width - 2 * NonMobile;
	}
};

export { SideGutter };
