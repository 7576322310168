import { NavigationProp, StackActions, useNavigation } from "@react-navigation/native";
import { useContext, useState } from "react";
import { KeyboardAvoidingView, SafeAreaView, ScrollView, StyleSheet, Text } from "react-native";

import { AnimatedModal, Button, Input, PasswordInput } from "../../Components";
import { TwoSideLabels } from "../../Components/Typography";

import { EMAIL_REGEXP } from "../../Constants";

import { CredentialsContext, ICredentialsContext } from "../../Providers/Credentials-Provider";
import { I18NContext, II18NContext } from "../../Providers/I18N-Provider";

import { SideGutter, TopGutter } from "../../Utilities";

const SignUp = (): JSX.Element => {

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();

	const _CredentialsContext: ICredentialsContext = useContext<ICredentialsContext>(CredentialsContext);
	const { I18N }: II18NContext = useContext<II18NContext>(I18NContext);

	const [PasswordVisibility, SetPasswordVisibility] = useState<boolean>(false);

	const [Modal, SetModal] = useState({ Title: "", Message: "", Visible: false });

	const SignUp = (): void => {

		if ((_CredentialsContext.Credentials.ConfirmPassword && EMAIL_REGEXP.test(_CredentialsContext.Credentials.Email) && _CredentialsContext.Credentials.Name && _CredentialsContext.Credentials.Password && _CredentialsContext.Credentials.Surname) && (_CredentialsContext.Credentials.ConfirmPassword === _CredentialsContext.Credentials.Password) && (_CredentialsContext.Credentials.ConfirmPassword.length >= 6 && _CredentialsContext.Credentials.Password.length >= 6)) {
			Navigation.dispatch(StackActions.push("Personal Settings"));

		} else {
			SetModal({ Title: I18N.t(["LoginScreen", "FormFilledOutIncorrectly"]), Message: I18N.t(["LoginScreen", "FillInAllTheFieldsToContinue"]), Visible: true });
		}
	};

	return (
		<SafeAreaView collapsable>
			<KeyboardAvoidingView collapsable>
				<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" keyboardShouldPersistTaps="handled" showsVerticalScrollIndicator>
					<Text style={ Styles.Slogan }>{ I18N.t(["SignUpScreen", "CreateAnAccountToGetStarted"]) }</Text>

					<Input
						Label={ I18N.t(["SignUpScreen", "Name"]) }
						OnChange={ (Text: string) => _CredentialsContext.DispatchCredentials({ Payload: Text, Type: "CHANGE_NAME" }) }
						Placeholder={ I18N.t(["SignUpScreen", "EnterName"]) }
						Value={ _CredentialsContext.Credentials.Name }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					/>

					<Input
						Label={ I18N.t(["SignUpScreen", "Surname"]) }
						OnChange={ (Text: string) => _CredentialsContext.DispatchCredentials({ Payload: Text, Type: "CHANGE_SURNAME" }) }
						Placeholder={ I18N.t(["SignUpScreen", "EnterSurname"]) }
						Value={ _CredentialsContext.Credentials.Surname }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					/>

					<Input
						Label={ I18N.t(["SignUpScreen", "Email"]) }
						OnChange={ (Text: string) => _CredentialsContext.DispatchCredentials({ Payload: Text, Type: "CHANGE_EMAIL" }) }
						Placeholder={ I18N.t(["SignUpScreen", "EnterEmail"]) }
						Value={ _CredentialsContext.Credentials.Email }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					/>

					<PasswordInput
						Label={ I18N.t(["SignUpScreen", "Password"]) }
						OnChange={ (Password: string) => _CredentialsContext.DispatchCredentials({ Payload: Password, Type: "CHANGE_PASSWORD" }) }
						Placeholder={ I18N.t(["SignUpScreen", "EnterPassword"]) }
						Value={ _CredentialsContext.Credentials.Password }
						ContainerStyles={ { marginBottom: 10, width: SideGutter(20, 20, 768) } }
						PasswordVisibility={ PasswordVisibility }
						TogglePasswordVisibility={ () => SetPasswordVisibility(!PasswordVisibility) }
						WithPasswordVisibilityIcon
					/>

					<Text style={ Styles.PasswordHint_Label }>{ I18N.t(["SignUpScreen", "EnterAtleastSixCharacters"]) }</Text>

					<PasswordInput
						Label={ I18N.t(["SignUpScreen", "ConfirmPassword"]) }
						OnChange={ (Password: string) => _CredentialsContext.DispatchCredentials({ Payload: Password, Type: "CHANGE_CONFIRM_PASSWORD" }) }
						Placeholder={ I18N.t(["SignUpScreen", "EnterConfirmPassword"]) }
						Value={ _CredentialsContext	.Credentials.ConfirmPassword }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						PasswordVisibility={ PasswordVisibility }
					/>

					<Button
						Label={ I18N.t(["SignUpScreen", "SignUp"]) }
						OnPress={ () => SignUp() }
						Theme="#ED2020"
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
					/>

					{ (_CredentialsContext.Credentials.ConfirmPassword || _CredentialsContext.Credentials.Email || _CredentialsContext.Credentials.Name || _CredentialsContext.Credentials.Password || _CredentialsContext.Credentials.Surname) ? <Button
						Label={ I18N.t(["SignUpScreen", "QuitSignUpWizard"]) }
						OnPress={ () => {
							_CredentialsContext.DispatchCredentials({ Payload: "", Type: "QUIT_SIGN_UP_WIZARD" });

							Navigation.dispatch(StackActions.pop(1));
						} }
						Theme="#000"
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
					/> : <></> }

					<TwoSideLabels
						LeftLabel={ I18N.t(["SignUpScreen", "HaveAnAccount"]) }
						RightLabel={ I18N.t(["SignUpScreen", "LogIn"]) }
						ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
						LeftLabelStyles={ { color: "#000", fontFamily: "Montserrat-Medium", fontSize: 16 } }
						OnRightLabelPress={ () => Navigation.dispatch(StackActions.pop(1)) }
						RightLabelStyles={ { color: "#000", fontFamily: "Montserrat-Bold", fontSize: 16 } }
					/>

					<AnimatedModal Title={ Modal.Title } Message={ Modal.Message } Visible={ Modal.Visible }>
						<Button
							Label={ I18N.t(["SignUpScreen", "Dismiss"]) }
							OnPress={ () => SetModal({ Title: "", Message: "", Visible: false }) }
							Theme="#000"
							LabelStyles={ { color: "#FFF", fontFamily: "Montserrat-Medium", fontSize: 16, paddingVertical: 10 } }
						/>
					</AnimatedModal>
				</ScrollView>
			</KeyboardAvoidingView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	Slogan: {
		color: "#000",
		fontFamily: "Montserrat-Bold",
		fontSize: 20,
		marginBottom: 25,
		textAlign: "center",
		width: SideGutter(70, 70, 768)
	},
	Icon: {
		height: 25,
		position: "absolute",
		left: 10,
		width: 25
	},
	PasswordHint_Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 12,
		marginBottom: 25,
		width: SideGutter(20, 20, 768)
	}
});

export { SignUp };
