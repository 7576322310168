import { Text, TouchableHighlight, StyleSheet, View, ViewStyle } from "react-native";

interface IFabButtonProps {
	Icon: JSX.Element;
	Label: string;
	OnPress: () => void;
	ContainerStyles?: ViewStyle;
};

const FabButton = (Props: IFabButtonProps): JSX.Element => {

	const { Icon, Label, OnPress, ContainerStyles } = Props;

	return (
		<View collapsable style={ [Styles.Flex_Container, ContainerStyles] }>
			<TouchableHighlight onPress={ () => OnPress() } style={ Styles.TouchableHighlight }>{ Icon }</TouchableHighlight>
			<Text style={ Styles.Label }>{ Label }</Text>
		</View>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		justifyContent: "center"
	},
	TouchableHighlight: {
		borderRadius: 100,
		marginBottom: 10,
		overflow: "hidden",
		elevation: 3,
		shadowColor: "#000",
		shadowOffset: {
			height: 1,
			width: 0
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22
	},
	Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 14
	}
});

export { FabButton };
