import Ionicons from "@expo/vector-icons/Ionicons";
import { StyleSheet, Text, TextInput, TouchableOpacity, View, ViewStyle } from "react-native";

interface IPasswordInputProps {
	Label: string;
	OnChange: (Password: string) => void;
	Placeholder: string;
	Value: string;
	ContainerStyles?: ViewStyle;
	PasswordVisibility?: boolean;
	TogglePasswordVisibility?: () => void;
	WithPasswordVisibilityIcon?: boolean;
};

const PasswordInput = (Props: IPasswordInputProps): JSX.Element => {

	const { Label, OnChange, Placeholder, Value, ContainerStyles, PasswordVisibility, TogglePasswordVisibility, WithPasswordVisibilityIcon } = Props;

	return (
		<View collapsable style={ ContainerStyles }>
			<Text style={ Styles.Label }>{ Label }</Text>

			<View collapsable style={ Styles.FlexRelative_Container }>
				<TextInput
					autoCapitalize="none"
					autoComplete="off"
					autoCorrect={ false }
					collapsable
					cursorColor="#000"
					keyboardType="default"
					onChangeText={ (Password: string) => OnChange(Password) }
					placeholder={ Placeholder }
					placeholderTextColor="#000"
					secureTextEntry={ PasswordVisibility ? false : true }
					style={ Styles.PasswordInput }
					textContentType="password"
					value={ Value }
				/>

				{ WithPasswordVisibilityIcon ? (
					<TouchableOpacity onPress={ () => TogglePasswordVisibility ? TogglePasswordVisibility() : {} } style={ Styles.TouchableOpacity }>
						<Ionicons color="#000" name={ PasswordVisibility ? "eye" : "eye-off" } size={ 25 } />
					</TouchableOpacity>
				) : <></> }
			</View>
		</View>
	);
};

const Styles = StyleSheet.create({
	Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginBottom: 10
	},
	FlexRelative_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		position: "relative"
	},
	PasswordInput: {
		backgroundColor: "#FFF",
		borderColor: "#E3E5E5",
		borderRadius: 8,
		borderWidth: 1,
		color: "#000",
		flex: 1,
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		padding: 10
	},
	TouchableOpacity: {
		padding: 10,
		position: "absolute",
		right: 1
	}
});

export { PasswordInput };
