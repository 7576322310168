import Ionicons from "@expo/vector-icons/Ionicons";
import { TouchableOpacity } from "react-native";

interface IBookmarkIconProps {
	Favorite: boolean;
	OnPress: () => void;
};

const BookmarkIcon = (Props: IBookmarkIconProps): JSX.Element => {

	const { Favorite, OnPress } = Props;

	return (
		<TouchableOpacity onPress={ () => OnPress() }>
			<Ionicons color="#ED2020" name={ Favorite ? "bookmark" : "bookmark-outline" } size={ 25 } />
		</TouchableOpacity>
	);
};

export { BookmarkIcon };
