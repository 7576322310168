import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { Image } from "expo-image";
import { StyleSheet, Text, View, ViewStyle } from "react-native";

import { eEWallsAPI } from "../Constants";

interface ITop {
	ID: string;
	photo: any;
	lastName: string;
	firstName: string;
	date: string;
};

interface ITopProps {
	Top: ITop;
	ContainerStyles?: ViewStyle;
};

const Top = (Props: ITopProps): JSX.Element => {

	const { Top, ContainerStyles } = Props;

	const { photo, firstName, lastName, date } = Top;

	return (
		<View collapsable style={ [Styles.TouchableOpacity, ContainerStyles] }>
			<View collapsable style={ [Styles.Flex_Container, ContainerStyles ? { width: ContainerStyles.width } : {}] }>
				<View collapsable style={ Styles.FlexContent_Container }>
					<Image
						collapsable
						contentFit="cover"
						placeholder="L2HC1R~q00IU?bM{ofj[000000%M"
						placeholderContentFit="cover"
						priority="high"
						source={ photo ? eEWallsAPI.BASE_URL + photo.path : require("../../assets/Icon.png") }
						style={ Styles.Image }
					/>

					<View collapsable style={ Styles.Information_Container }>
						<View collapsable style={ Styles.First_Row_Container }>
							<Text ellipsizeMode="tail" numberOfLines={ 2 } style={ Styles.Name }>{ firstName + " " + lastName }</Text>
						</View>

						<View collapsable style={ Styles.Address_Container }>
							<MaterialIcons color="#000" name="date-range" size={ 15 } />
							<Text ellipsizeMode="tail" numberOfLines={ 2 } style={ Styles.Address }>{ new Date(date).toLocaleDateString() }</Text>
						</View>
					</View>
				</View>
			</View>
		</View>
	);
};

const Styles = StyleSheet.create({
	TouchableOpacity: {
		borderRadius: 8
	},
	Flex_Container: {
		backgroundColor: "#FFF",
		borderRadius: 8,
		elevation: 3,
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between",
		shadowColor: "#000",
		shadowOffset: {
			height: 1,
			width: 0
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22
	},
	FlexContent_Container: {
		flex: 1,
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "center"
	},
	Image: {
		borderBottomLeftRadius: 8,
		borderTopLeftRadius: 8,
		height: 100,
		width: 100
	},
	Information_Container: {
		flex: 1,
		padding: 7,
	},
	First_Row_Container: {
		flex: 1,
	},
	Name: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 16,
		marginBottom: 10
	},
	Address_Container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	Address: {
		color: "#000",
		flex: 1,
		fontFamily: "Montserrat-Medium",
		fontSize: 14,
		marginLeft: 5
	},
	Bookmark: {
		padding: 5
	}
});

export { ITop, Top };
