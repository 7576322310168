import { CommonActions, NavigationProp, useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { AxiosResponse } from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";

import { Tabulator } from "../../../Components";
import { Heading } from "../../../Components/Heading";
import { ProfileIcon } from "../../../Components/Heading/Icons";
import { EmptyListPlaceholder, IRoutesPayload, RoutesList } from "../../../Components/Lists";
import { TwoSideLabels } from "../../../Components/Typography";

import { IRouteEntity, IUserEntity } from "../../../Interfaces/Entities";

import { HTTPClientContext, IHTTPClientContext } from "../../../Providers/HTTP-Client-Provider";
import { I18NContext, II18NContext } from "../../../Providers/I18N-Provider";
import { KeyboardContext, IKeyboardContext } from "../../../Providers/Keyboard-Provider";

import { SideGutter, TopGutter } from "../../../Utilities";

const RouteSetterProfile = (): JSX.Element => {

	const _HTTPClientContext: IHTTPClientContext = useContext<IHTTPClientContext>(HTTPClientContext);
	const { I18N, Locale }: II18NContext = useContext<II18NContext>(I18NContext);
	const _KeyboardContext: IKeyboardContext = useContext<IKeyboardContext>(KeyboardContext);

	const Navigation: NavigationProp<ReactNavigation.RootParamList> = useNavigation<NavigationProp<ReactNavigation.RootParamList>>();
	const Route = useRoute();

	const [Routes, SetRoutes] = useState<IRoutesPayload>({ Fetching: false, Limit: 100, Loading: false, More: false, Page: 1, Payload: [] });
	const [User, SetUser] = useState<IUserEntity | null>(null);

	const [Tab, SetTab] = useState<string>("Sends");

	useFocusEffect(
		useCallback(() => {
			(async () => {
				console.log(Route.params.id);
				
				const AxiosResponse_Result = await _HTTPClientContext.HTTPClient.get(`users/${ Route.params.id }`);
				SetUser(AxiosResponse_Result.data);
			})();
		}, [])
	);

	useEffect(() => {
		(async() => {
			const AxiosResponse_Result: AxiosResponse = await _HTTPClientContext.HTTPClient.get(`users/${ Route.params.id }?limit=${ Routes.Limit }&page=${ 1 }`);
			SetRoutes({ ...Routes, Payload: AxiosResponse_Result.data.routes });
		})();
	}, []);

	const OnRouteBookmarkPress = (Route: IRouteEntity): void => {
		if (!Route.isFavourite) {
			(async () => {
				await _HTTPClientContext.HTTPClient.post("favourite-routes", { route: Route.id });
			})();

			SetRoutes({ ...Routes, Payload: Routes.Payload.map<IRouteEntity>((Entry: IRouteEntity) => {
				if (Entry.id === Route.id) {
					Entry.isFavourite = true;
				}

				return Entry;
			}) });

		} else {
			(async () => {
				await _HTTPClientContext.HTTPClient.delete(`favourite-routes/${ Route.id }`);
			})();

			SetRoutes({ ...Routes, Payload: Routes.Payload.filter((Entry: IRouteEntity) => {
				if (Entry.id === Route.id) {
					Entry.isFavourite = !Entry.isFavourite;
				}

				return Entry;
			}) });
		}
	};

	return (
		<SafeAreaView collapsable>
			<ScrollView collapsable contentContainerStyle={ Styles.Flex_Container } contentInsetAdjustmentBehavior="automatic" showsVerticalScrollIndicator>
				<Heading
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					Title={ I18N.t(["RouteSetterProfileScreen", "RouteSetterProfile"]) }
				/>

				<ProfileIcon
					ImageStyles={ { borderRadius: 5, flex: 1, width: "100%" } }
					OnPress={ () => {} }
					URL={ `https://app.e-walls.eu/api/${ User?.photo.path }` }
					ContainerStyles={ { alignItems: "center", height: 100, marginBottom: 25, width: 100 } }
					Disabled
					Size={ 100 }
				/>

				<Text style={ Styles.User }>{ User ? `${ User.firstName } ${ User.lastName }` : "" }</Text>

				<TwoSideLabels
					LeftLabel={ I18N.t(["RouteSetterProfileScreen", "RegisteredOn"]) }
					RightLabel={ Route.params ? new Date(User?.createdAt).toLocaleDateString() : new Date().toLocaleDateString() }
					ContainerStyles={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
					LeftLabelStyles={ { color: "#000", fontFamily: "Montserrat-Medium", fontSize: 16 } }
					RightLabelStyles={ { color: "#000", fontFamily: "Montserrat-Medium", fontSize: 16 } }
				/>

				<Tabulator
					OnPress={ (SelectedTab: string) => SetTab(SelectedTab) }
					SelectedTab={ Tab }
					Tabs={ ["Sends", "Posted Routes"] }
					BG_Tabs={ ["Публикувани", "Маршрути"] }
					ContainerStyles={ { marginBottom: 10, width: SideGutter(0, 0, 768) } }
					Locale={ Locale }
				/>

				{ Tab === "Sends" ? <EmptyListPlaceholder
					Label={ I18N.t(["RouteSetterProfileScreen", "NoSendsFound"]) }
					ContainerStyle={ { marginBottom: 25, width: SideGutter(20, 20, 768) } }
				/> : (
					<RoutesList
						EmptyListLabel={ I18N.t(["RouteSetterProfileScreen", "NoPostedRoutesFound"]) }
						Fetching={ Routes.Fetching }
						Loading={ Routes.Loading }
						More={ Routes.More }
						OnRouteBookmarkPress={ (RelatedRoute: IRouteEntity) => OnRouteBookmarkPress(RelatedRoute) }
						OnRouteCardPress={ (RelatedRoute: IRouteEntity) => Navigation.dispatch(CommonActions.navigate("Locations Tab", { screen: "Route Details", params: { id: RelatedRoute.id } })) }
						Pagination={ <></> }
						Routes={ Routes.Payload }
						ContainerStyles={ { alignItems: "center", width: SideGutter(0, 0, 768) } }
					/>
				) }

				{ _KeyboardContext.Offset ? <View collapsable style={ { marginBottom: _KeyboardContext.Offset } } /> : <></> }
			</ScrollView>
		</SafeAreaView>
	);
};

const Styles = StyleSheet.create({
	Flex_Container: {
		alignItems: "center",
		paddingTop: TopGutter(10, 10, 10)
	},
	User: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 18,
		marginBottom: 25
	},
	ScrollViewContent_Container: {
		alignItems: "center",
		paddingBottom: 10
	},
	ScrollView_Container: {
		width: SideGutter(0, 0, 768)
	},
	SortCriterion_Label: {
		color: "#000",
		fontFamily: "Montserrat-Medium",
		fontSize: 14,
		marginLeft: 15
	}
});

export { RouteSetterProfile };
