import { EventArg, EventMapBase, NavigationState, ScreenListeners } from "@react-navigation/native";
import { NativeStackNavigationOptions, createNativeStackNavigator } from "@react-navigation/native-stack";
import { useState } from "react";
import { View } from "react-native";

import { BottomTabulator } from "../Components";

import { LocationsFlow } from "../Controllers/Locations-Flow";
import { ProfileFlow } from "../Controllers/Profile-Flow";

import { PublicProfile } from "../Screens/Public-Profile";
import { Home } from "../Screens/User-Flow";

import { SideGutter } from "../Utilities";

const UserFlow = (): JSX.Element => {

	const [FocusedTab, SetFocusedTab] = useState<string>("");

	const NativeStack = createNativeStackNavigator();

	const NativeStackScreenListeners: ScreenListeners<NavigationState, EventMapBase> = {
		focus: (Event: EventArg<string, boolean, any>) => SetFocusedTab(() => Event.target ? Event.target : "")
	};

	const NativeStackScreenOptions: NativeStackNavigationOptions = {
		animation: "flip",
		contentStyle: {
			backgroundColor: "#F5F5F5",
			overflow: "scroll"
		},
		headerShown: false,
	};

	return (
		<View style={{ flex: 1 }}>
			<NativeStack.Navigator initialRouteName="Home Tab" screenListeners={ NativeStackScreenListeners } screenOptions={ NativeStackScreenOptions }>
				<NativeStack.Screen name="Home Tab" component={ Home } />
				<NativeStack.Screen name="Locations Tab" component={ LocationsFlow } />
				<NativeStack.Screen name="Profile Tab" component={ ProfileFlow } />
				<NativeStack.Screen name="Public Profile" component={ PublicProfile } />
			</NativeStack.Navigator>

			<BottomTabulator
				FocusedTab={ FocusedTab }
				Tabs={ ["Home", "Locations", "Profile"] }
				ContainerStyles={ { position: "absolute", bottom: 10, width: SideGutter(10, 10, 768) } }
			/>
		</View>
	);
};

export { UserFlow };
