enum eEWallsAPI {
	BASE_URL = "https://app.e-walls.eu/api/",
	VERSIONED_URL = "https://app.e-walls.eu/api/v1/"
};

enum eKeyboardState {
	HIDDEN = 0,
	INIT = 1,
	SHOWN = 2
};

enum ePrivilege {
	USER = "1",
	ROUTE_SETTER = "2",
	ADMIN = "3",
	SUPER_ADMIN = "4"
};

enum eRouteConfiguration {
	MINIMUM_ROUTE_HANDLES = 3
};

export { eEWallsAPI };
export { eKeyboardState };
export { ePrivilege };
export { eRouteConfiguration };
