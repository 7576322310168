import { I18n } from "i18n-js";
import { Context, ReactNode, createContext, useEffect, useState } from "react";
import { AppState, AppStateStatus, NativeEventSubscription } from "react-native";

import { BG_BG_TRANSLATION, EN_US_TRANSLATION } from "../I18n";

interface II18NContext {
	I18N: I18n;
	Locale: string;
};

interface II18NProviderProps {
	children: ReactNode;
};

const I18NContext: Context<II18NContext> = createContext<II18NContext>({
	I18N: new I18n(),
	Locale: "en-US"
});

const I18NProvider = (Props: II18NProviderProps): JSX.Element => {

	const { children } = Props;

	const [ApplicationState, SetApplicationState] = useState<AppStateStatus>(() => AppState.currentState);
	const [Locale, SetLocale] = useState<string>("en-US");

	const I18N = new I18n({
		"bg-BG": BG_BG_TRANSLATION,
		"en-US": EN_US_TRANSLATION
	});

	I18N.defaultLocale = "en-US";
	I18N.locale = Locale;

	useEffect(() => {
		const ApplicationStateSubscription: NativeEventSubscription = AppState.addEventListener("change", (NextApplicationState: AppStateStatus) => {
			SetApplicationState(NextApplicationState);
		});

		return () => {
			ApplicationStateSubscription.remove();
		};
	}, []);

	useEffect(() => {
		SetLocale("en-US");
	}, [ApplicationState]);

	return (
		<I18NContext.Provider value={ { I18N, Locale } }>
			{ children }
		</I18NContext.Provider>
	);
};

export { I18NContext };
export { I18NProvider };
export { II18NContext };
